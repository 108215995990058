import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import store from '../../store/store'
import { setClickedComment, clearClickedComment } from '../../store/InteractionsSlice'
class CommentAnchor extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.isMobile = store.getState().isMobile;
    }

    render() {
        return (
            <div className="comment-anchor-block" data-test={`comment-anchor-block-test-${this.props.elem.id}`} style={{ left: this.props.elem.left, top: this.props.elem.top }} >
                <span className={`${!this.isMobile ? 'comment-anchor-circle-hover' : ''} comment-anchor-circle ${this.props.elem.category}-location-color`}
                    data-test="comment-anchor"
                    onClick={() => {
                        store.dispatch(this.props.comment === null ? setClickedComment(this.props.elem) : clearClickedComment())
                        this.props.closeCommentCreate()
                    }}>

                </span>
            </ div>
        )
    }
}

const mapStateToProps = (state) => ({
    comment: state.interactions?.comment ? new Comment(state.interactions.comment) : null, // Zugriff auf den Comment-State
  });

export default connect(mapStateToProps)(withTranslation()(CommentAnchor))