import React from "react"
import { Avatar, Box, IconButton, AvatarBadge } from "@chakra-ui/react"
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { FiLogOut, FiMail } from "react-icons/fi"
import { UserDto, Api, baseApiParams } from "my-api-client-package"
import { useTranslation } from "react-i18next"
import store from "../../store/store"
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { MiscFunctions } from "helper-functions-package";
import { 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    useDisclosure 
} from "@chakra-ui/react"

interface AvatarMenuProps {
    userObj: UserDto | null;
}

export const AvatarMenu: React.FC<AvatarMenuProps> = (props) => {
    const { userObj } = props;
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });

    const logout = () => {
        MiscFunctionsLocal.logout();
    };

    const handleInvitationResponse = (accept: boolean) => {
        // Hier die Logik für das Annehmen/Ablehnen der Einladung implementieren
        if (accept) {
            api.company.acceptCompanyInvite(baseApiParams())
        }
        onClose();
    };

    return (
        <Box className="avatar-menu">
            <Menu>
                <MenuButton
                    as={Avatar}
                    aria-label='Options'
                    icon={!MiscFunctions.isNull(userObj) ? (
                        <Avatar as={IconButton} aria-label='Options' boxSize="10" src={userObj?.avatar}>
                            {userObj.invitedCompany && (
                                <AvatarBadge placement="top-end" boxSize='1.25em' bg='red.500' borderColor='white'>
                                    !
                                </AvatarBadge>
                            )}
                        </Avatar>
                    ) : (
                        <Avatar as={IconButton} aria-label='Options' boxSize="10" />
                    )}
                    variant='outline'
                    boxSize="10"
                />
                <MenuList minWidth={"110px"} className="avatar-menu-list">
                    <MenuItem className="profile-menu-item" onClick={() => store.dispatch({ type: 'mediathek/setActiveMediathekPage', payload: "Profile" })} fontSize={"14px"} icon={<Avatar padding={0} margin={0} size="sm" src={userObj?.avatar} />}>
                        {t('rate_my.profile')}
                    </MenuItem>
                    
                    {userObj?.invitedCompany && (
                        <>
                            <MenuItem 
                                className="invitation-menu-item" 
                                onClick={onOpen}
                                fontSize={"14px"}
                                icon={<FiMail color={"#A0AEC0"} size={24} />}
                            >
                                {t('general.invitation')}
                            </MenuItem>

                            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>{t('general.invitation')}</ModalHeader>
                                    <ModalBody>
                                        {t('general.accept_invitation_question')}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme="blue" mr={3} onClick={() => handleInvitationResponse(true)}>
                                            {t('general.accept')}
                                        </Button>
                                        <Button variant="ghost" onClick={() => handleInvitationResponse(false)}>
                                            {t('general.decline')}
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </>
                    )}
                    <MenuItem className="profile-menu-item" onClick={() => logout()} paddingLeft={"20px"} fontSize={"14px"} icon={<FiLogOut color={"#A0AEC0"} size={24} />}>
                        {t('general.logout')}
                    </MenuItem>

                </MenuList>
            </Menu>
        </Box>
    )
}