import React from "react";
import DOClient from "../../utils/DOClient";
import TextInput from "./TextInput";
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Spinner } from "@chakra-ui/react";
import AvatarEditor from "react-avatar-editor";
import Counter from "./Counter";
import { withTranslation } from "react-i18next";
import { getAuthAccessObject } from "../../utils/tokenStorage";
import ToastMessages from "../../helpers/ToastMessages";
import { MiscFunctions } from 'helper-functions-package';
import Swal from "sweetalert2";
import { Api, baseApiParams } from "my-api-client-package";
class MediaUploadUI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      selectedFileSrc: null,
      showBlock: false,
      uploadSuccess: false,
      mediaDescription: "",
      showSpinner: false,
      rotateValue: 0,
      scaleValue: 1,
      editedFile: null,
      selectedVideo: null,
      selectedAudio: null,
      imageType: ".jpg,.jpeg,.png",
      docType: ".pdf",
      videoType: ".mp4",
      audioType: ".mp3,.mpeg,.wav",
      videoAuioAndImageType: ".mp4,.jpg,.jpeg,.png,.mp3,.mpeg,.wav",
      selectedFileType: ".jpg,.jpeg,.png,.pdf,.mp4,.mp3,.mpeg,.wav",
      uploadedFileName: "",
      swithElement: false,
      api: new Api({
        baseUrl: process.env.REACT_APP_API_URL
      })
    };
  }

  dataURLtoFile(dataurl, filename, fileType) {
    var arr = dataurl.split(","),
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: fileType });
  }

  handleDescription = (string) => {
    this.setState({ mediaDescription: string });
  };

  selectFileHandler = (event) => {
    // Reset the state related to file selection
    this.setState({
      selectedFile: null,
      selectedFileSrc: null,
      editedFile: null,
      selectedVideo: null,
      selectedAudio: null,
    });

    const file = event.target.files[0];
    if (!file) {
      return; // No file selected, exit early
    }

    const fileType = file.type;
    const fileSizeInMB = file.size / (1024 * 1024); // Convert size to MB

    // Define size limits
    const sizeLimits = {
      pdf: 50, // MB
      jpeg: 50, // MB
      jpg: 50, // MB
      png: 50, // MB
      mp3: 50, // MB
      mpeg: 50, // MB
      mp4: 5120 // MB (5 GB)
    };

    // Extract file extension from file type (e.g., 'image/jpeg' -> 'jpeg')
    const fileExtension = fileType.split('/')[1].toLowerCase();
    // Check if the file type is supported and within the size limit
    if (sizeLimits[fileExtension] && fileSizeInMB > sizeLimits[fileExtension]) {

      Swal.fire({
        title: "Oops!",
        text: `The selected file is too large. Maximum size allowed for .${fileExtension} files is ${sizeLimits[fileExtension]} MB.`,
        icon: "error",
        confirmButtonColor: "#4baaac",
        confirmButtonText: "OK",
      });
      console.error(`The selected file is too large. Maximum size allowed for .${fileExtension} files is ${sizeLimits[fileExtension]} MB.`);
      return;
    }

    // Set the selected file if the size is within the limit
    this.setState({ selectedFile: file });
    // Call handleImageChange if needed
    this.handleImageChange(event);
  };

  renameFile(originalFile, newName) {
    const newFile = new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });

    return newFile;
  }

  uploadMedia = async() => {
    const originalFileName = this.state.selectedFile.name.toLowerCase();
    if (originalFileName) {
      this.setState({ showSpinner: true });
      const fileName = `${getAuthAccessObject().ID}/${originalFileName}`;
      let encoded = encodeURIComponent(fileName);
      let editedFile = this.state.selectedFile;
      if (this.editor) {
        this.setState({uploadedFileName: this.state.selectedFile.name})
        editedFile = this.dataURLtoFile(
          this.editor.getImageScaledToCanvas().toDataURL(),
          originalFileName,
          this.state.selectedFile.type
        );
        this.setState({ selectedFile: editedFile });
      }
      const res = await this.state.api.upload.uploadPrivateFileLegacy(
        encodeURIComponent(originalFileName),
        encodeURIComponent(originalFileName),
        baseApiParams()
      )
      let response = await res.data;
      let media = await DOClient.put(
        response.message,
        editedFile,
        this.state.mediaDescription
      );
      const mediaObject = await this.state.api.success.confirmUpload(
        {
          filename: encoded,
          mediaProperties: response.description
        },
        baseApiParams()
      );
      this.props.selectHandler(mediaObject.data, this.state.selectedFile);
      this.setState({
        uploadSuccess: true,
        showSpinner: false,
        showBlock: false,
        selectedFile: null,
        selectedFileSrc: null,
        editedFile: null,
        selectedVideo: null,
        selectedAudio: null
      });
      return mediaObject.data;
    }
    else {
      ToastMessages.erorrMessage(this.props.t('messages.select_a_file'));
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.uploadedFileName !== prevState.uploadedFileName && !MiscFunctions.isUndefined(this.props.ressourceUpload)){
      this.props.onChangeFileName(this.state.uploadedFileName)
    }
    if(prevProps.swithElement !== prevState.swithElement && !MiscFunctions.isUndefined(this.props.ressourceUpload)){
      this.setState({
        swithElement: this.props.swithElement,
        uploadedFileName: ""
      })
    }
  }
  componentDidMount() {
    if (this.props.fileType === "image")
      this.setState({ selectedFileType: this.state.imageType });
    else if (this.props.fileType === "doc")
      this.setState({ selectedFileType: this.state.docType });
    else if (this.props.fileType === "video")
      this.setState({ selectedFileType: this.state.videoType });
    else if (this.props.fileType === "audio")
      this.setState({ selectedFileType: this.state.audioType });
    else if (this.props.fileType === "image,video,audio")
      this.setState({ selectedFileType: this.state.videoAuioAndImageType });
  }

  handleImageChange = (event) => {
    this.setState({ uploadSuccess: false });

    const file = event.target.files[0];

    if (file && file.type.startsWith("video/")) {
      const videoUrl = URL.createObjectURL(file);
      this.setState({ selectedVideo: videoUrl });
    }

    if(file && file.type.startsWith("audio/")) {
      const audioUrl = URL.createObjectURL(file);
      this.setState({selectedAudio: audioUrl});
    }
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.setState({
          selectedFileSrc: e.target.result,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  setEditorRef = (editor) => (this.editor = editor);

  onScaleChange = (e) => {
    this.setState({ scaleValue: Number(e) });
    this.setState({ uploadSuccess: false });
  };

  onRotateChange = (e) => {
    this.setState({ rotateValue: Number(e) });
    this.setState({ uploadSuccess: false });
  };

  isFileImage = (file) => {
    return file && file.split("/")[0] === "image";
  };

  bodyOverflow = () => {
    document.body.style.overflow = "";
  }

  render() {
    const { label, buttonClass } = this.props;
    
    return (
      <>
        {this.state.showBlock ? (
          <Modal
            isOpen={this.state.showBlock}
            onClose={() => {
              this.setState({ showBlock: !this.state.showBlock });
            }}
            size="xl"
          >
            <ModalOverlay />
            <ModalContent className="expiration-box">
              <ModalCloseButton />
              <ModalBody className="text-center" style={{ overflow: "inherit", flex: "none" }}>
                <div className="file_upload_box">
                  <div className="flex flex-col text-sm">
                    <input
                      data-test="file-upload-box"
                      type="file"
                      name="file"
                      accept={this.state.selectedFileType}
                      onChange={(e) => this.selectFileHandler(e)}
                    />
                  </div>

                  {!MiscFunctions.isNull(this.state.selectedFile) &&
                    !MiscFunctions.isUndefined(this.state.selectedFile) && (
                      <>
                        {this.isFileImage(this.state.selectedFile.type) && (
                          <div className="image-edit-block">
                            <AvatarEditor
                              ref={this.setEditorRef}
                              style={{ width: "100%", height: "70%" }}
                              image={this.state.selectedFileSrc}
                              color={[0, 0, 0, 0.6]}
                              scale={this.state.scaleValue}
                              rotate={this.state.rotateValue}
                              border={this.props.border}
                              width={this.props.imageWidth}
                              height={this.props.imageHeight}
                              borderRadius={this.props.borderRadius}
                            />
                            <div
                              className={"ml-10 mr-10"}
                              style={{
                                minWidth: "100px",
                                marginLeft: "15px",
                                height: "auto",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <label
                                className={
                                  "block text-sm font-medium text-gray-900 dark:text-white input_label"
                                }
                              >
                                {this.state.selectedFile.name}
                              </label>
                            </div>

                            <div className="image-edit-settins-block">
                              {this.props.rotate === true && (
                                <Counter
                                  title="Rotate"
                                  value={this.state.rotateValue}
                                  min={-180}
                                  max={180}
                                  onChange={this.onRotateChange}
                                />
                              )}
                              {this.props.reScale === true && (
                                <>
                                  <Counter
                                    title="Scale"
                                    value={this.state.scaleValue}
                                    min={1}
                                    max={10}
                                    onChange={this.onScaleChange}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        )}

                        {this.state.selectedVideo && (
                          <video
                            controls
                            src={this.state.selectedVideo}
                            width="640"
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}

                        {this.state.selectedAudio && (
                          <audio
                            src={this.state.selectedAudio}
                          ></audio>
                        )}
                        {this.props.description && <div className="flex flex-col">
                          <TextInput
                            label=""
                            rows="5"
                            limit="50"
                            setValue={(e) => this.handleDescription(e)}
                            value={this.state.mediaDescription}
                          />
                        </div>}
                        <button
                          data-test="content-upoad-media-btn"
                          className="content-upload-media-btn"
                          onClick={this.uploadMedia}
                          disabled={this.state.uploadSuccess ? true : false}
                        >
                          {this.state.showSpinner && (
                            <Spinner
                              size="sm"
                              color="green.500"
                              mr={2}
                              thickness="2px"
                              speed="0.65s"
                              emptyColor="gray.200"
                            />
                          )}
                          {this.state.uploadSuccess ? this.props.t('general.done') : this.props.t('general.upload')}
                        </button>
                      </>
                    )}
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
        ) : this.bodyOverflow()}

        <>
          <button
            onClick={() => this.setState({ showBlock: !this.state.showBlock })}
            className={buttonClass}>
            {label && <>{label}</>}
          </button>
        </>
      </>
    );
  }
}

export default withTranslation()(MediaUploadUI);
