import React, { useEffect, useState } from 'react';
import { FileUpload, FormatByte } from '@ark-ui/react';
import { Box, Button, Center, CenterProps, HStack, Icon, Square, Stack, Text, VStack } from '@chakra-ui/react';
import { FiFile, FiUploadCloud } from 'react-icons/fi';
import { Api, baseApiParams, handleApiError, DOClient } from 'my-api-client-package';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MiscFunctions } from 'helper-functions-package';


interface DropzoneProps extends CenterProps {
    fileFormats: string[]; // Accept an array of file formats as a prop
    fileSizeLimit: number; // File size limit in MB
    selectedFiles: (files: File[]) => void; // Callback to send files to parent component
    handleImageUrl: (url: string) => void;
}
export const Dropzone = ({ fileFormats, fileSizeLimit, selectedFiles, handleImageUrl, ...props }: DropzoneProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [files, setFiles] = useState([]);
    const userObj = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    const api = new Api({ baseUrl: process.env.REACT_APP_API_URL });

    const handleProfilePic = (file: string) => {
        dispatch({ type: "profile/setProfilePicture", payload: file });
    }

    // Function to format the file formats into "PNG, JPG or GIF"
    const formatFileFormats = (formats: string[]) => {
        if (formats.length === 0) return '';
        if (formats.length === 1) return formats[0];
        return `${formats.slice(0, -1).join(', ')} ${t('rate_my.or')} ${formats[formats.length - 1]}`;
    };

    const renameFile = (originalFile: any, newName: any) => {
        const newFile = new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        return newFile;
    }
    // Function to handle file upload
    const uploadFile = async (file: File) => {
        try {
            const originalFileName = file.name;
            const extension = originalFileName.slice(originalFileName.lastIndexOf("."));
            const timestamp = Date.now();
            const privateFileName = `${userObj.id.id}-${timestamp}${extension}`;
            const newFile = renameFile(files[0], privateFileName);

            const resolution = await MiscFunctions.getImageResolution(newFile);

            api.upload.uploadPrivateFile(newFile.name, newFile.name, { resolution: resolution }, baseApiParams()).then(async (resp: any) => {
                if (resp && resp.data && resp.data.message) {
                    const media = DOClient.put(
                        await resp.data.message,
                        newFile,
                        newFile.name
                    ).then(async (response: any) => {
                        const mediaObject = await api.success.confirmUpload({
                            filename: encodeURIComponent(`${userObj.id.id}/${newFile.name}`), mediaProperties: {
                                resolution: resolution
                            }
                        }, baseApiParams());
                        console.log(mediaObject);
                        handleImageUrl(mediaObject.data.unsignedUrl);
                        handleProfilePic(mediaObject.data.unsignedUrl);
                    },
                        async (err: any) => {
                            console.log(err);
                        });
                } else {
                    console.error("Error.");
                }
            }, async (err) => {
                console.log(err);
            });

        }
        catch (err) {
            handleApiError(err, navigate)
        }
    };

    // Use effect to upload files when they are added
    useEffect(() => {
        if (files.length > 0) {
            files.forEach((file) => {
                uploadFile(file);
            });
            selectedFiles(files)
        }
    }, [files]);

    return (
        <FileUpload.Root
            {...props}
            onFileAccept={(acceptedFiles: any) => {
                setFiles(acceptedFiles.files);
            }}
        >
            <FileUpload.HiddenInput />
            <FileUpload.Dropzone asChild>
                <Center borderWidth="1px" borderStyle="solid" borderColor="#E2E8F0" borderRadius="lg" px="6" py="6" width={{ base: "100%", xl: "378px" }} height={{ base: "auto", xl: "120px" }}>
                    <VStack spacing="3">
                        <Square size="10" bg="bg.subtle" borderRadius="lg">
                            <Icon as={FiUploadCloud} boxSize="5" color="#4A5568" />
                        </Square>
                        <VStack spacing="1" className='dropzone-text'>
                            <HStack spacing="1" whiteSpace="nowrap" display={"flex"} flexDirection={{ base: "column", md: "row" }} alignItems={"center"}>
                                <FileUpload.Trigger asChild>
                                    <Button variant="text" color="#4A5568" size="sm" padding={"0"}>
                                        {t('rate_my.click_to_upload')}
                                    </Button>
                                </FileUpload.Trigger>
                                <Text textStyle="sm" color="#4A5568">
                                    {t('rate_my.by_drag_drop')}
                                </Text>
                            </HStack>
                            <Text textStyle="xs" color="#4A5568">
                                {formatFileFormats(fileFormats)} {t('rate_my.up_to')} {fileSizeLimit}MB
                            </Text>
                        </VStack>
                    </VStack>
                </Center>
            </FileUpload.Dropzone>

            <FileUpload.Context>
                {({ acceptedFiles }) => (
                    <Stack mt="4" hidden={acceptedFiles.length === 0}>
                        {acceptedFiles.map((file) => (
                            <HStack padding="3" key={file.name} borderWidth="1px" borderRadius="lg">
                                <Icon as={FiFile} boxSize="5" color="fg.muted" />
                                <Text textStyle="sm">
                                    {file.name} -{' '}
                                    <Box as="span" color="fg.muted">
                                        <FormatByte value={file.size} />
                                    </Box>
                                </Text>
                            </HStack>
                        ))}
                    </Stack>
                )}
            </FileUpload.Context>
        </FileUpload.Root>
    );
};

