import React, { useEffect } from "react"
import { Stack, Text, Button, Avatar, Card, CardBody, CardHeader, Input, Textarea, CardFooter, Box, Container, WrapItem, FormControl, FormLabel } from "@chakra-ui/react"
import "./pages.scss"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { HiCheckCircle } from "react-icons/hi";
import { IoDownloadOutline } from "react-icons/io5";
import { Dropzone } from "../../../helpers/Dropzone.tsx"
import { Api, UserDto, handleApiError, baseApiParams, HttpResponse } from 'my-api-client-package'
import { useNavigate } from "react-router-dom"
import { Notification } from "auth-components-package"
import { Bildung } from "./Bildung.tsx"
import DropdownMenu from "../../../helpers/DropdownMenu.tsx"
import store from "../../../store/store.js"
import { useSelector } from "react-redux"

export const Profile = () => {
    const { t } = useTranslation();
    const token = useSelector((state: any) => state.token);
    const userObj = useSelector((state: UserDto | any) => state.user);
    const navigate = useNavigate();

    const profile = useSelector((state: any) => state.profile);
    const eduLevel = profile.eduLevel || userObj.education;
    const specialistArea = profile.specialistArea || userObj.speciality;
    const specialization = profile.specialization || userObj.specialization;
    const languages = [
        { key: t('rate_my.language_de') },
        { key: t('rate_my.language_en') }
    ]
    const preference = useSelector((state: any) => state.preference);
    const language = preference.language || (userObj.preferences !== null && userObj.preferences !== "" && userObj.preferences[0] === "english" ? "English" : "Deutsch");
    const [alertMessage, setAlertMessage] = useState<string | null>(null)
    const [alertHeading, setAlertHeading] = useState<string | null>(null)
    const [buttonText, setButtonText] = useState<string | null>(null)

    const [alertType, setAlertType] = useState<string>('success')

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [infoNotify, setInfoNotify] = useState<boolean>(false);

    const engVersion = "67375cbee8cff4eb470c0729";
    const gerVersion = "672db135127b502557ba057b";
    const handleImageUrl = (url: string) => {
        setImageUrl(url);
    }
    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });

    const handleFilesChange = (files: File[]) => {
        if (files.length > 0) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSrc(event.target?.result as string); // Set the base64 image URL
            };
            reader.readAsDataURL(files[0]);
        }
    };


    const [formValues, setFormValues] = useState({
        username: userObj.username || "",
        title: userObj.titel || "",
        firstName: userObj.firstname || "",
        lastName: userObj.lastname || "",
        paymentAllowed: userObj.paymentAllowed || false,
        phone: userObj.phone || "",
        accountFirstName: userObj.firstname || "",
        accountLastName: userObj.lastname || "",
        shortCv: userObj.shortCv || "",
        email: userObj.email || "",

        street: "",
        location: "",
        postalCode: "",
        iban: "",
        bank: "",
        bic: "",
    });


    const updateUserInfo = async () => {
        const userData: UserDto | any = {
            id: userObj.id,
            email: formValues.email,
            firstname: formValues.firstName,
            lastname: formValues.lastName,
            titel: formValues.title,
            phone: formValues.phone,
            username: formValues.username,
            shortCv: formValues.shortCv,
            avatar: imageUrl
        }
        if (token) {
            try {
                const user = await api.user.saveUser(userData, baseApiParams());
                store.dispatch({ type: "user/setUser", payload: user.data });
                setAlertType('success')
                if (formValues.email !== userObj.email) {
                    await api.user.changeEmail({ mail: formValues.email }, baseApiParams());
                    setInfoNotify(true);
                    setAlertHeading(t("rate_my.notification_confirm_email_heading"))
                    setAlertMessage(t("rate_my.notification_confirm_email_message"));
                    setButtonText("Ok")
                } else {
                    setAlertHeading("")
                    setAlertMessage(t("messages.information_updated_successfully"));
                    setButtonText("Skip")
                }
            } catch (err) {
                handleApiError(err, navigate)
                setAlertType('error')
                setAlertMessage(t("messages.error"))
            }
        }
    }

    const updateEducationInfo = async () => {
        const data: UserDto | any = {
            email: userObj.email,
            id: userObj.id,
            education: eduLevel,
            speciality: specialistArea,
            specialization: specialization,
        }
        if (token) {
            try {
                const user = await api.user.saveUser(data, baseApiParams());
                setAlertType('success')
                setAlertMessage(t("messages.information_updated_successfully"))
            } catch (err) {
                handleApiError(err, navigate)
                setAlertType('error')
                setAlertMessage(t("messages.error"))
            }
        }
    }

    const handleDownload = async (id: any) => {
        if (token) {
            try {
                const mediaName = await api.media.getMedia(id, baseApiParams());
                const mediaData = await api.download.getMediaDownload(id, baseApiParams())
                const dataType = "application/pdf";
                const response = await fetch(mediaData.data.message);
                const data = await response.arrayBuffer();
                const blob = new Blob([data], { type: dataType });
                const url = window.URL.createObjectURL(blob);
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.download = mediaName.data.description;
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
                setAlertType('success')
                setAlertMessage(t("messages.information_download_successfully"))
            } catch (err) {
                handleApiError(err, navigate)
                setAlertType('error')
                setAlertMessage(t("messages.error"))
            }
        }
    };


    const [isAddressSectionModified, setIsAddressSectionModified] = useState(false);
    const [isAccountSectionModified, setIsAccountSectionModified] = useState(false);

    useEffect(() => {
        const fetchInitialData = async () => {
            const userId = userObj.id.id;

            try {
                const userInfo = await api.user.getUserInfos({ userId }, baseApiParams()) as HttpResponse<any, any>;
                setFormValues({
                    ...formValues,
                    iban: userInfo.data.iban || "",
                    bank: userInfo.data.bank || "",
                    bic: userInfo.data.bic || "",
                    location: userInfo.data.location || "",
                    street: userInfo.data.street || "",
                    postalCode: userInfo.data.zipCode || "",
                });
            } catch (error) {
                console.error(t("messages.error"), error);
            }
        };

        fetchInitialData();
    }, []);

    const handleInputChange = (e: any) => {
        const { name, type, value, checked } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === "checkbox" ? checked : value,
        }));

        if (["username", "title", "firstName", "lastName", "street", "location", "postalCode", "paymenAllowed"].includes(name) && !isAddressSectionModified) {
            setIsAddressSectionModified(true);
        }

        if (["accountFirstName", "accountLastName", "iban", "bank", "bic"].includes(name) && !isAccountSectionModified) {
            setIsAccountSectionModified(true);
        }
    };


    const handlePaymentInfo = async () => {
        const bankInfo = {
            iban: formValues.iban,
            bic: formValues.bic,
            bank: formValues.bank,
            type: "bank",
            location: formValues.location,
            street: formValues.street,
            zipCode: formValues.postalCode,
        }
        try {
            const resp = await api.user.updatePaymentInfo(bankInfo, baseApiParams()) as HttpResponse<any, any>;
            setAlertType('success')
            setAlertMessage(t("messages.information_updated_successfully"))
        }
        catch (e) {
            console.log(e)
            setAlertType('error')
            setAlertMessage(t("messages.error"))
        }
    }

    return (
        <Box alignItems={"center"}>
            <Text
                fontSize="2xl"
                fontStyle="normal"
                fontWeight="medium"
                fontFamily="heading"
                lineHeight="taller"
                color="gray.900"
            >{t('rate_my.profile')}</Text>
            <Card
                paddingTop={"20px"}
                paddingBottom={"20px"}
                paddingLeft={{ base: "10px", xl: "40px" }}
                paddingRight={{ base: "10px", xl: "40px" }}
                boxShadow={"none"}
                borderRadius={"12px"}
                borderColor="gray.200"
            >
                <Stack flexDirection={{ base: "column", xl: "row" }}>
                    <CardHeader padding={"0px 0px 0px 0px"}>
                        <Text
                            fontSize="lg"
                            fontWeight="semibold"
                            fontFamily="heading"
                            fontStyle="normal"
                            lineHeight="tall"
                            color="gray.900"
                            width={{ base: "100%", xl: "110px" }}>{t('rate_my.your_profile')}</Text>
                    </CardHeader>
                    <Box width={"100%"}>
                        <CardBody padding={0}>
                            <Box width={"100%"} className="payment-form-block" gap={"15px"}>
                                <Stack padding={0} gap={{ base: "15px", md: "15px" }}>
                                    <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                                        <Stack display={"flex"} flexDirection={{ base: "column", md: "row" }} gap={{ base: "15px", md: "10px" }} padding={"0"}>
                                            <FormControl minW={{ base: "100%", xl: "240px" }}>
                                                <FormLabel className="input-label"
                                                    fontStyle="normal"
                                                    lineHeight="xs"
                                                    margin="0"
                                                    gap="base"
                                                    color="gray.900"
                                                    fontSize="base"
                                                    fontWeight="normal"
                                                >{t('general.username')}</FormLabel>
                                                <Input
                                                    name="username"
                                                    type='text'
                                                    value={formValues.username}
                                                    onChange={handleInputChange}
                                                    width={{ base: "100%", xl: "240px" }}
                                                    color="gray.900"
                                                    fontFamily="body"
                                                    fontSize="sm"
                                                    fontStyle="normal"
                                                    fontWeight="normal"
                                                    lineHeight="shorter"
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Container>
                                    <Container display={"flex"} flexDirection={{ base: "column", xl: "row" }} gap={{ base: "15px", md: "10px" }} margin={"0"} width={"100%"} maxW={"auto"} paddingInlineEnd={"0"}>

                                        <Stack display={"flex"} flexDirection={{ base: "column", xl: "row" }} gap={{ base: "15px", md: "10px" }} padding={"0"}>
                                            <FormControl minW={{ base: "100%", xl: "140px" }}>
                                                <FormLabel className="input-label"
                                                    fontStyle="normal"
                                                    lineHeight="xs"
                                                    margin="0"
                                                    gap="base"
                                                    color="gray.900"
                                                    fontSize="base"
                                                    fontWeight="normal" >{t('rate_my.title')}</FormLabel>
                                                <Input
                                                    name="title"
                                                    type='text'

                                                    value={formValues.title}
                                                    onChange={handleInputChange}
                                                    width={{ base: "100%", xl: "150px" }}
                                                    color="gray.900"
                                                    fontFamily="body"
                                                    fontSize="sm"
                                                    fontStyle="normal"
                                                    fontWeight="normal"
                                                    lineHeight="shorter" />
                                            </FormControl>
                                            <FormControl minW={{ base: "100%", xl: "295px" }}>
                                                <FormLabel className="input-label"
                                                    fontStyle="normal"
                                                    lineHeight="xs"
                                                    margin="0"
                                                    gap="base"
                                                    color="gray.900"
                                                    fontSize="base"
                                                    fontWeight="normal" >{t('rate_my.first_name')}</FormLabel>
                                                <Input
                                                    name="firstName"
                                                    type='text'

                                                    value={formValues.firstName}
                                                    onChange={handleInputChange}
                                                    width={{ base: "100%", xl: "295px" }}
                                                    color="gray.900"
                                                    fontFamily="body"
                                                    fontSize="sm"
                                                    fontStyle="normal"
                                                    fontWeight="normal"
                                                    lineHeight="shorter" />
                                            </FormControl>
                                        </Stack>

                                        <Stack display={"flex"} flexDirection={"row"} gap={{ base: "15px", md: "10px" }} padding={"0"} width={"100%"}>
                                            <FormControl minW={{ base: "100%", xl: "295px" }}>
                                                <FormLabel className="input-label"
                                                    fontStyle="normal"
                                                    lineHeight="xs"
                                                    margin="0"
                                                    gap="base"
                                                    color="gray.900"
                                                    fontSize="base"
                                                    fontWeight="normal" >{t('general.last_name')}</FormLabel>
                                                <Input
                                                    name="lastName"
                                                    type='text'

                                                    value={formValues.lastName}
                                                    onChange={handleInputChange}
                                                    width={{ base: "100%", xl: "295px" }}
                                                    color="gray.900"
                                                    fontFamily="body"
                                                    fontSize="sm"
                                                    fontStyle="normal"
                                                    fontWeight="normal"
                                                    lineHeight="shorter" />
                                            </FormControl>
                                        </Stack>
                                    </Container>

                                    <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"} display={"flex"} flexDirection={{ base: "column", xl: "row" }} gap={{ base: "15px", md: "10px" }}>
                                        <Stack display={"flex"} flexDirection={{ base: "column", xl: "row" }} gap={{ base: "15px", md: "10px" }} padding={"0"}>
                                            <FormControl minW={{ base: "100%", xl: "453px" }}>
                                                <FormLabel className="input-label"
                                                    fontStyle="normal"
                                                    lineHeight="xs"
                                                    margin="0"
                                                    gap="base"
                                                    color="gray.900"
                                                    fontSize="base"
                                                    fontWeight="normal" >{t('rate_my.email')}</FormLabel>
                                                <Input
                                                    name="email"
                                                    type='email'
                                                    value={formValues.email}
                                                    onChange={handleInputChange}
                                                    width={{ base: "100%", xl: "453px" }}
                                                    color="gray.900"
                                                    fontFamily="body"
                                                    fontSize="sm"
                                                    fontStyle="normal"
                                                    fontWeight="normal"
                                                    lineHeight="shorter" />
                                            </FormControl>
                                        </Stack>
                                        <Stack display={"flex"} flexDirection={"row"} gap={{ base: "15px", md: "10px" }} padding={"0"} width={"100%"}>
                                            <FormControl minW={{ base: "100%", xl: "295px" }}>
                                                <FormLabel className="input-label"
                                                    fontStyle="normal"
                                                    lineHeight="xs"
                                                    margin="0"
                                                    gap="base"
                                                    color="gray.900"
                                                    fontSize="base"
                                                    fontWeight="normal" >{t('general.phone')}</FormLabel>
                                                <Input
                                                    name="phone"
                                                    type='text'
                                                    value={formValues.phone}
                                                    onChange={handleInputChange}
                                                    width={{ base: "100%", xl: "295px" }}
                                                    color="gray.900"
                                                    fontFamily="body"
                                                    fontSize="sm"
                                                    fontStyle="normal"
                                                    fontWeight="normal"
                                                    lineHeight="shorter" />
                                            </FormControl>
                                        </Stack>
                                    </Container>


                                    <Box as="section" bg="bg.surface" display={'flex'} flexDirection={{ base: 'row', md: 'column' }} paddingLeft={"20px"} gap={"6px"} >
                                        <Text className="profil-pic">{t("rate_my.profile_picture")}</Text>
                                        <Container maxW="lg" display={'flex'} flexDirection={{ base: 'column', xl: 'row' }} gap={"16px"} maxWidth={'100%'} padding={0}>
                                            <WrapItem display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                                <Avatar name={`${userObj.firstname} ${userObj.lastname}`} size={'xl'} src={imageSrc ? imageSrc : userObj.avatar} height="54px" width="54px" />
                                            </WrapItem>
                                            <FormControl id="file">
                                                <Dropzone fileFormats={['JPG', 'PNG']} fileSizeLimit={3} selectedFiles={(e) => handleFilesChange(e)} handleImageUrl={(e) => handleImageUrl(e)} color="#4A5568" />
                                            </FormControl>
                                        </Container>
                                    </Box>

                                    <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                                        <FormControl>
                                            <FormLabel className="input-label"
                                                fontStyle="normal"
                                                lineHeight="xs"
                                                margin="0"
                                                gap="base"
                                                color="gray.900"
                                                fontSize="base"
                                                fontWeight="normal" >{t('rate_my.short_cv')}</FormLabel>
                                            <Textarea name="shortCv" placeholder={t('rate_my.hint_for_short_vita')} value={formValues.shortCv} onChange={handleInputChange}
                                                color="gray.900"
                                                fontFamily="body"
                                                fontSize="sm"
                                                fontStyle="normal"
                                                fontWeight="normal"
                                                lineHeight="shorter" />
                                        </FormControl>
                                    </Container>

                                </Stack>
                            </Box>
                        </CardBody>
                        <CardFooter padding={"30px 0px 30px 0px"} justifyContent={{ base: "center", md: "end" }}>
                            <Button
                                variant='outline'
                                className={'save-btn'}
                                color="white"
                                onClick={() => {
                                    updateUserInfo();
                                }}
                                fontFamily="body"
                                fontSize="md"
                                fontStyle="normal"
                                fontWeight="semibold"
                                lineHeight="base"
                                bg="primary.500"
                            >{t('rate_my.save_btn')}</Button>
                        </CardFooter>
                    </Box>

                </Stack>

                <Stack gap={0}>
                    <Stack flexDirection={{ base: "column", xl: "row" }}>
                        <CardHeader padding={"0px"}>
                            <Text fontSize="lg" fontWeight="semibold" fontFamily="heading" fontStyle="normal" lineHeight="tall" color="gray.900" width={{ base: "100%", xl: "110px" }}>{t('rate_my.address')}</Text>
                        </CardHeader>
                        <CardBody padding={0}>
                            <Box display={"flex"} flexDirection={"column"} gap={"15px"}>
                                <Container width={"100%"} margin={"0"} maxW={{ base: "100%", md: "453px" }} paddingInlineEnd={"0"}>
                                    <FormControl>
                                        <FormLabel className="input-label"
                                            fontStyle="normal"
                                            lineHeight="xs"
                                            margin="0"
                                            gap="base"
                                            color="gray.900"
                                            fontSize="base"
                                            fontWeight="normal" >{t('rate_my.street_house_number')}</FormLabel>
                                        <Input
                                            name="street"
                                            type='text'

                                            value={formValues.street}
                                            onChange={handleInputChange}
                                            width={{ base: "100%", md: "453px" }}
                                            color="gray.900"
                                            fontFamily="body"
                                            fontSize="sm"
                                            fontStyle="normal"
                                            fontWeight="normal"
                                            lineHeight="shorter"
                                        />
                                    </FormControl>
                                </Container>

                                <Container width={"100%"} margin={"0"} paddingInlineEnd={"0"} display={"flex"} flexDirection={{ base: "column", xl: "row" }} gap={"15px"}>
                                    <FormControl maxW={{ base: "100%", md: "453px" }}>
                                        <FormLabel className="input-label"
                                            fontStyle="normal"
                                            lineHeight="xs"
                                            margin="0"
                                            gap="base"
                                            color="gray.900"
                                            fontSize="base"
                                            fontWeight="normal" >{t('rate_my.location')}</FormLabel>
                                        <Input
                                            name="location"
                                            type='text'

                                            value={formValues.location}
                                            onChange={handleInputChange}
                                            width={{ base: "100%", md: "453px" }}
                                            color="gray.900"
                                            fontFamily="body"
                                            fontSize="sm"
                                            fontStyle="normal"
                                            fontWeight="normal"
                                            lineHeight="shorter" />
                                    </FormControl>
                                    <FormControl maxW={{ base: "100%", md: "290px" }}>
                                        <FormLabel className="input-label"
                                            fontStyle="normal"
                                            lineHeight="xs"
                                            margin="0"
                                            gap="base"
                                            color="gray.900"
                                            fontSize="base"
                                            fontWeight="normal" >{t('rate_my.postal_code')}</FormLabel>
                                        <Input
                                            name="postalCode"
                                            type='text'
                                            value={formValues.postalCode}
                                            onChange={handleInputChange}
                                            width={{ base: "100%", md: "290px" }}
                                            color="gray.900"
                                            fontFamily="body"
                                            fontSize="sm"
                                            fontStyle="normal"
                                            fontWeight="normal"
                                            lineHeight="shorter" />
                                    </FormControl>
                                </Container>
                            </Box>
                        </CardBody>
                    </Stack>
                    <CardFooter padding={"30px 0px 30px 0px"} justifyContent={{ base: "center", md: "end" }}>
                        <Button className={`save-btn`}
                            color="white"
                            variant='outline'
                            onClick={() => handlePaymentInfo()}
                            fontFamily="body"
                            fontSize="md"
                            fontStyle="normal"
                            fontWeight="semibold"
                            lineHeight="base"
                            bg="primary.500"
                        >{t('rate_my.save')}</Button>
                    </CardFooter>
                </Stack>
                <Stack>
                    <Stack flexDirection={{ base: "column", xl: "row" }}>
                        <CardHeader padding={"0px"}>
                            <Text fontSize="lg" fontWeight="semibold" fontFamily="heading" fontStyle="normal" lineHeight="tall" color="gray.900" width={{ base: "100%", xl: "110px" }}>{t('general.education')}</Text>
                        </CardHeader>
                        <Box width={"100%"}>
                            <CardBody padding={0}>
                                <Box width={{ base: "100%", xl: "453px" }} className="payment-form-block" gap={"15px"}>
                                    <Bildung showInfo={true} />
                                </Box>
                            </CardBody>
                            <CardFooter padding={"30px 0px 30px 0px"} justifyContent={{ base: "center", md: "end" }}>
                                <Button className={`save-btn`}
                                    color="white" variant='outline' onClick={() => {
                                        updateEducationInfo();
                                    }}
                                    fontFamily="body"
                                    fontSize="md"
                                    fontStyle="normal"
                                    fontWeight="semibold"
                                    lineHeight="base"
                                    bg="primary.500">{t('rate_my.save_btn')}</Button>
                            </CardFooter>
                        </Box>
                    </Stack>
                </Stack>

                <Stack>
                    <CardHeader padding={"0px"}>
                        <Text fontSize="lg" fontWeight="semibold" fontFamily="heading" fontStyle="normal" lineHeight="tall" color="gray.900" width={{ base: "100%", xl: "110px" }}>{t('rate_my.conditions')}</Text>
                        <Stack direction={"row"} alignItems={"baseline"} padding={"15px 0px 0px 0px"}>
                            <HiCheckCircle color="teal" />
                            <Text className="date-info">{"seit 16.Jan.2024"}</Text>
                        </Stack>
                    </CardHeader>
                    <CardBody padding={"0px"}>
                        <Stack className="conditions-content" padding={0}>
                            <Text className="language-text">{t('general.language')}</Text>
                            <Stack className="language-dropdown" padding={"50px 0px 0px 0px"}>
                                <DropdownMenu
                                    label={language}
                                    value={language?.key}
                                    options={languages}
                                    onSelect={(e) => {
                                        store.dispatch({ type: "preference/setLanguage", payload: e });
                                    }}
                                />
                            </Stack>

                        </Stack>
                    </CardBody>

                    <CardFooter padding={"10px 0px 10px 0px"} justifyContent={{ base: "center", md: "start" }}>
                        <Button className="download-btn"
                            color={"white"}
                            leftIcon={<IoDownloadOutline />}
                            onClick={() => {
                                if (language === "Deutsch") {
                                    handleDownload(gerVersion);
                                } else {
                                    handleDownload(engVersion)
                                }
                            }}
                            fontFamily="body"
                            fontSize="md"
                            fontStyle="normal"
                            fontWeight="semibold"
                            lineHeight="base"
                            bg="primary.500"
                        >{t('rate_my.download_btn')}</Button>
                    </CardFooter>
                </Stack>
            </Card>
            {alertMessage && <Notification heading={alertHeading} message={alertMessage} type={alertType} buttonText={buttonText} onClose={() => setAlertMessage(null)} infoNotify={infoNotify} />}
        </Box>
    )
}