import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    fonts: {
        heading: 'Open Sans, Inter, sans-serif',
        body: 'Inter, sans-serif',
        input: 'Inter, sans-serif',
        label: 'Inter, sans-serif',

    },
    fontSizes: {
        base: '0.625rem', // 10px
        xs: '0.75rem',    // 12px
        sm: '0.875rem',   // 14px
        md: '1rem',       // 16px
        lg: '1.125rem',   // 18px
        xl: '1.25rem',    // 20px
        '2xl': '1.5rem',  // 24px
        '3xl': '1.875rem', // 30px
        '4xl': '2.25rem',  // 36px
    },
    gap: {
        base: '0.625rem', // 10px
        xs: '0.75rem',    // 12px
        sm: '0.875rem',   // 14px
        md: '1rem',       // 16px
        lg: '1.125rem',   // 18px
        xl: '1.25rem',    // 20px
    },
    fontWeights: {
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
    },
    fontStyle: {
        normal: 'normal',
        bold: 'bold',
        italic: 'italic',
        underline: 'underline',
        lineThrough: 'line-through',
        none: 'none',
        initial: 'initial',
        inherit: 'inherit',
    },
    lineHeights: {
        normal: 'normal',
        none: '1rem',        // 16px
        xs: '1.125rem',      // 18px
        shorter: '1.25rem',  // 20px
        short: '1.375rem',   // 22px
        base: '1.5rem',      // 24px
        tall: '1.625rem',    // 26px
        taller: '2rem',      // 32px
    },
    letterSpacings: {
        tighter: '-0.05em',
        tight: '-0.025em',
        normal: '0',
        wide: '0.025em',
        wider: '0.05em',
        widest: '0.1em',
    },
    colors: {
        primary: {
            50: '#E6FFFF',
            100: '#B8F5F5',
            200: '#8AEBEB',
            300: '#5CE1E1',
            400: '#2ED7D7',
            500: '#319795', // Main color
            600: '#287B7A',
            700: '#1F5F5E',
            800: '#164342',
            900: '#0D2726'
        },
        gray: {
            50: '#F7FAFC',
            100: '#EDF2F7',
            200: '#E2E8F0',
            300: '#CBD5E0',
            400: '#A0AEC0',
            500: '#718096',
            600: '#4A5568',
            700: '#2D3748',
            800: '#1A202C',
            900: '#171923'
        },
        success: {
            50: '#F0FDF4',
            100: '#DCFCE7',
            500: '#22C55E',
            600: '#16A34A'
        },
        error: {
            50: '#FEF2F2',
            100: '#FEE2E2',
            500: '#EF4444',
            600: '#DC2626'
        },
        warning: {
            50: '#FFFBEB',
            100: '#FEF3C7',
            500: '#F59E0B',
            600: '#D97706'
        }
    },
    bg: {
        primary: 'colors.primary',
        gray: 'colors.gray',
        success: 'colors.success',
        error: 'colors.error',
        warning: 'colors.warning'
    },
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
})

export default theme 