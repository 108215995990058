import React from 'react';
import {
    Box,
    Center,
    Flex,
    Heading,
    Stack,
    Text,
} from '@chakra-ui/react'
import Logo from '../../assets/img/logo_mediMESH_weis_large.svg'
import bg from '../../assets/img/login_image.jpg'

import './Login.scss'
import { useTranslation } from "react-i18next";
import { SignInForm } from 'auth-components-package';
import store from '../../store/store';
import { loadUserInfo } from '../../helpers/loadUserInfo';

const Login = () => {

    const { t } = useTranslation();
    const reCaptchaKey = process.env.REACT_APP_SITE_KEY || '';
    const dispatch = store.dispatch;
    const API_URL = process.env.REACT_APP_API_URL || '';


    return (
        <Flex
            minH={{ base: 'auto', md: '100vh' }}
            bgGradient={{
                md: 'linear(to-r, bg.accent.default 50%, bg.surface 50%)',
            }}
            className="login-container"
        >
            <Flex maxW="full" mx="auto" width="full">
                <Box flex="1" display={{ base: 'none', md: 'block' }} style={{ flex: '60%' }}>
                    <Flex
                        direction="column"
                        px={{ base: '4', md: '8' }}
                        height="full"
                        color="fg.accent.default"
                        position="relative"
                        className="login-left-side"
                    >
                        <Box
                            as="div"
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            zIndex="0"
                            style={{
                                backgroundImage: `url(${bg})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                opacity: 0.8,
                            }}
                        />
                        <Box
                            as="div"
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            zIndex="0"
                            style={{
                                backgroundColor: 'rgba(44, 122, 123, 0.8)',
                            }}
                        />
                        <Flex align="center" h="24" paddingLeft={"50px"} py={130} zIndex={2}>
                            <img src={Logo} alt='mediMESH' />
                        </Flex>
                        <Flex flex="1" flexDirection={'column'} paddingLeft={"50px"} zIndex={2}>
                            <Stack spacing="6" gap={"32px"}>
                                <Heading width={'100%'} className='heading'>{t("login_register_page.welcome_medimesh")} 👋</Heading>
                                <Text className='sub-text'>
                                    {t("smartslide.future_of_medical_education")}
                                </Text>
                                <Text className='sub-sub-text'>
                                    {t("smartslide.create_share_and_learn")}
                                </Text>
                            </Stack>
                        </Flex>
                    </Flex>
                </Box>
                <Center flex="1" style={{ flex: '40%' }}>
                    <SignInForm
                        px={{ base: '4', md: '8' }}
                        py={{ base: '12', md: '48' }}
                        width="full"
                        maxW="md"

                        tokenstorage={'token/setToken'}
                        loadUserInfo={loadUserInfo}
                        reCaptchaKey={reCaptchaKey}
                        dispatch={dispatch}
                        t={t}
                        apiURL={API_URL}
                    />
                </Center>
            </Flex>
        </Flex>
    )
}

export default Login