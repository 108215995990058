import { createSlice } from '@reduxjs/toolkit';
import { MiscFunctions } from 'helper-functions-package';

const interactionsSlice = createSlice({
    name: 'interactions',
    initialState: {
        interactions: [],
        annotations: [],
        comment: null,
        annotation: null,
        commentLoad: false,
        mainCommentActionPosition: 0,
        replyCommentActionBtnHeight: 0,
        medias: [],
        clickedReply: null,
        changesMade: false,
        commentCreate: false,
        commentReply: false,
    }, // Initial comment value
    reducers: {
        setCommentLoad(state) {
            state.commentLoad = true;
        },
        clearCommentLoad(state) {
            state.commentLoad = false;
        },
        setVideoInteractions(state, action) {
            state.interactions = action.payload;
        }, // Update video interactions
        clearVideoInteractions(state) {
            state.interactions = [];
            state.annotations = [];
        }, // Clear video interactions
        deleteComment: (state, action) => {
            state.interactions = state.interactions.filter(elem => elem.id !== action.payload);
            state.annotations = state.annotations.filter(elem => elem.id !== action.payload);
            if (state.comment !== null && state.comment.id === action.payload) {
                state.comment = null;
            }
        },
        setAnnotations(state, action) {
            state.annotations = action.payload.map(annotation => {
                const interaction = state.interactions.find(i => i.id === annotation.id);
                return {
                    ...annotation,
                    commentSettings: {
                        ...annotation.commentSettings,
                        stopWhenReached: interaction?.commentSettings?.stopWhenReached || false
                    }
                };
            });;
        },
        clearAnnotations(state) {
            state.annotations = [];
            state.annotation = null;
            state.comment = null;
        },
        setAnnotation(state, action) {
            state.annotation = action.payload;
        },
        clearAnnotation(state) {
            state.annotation = null;
        },
        clearClickedComment(state) {
            state.comment = null;
            state.annotation = null;
            state.replyCommentActionBtnHeight = 0;
            state.mainCommentActionPosition = 0;
        },
        setClickedComment(state, action) {
            state.comment = state.interactions.filter(elem => elem.id === action.payload.id)[0];
            state.annotation = state.annotations.filter(elem => elem.id === action.payload.id)[0] || null;
        },
        addInteraction(state, action) {
            const index = state.interactions.findIndex(
                interaction => MiscFunctions.DateString2Seconds(interaction.timestamp) > MiscFunctions.DateString2Seconds(action.payload.timestamp)
            );

            if (index === -1) {
                // If no element with a larger timestamp was found, append to the end
                state.interactions.push(action.payload);
            } else {
                // Insert at the found position
                state.interactions.splice(index, 0, action.payload);
            }
            let newAnnotation = {
                "id": action.payload.id,
                "left": action.payload.position[0] * 100 + "%",
                "top": action.payload.position[1] * 100 + "%",
                "category": action.payload.category[0].type,
                "autoplay": action.payload?.commentSettings?.autoplay !== undefined ? action.payload.commentSettings.autoplay : false,
                "startTime": MiscFunctions.DateString2Seconds(action.payload.timestamp),
                "commentSettings": {
                    stopWhenReached: action.payload?.commentSettings?.stopWhenReached !== undefined ? action.payload.commentSettings.stopWhenReached : false,
                }
            }
            state.annotations.push(newAnnotation);
        },
        changeComment(state, action) {
            console.log('comment.json action.payload', action.payload);
            const index = state.interactions.findIndex(elem => elem.id === action.payload.id);
          
            // Update existing annotation instead of creating a new one
            const annotationIndex = state.annotations.findIndex(elem => elem.id === action.payload.id);
            if (annotationIndex !== -1) {
                state.annotations[annotationIndex] = {
                    ...state.annotations[annotationIndex],
                    "left": action.payload.position[0] * 100 + "%",
                    "top": action.payload.position[1] * 100 + "%",
                    "category": action.payload.category[0].type,
                    "autoplay": action.payload?.commentSettings?.autoplay !== undefined ? action.payload.commentSettings.autoplay : false,
                    "startTime": MiscFunctions.DateString2Seconds(action.payload.timestamp),
                    "commentSettings": {
                        stopWhenReached: action.payload?.commentSettings?.stopWhenReached !== undefined ? action.payload.commentSettings.stopWhenReached : false,
                    }
                };
            }

            if (index !== -1) {
                state.interactions[index] = action.payload;
            }
            state.comment = action.payload;
        },
        setMainCommentActionPosition(state, action) {
            state.mainCommentActionPosition = action.payload;
        },
        setReplyCommentActionBtnHeight(state, action) {
            state.replyCommentActionBtnHeight = action.payload;
        },
        setReplies(state, action) {
            const index = state.interactions.findIndex(elem => elem.id === action.payload.commentID);
            if (index !== -1) {
                state.interactions[index].replies = action.payload.replies;
            }
        },
        setClickedReply(state, action) {
            state.clickedReply = action.payload;
        },
        clearClickedReply(state) {
            state.clickedReply = null;
        },
        addReply(state, action) {
            console.log(action.payload);
            const index = state.interactions.findIndex(elem => elem.id === action.payload.commentID);
            console.log(index);
            if (index !== -1) {
                if (MiscFunctions.isNull(state.interactions[index].replies)) {
                    state.interactions[index].replies = [];
                }
                state.interactions[index].replies.push(action.payload.reply);
            }
        },
        changeReply(state, action) {
            const index = state.interactions.findIndex(elem => elem.id === action.payload.commentID);
            if (index !== -1) {
                const replyIndex = state.interactions[index].replies.findIndex(elem => elem.id === action.payload.reply.id);
                if (replyIndex !== -1) {
                    state.interactions[index].replies[replyIndex] = action.payload.reply;
                }
            }
        },
        deleteReply(state, action) {
            const index = state.interactions.findIndex(elem => elem.id === action.payload.commentID);
            if (index !== -1) {
                state.interactions[index].replies = state.interactions[index].replies.filter(elem => elem.id !== action.payload.reply.id);
            }
        },
        deleteMedias(state, action) {
            const commentMedias = action.payload.commentMedias.filter(elem => elem.id !== action.payload.deleteElem)
            const index = state.interactions.findIndex(elem => elem.id === action.payload.commentID);
            if (index !== -1) {
                state.interactions[index].medias = commentMedias;
            }
            state.medias = state.interactions[index].medias;
        },
        uploadMedias(state, action) {
            const { commentID, medias, reply, commentReply, parentID } = action.payload;
            
            // Handle media state updates based on context
            if (reply) {
                const parentIndex = state.interactions.findIndex(elem => elem.id === parentID);
                if (parentIndex !== -1) {
                    const replyIndex = state.interactions[parentIndex].replies.findIndex(elem => elem.id === commentID);
                    if (replyIndex !== -1) {
                        // Update medias for the reply
                        if (medias) {
                            state.interactions[parentIndex].replies[replyIndex].medias = [...(state.interactions[parentIndex].replies[replyIndex].medias || []), medias];
                        }
                        state.medias = state.interactions[parentIndex].replies[replyIndex].medias;
                    }
                }
            } else if (commentReply) {
                state.medias.push(medias);
            } else {
                const commentIndex = state.interactions.findIndex(elem => elem.id === commentID);
                if (commentIndex !== -1) {
                    // Keep existing medias if no new media is added
                    if (!medias) {
                        state.medias = state.interactions[commentIndex].medias;
                    } else {
                        state.medias = [...state.interactions[commentIndex].medias, medias];
                    }
                    state.comment = state.interactions.find(elem => elem.id === action.payload.id) || null;
                }
                else {
                    state.medias.push(medias);
                }
            }
        },
        clearMedias(state, action) {
            state.medias = []
        },
        setChangesMade(state, action) {
            state.changesMade = action.payload;
        },
        setCommentCreate(state, action) {
            state.commentCreate = action.payload;
        },
        setCommentReply(state, action) {
            state.commentReply = action.payload;
        },
        clearCommentReply(state) {
            state.commentReply = false;
        }
    },
});

export const {
    setVideoInteractions,
    clearVideoInteractions,
    deleteComment,
    setAnnotations,
    setClickedComment,
    setAnnotation,
    clearAnnotation,
    clearAnnotations,
    clearClickedComment,
    addInteraction,
    changeComment,
    setCommentLoad,
    clearCommentLoad,
    setMainCommentActionPosition,
    setReplyCommentActionBtnHeight,
    setReplies,
    addReply,
    changeReply,
    deleteReply,
    deleteMedias,
    uploadMedias,
    setClickedReply,
    clearClickedReply,
    clearMedias,
    setChangesMade,
    setCommentCreate,
    setCommentReply,
    clearCommentReply
} = interactionsSlice.actions;

export default interactionsSlice.reducer;


