import React from 'react'
import {
    Box,
    HStack,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react'

import { SidebarButton } from './SidebarButton.tsx';
import { useTranslation } from 'react-i18next';
import { Navigation } from './Navigation.tsx';
import { UserDto } from 'my-api-client-package';
import { AvatarMenu } from '../AvatarMenu.tsx';
import { useSelector } from 'react-redux';


interface SidebarProps {
    userObj: UserDto | null;
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
    const { t } = useTranslation()
    const userObj = props.userObj;
    
    // Get language preferences from Redux states
    const preference = useSelector((state: any) => state.preference);
    const languageState = useSelector((state: any) => state.language);
    
    // Determine language using both states
    const language = preference.language || 
                    (languageState?.userLanguage ? (languageState.userLanguage === 'english' ? 'English' : 'Deutsch') : 
                    (userObj?.preferences !== null && userObj?.preferences !== "" && userObj?.preferences[0] === "english" ? "English" : "Deutsch"));

    // Define URLs based on language
    const getTermsUrl = () => {
        const isEnglish = language.toLowerCase() === "english" || languageState?.userLanguage === "english";
        return isEnglish ? 'https://medimesh.de/termsofuse/' : 'https://medimesh.de/nutzungsbedingungen/';
    };

    const getPrivacyUrl = () => {
        const isEnglish = language.toLowerCase() === "english" || languageState?.userLanguage === "english";
        return isEnglish ? 'https://medimesh.de/privacypolicy/' : 'https://medimesh.de/datenschutz/';
    };

    return (
        <Stack
            flex="1"
            maxW={{ base: 'full', sm: 'xs' }}
            py={{ base: '6', sm: '8' }}
            px={{ base: '4', sm: '6' }}
            bg="#fff"
            borderRightWidth="1px"
            justifyContent="space-between"
            zIndex="10"
            width={{ base: '100%', md: '320px' }}
            className='my-sidebar'
            height={{ base: "90vh", sm: "94vh" }}
        >
            <Stack spacing="8" marginTop={{ base: "0", sm: "30px" }} flex="1" overflowY={"auto"}>
                <Navigation />
            </Stack>
            <StackDivider marginTop="0" borderBottomWidth={"1px"} borderColor={""} />
            <Stack spacing="4" divider={<StackDivider />}>
                <Stack spacing="1" color={"#4A5568"} fontWeight={"600"}>
                    <SidebarButton _hover={{ color: "#2C7A7B" }} fontSize={"14px"}><a href={getTermsUrl()} target="_blank" rel="noopener noreferrer">{t("rate_my.terms_of_use")}</a></SidebarButton>
                    <SidebarButton _hover={{ color: "#2C7A7B" }} fontSize={"14px"}><a href={getPrivacyUrl()} target="_blank" rel="noopener noreferrer">{t("rate_my.privacy_policy")}</a></SidebarButton>
                </Stack>
                <HStack spacing="3" justify="space-between">
                    <HStack spacing="3">
                        <AvatarMenu userObj={userObj} />

                        <Box>
                            <Text textStyle="sm" fontWeight="medium">
                                {userObj && `${userObj.firstname ? userObj.firstname + " " + userObj.lastname : (userObj.email).split("@")[0]}`}
                            </Text>
                            <Text textStyle="sm" color="fg.muted" _hover={{ color: "#2C7A7B" }}>
                                {userObj && `${userObj.email}`}
                            </Text>
                        </Box>
                    </HStack>
                </HStack>
            </Stack>
        </Stack>
    )
}