import { createSlice } from '@reduxjs/toolkit';

const segmentSlice = createSlice({
    name: 'selectedSegment',
    initialState: {
        currentSegment: null,
        editSegment: null,
        tasksSegments: [],
        editTasksSegments: []
    },
    reducers: {
        setTaskSegment: (state, action) => {
            state.currentSegment = state.tasksSegments.filter(elem => elem.id === action.payload)[0];
            if (state.editTasksSegments.length !== 0) {
                state.editSegment = state.editTasksSegments.filter(elem => elem.id === action.payload)[0];
            }
        },
        clearTaskSegment: (state) => {
            state.currentSegment = null;
            state.editSegment = null;
        },
        setCurrentTaskSegment: (state, action) => {
            state.currentSegment = action.payload;
        },
        setEditTaskSegment: (state, action) => {
            state.editSegment = action.payload;

            if (state.editTasksSegments.length === 0) {
                state.editTasksSegments = JSON.parse(JSON.stringify(state.tasksSegments));
            }
           
            const index = state.editTasksSegments.findIndex(
                segment => segment.id === action.payload.id
            );
            
            if (index !== -1) {
                state.editTasksSegments[index] = action.payload;
            }
        },
        clearEditTaskSegment: (state) => {
            
            const indexEditSegment = state.editTasksSegments.findIndex(
                segment => segment.id === state.editSegment.id
            );
            const indexSegment = state.tasksSegments.findIndex(
                segment => segment.id === state.editSegment.id
            );
            if (indexEditSegment !== -1) {
                if (state.editTasksSegments.length !== 0) {
                    state.editTasksSegments[indexEditSegment] = state.tasksSegments[indexSegment];
                }
            }
            state.editSegment = null;
        },
        setTasksSegments: (state, action) => {
            state.tasksSegments = action.payload;
        },
        setEditTasksSegments: (state, action) => {
            state.editTasksSegments = action.payload;
        },
        clearTasksSegments: (state) => {
            state.tasksSegments = [];
            state.editTasksSegments = [];
        },
        clearEditTasksSegments: (state) => {
            state.editTasksSegments = [];
        },
        addTaskSegment: (state, action) => {
            state.tasksSegments.push(action.payload);
            state.editTasksSegments = JSON.parse(JSON.stringify(state.tasksSegments))
        },
        deleteTaskSegment: (state, action) => {
            state.tasksSegments = state.tasksSegments.filter(elem => elem.id !== action.payload);
            state.editTasksSegments = state.editTasksSegments.filter(elem => elem.id !== action.payload);
            state.editSegment = null;
            state.currentSegment = null;
        }
    },
});

export const { 
    setTaskSegment, 
    clearTaskSegment, 
    setCurrentTaskSegment,
    setEditTaskSegment, 
    clearEditTaskSegment,
    setTasksSegments,
    setEditTasksSegments,
    clearTasksSegments,
    clearEditTasksSegments,
    addTaskSegment,
    deleteTaskSegment
 } = segmentSlice.actions;

export default segmentSlice.reducer;
