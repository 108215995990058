import React, { Component } from "react";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { Api, baseApiParams } from "my-api-client-package";
class ToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: this.props.isActive,
      value: this.props.value,
      userObject: this.props.userObject,
      api: new Api({
        baseUrl: process.env.REACT_APP_API_URL
      })
    };
  }

  toggleButton = async () => {
    const data = await this.state.api.user.updatePreferences({preferences: [this.props.value]}, baseApiParams());
    this.props.hotRefreshUserObject(data.data);
    this.checkValue(MiscFunctionsLocal.createUserObject(data.data));
  };

  checkValue = (data) => {
    if (data.preferences.includes(this.props.value)) {
      this.setState({
        isActive: true,
      })
    }else{
      this.setState({
        isActive: false,
      })
    }
  }
  render() {
    const { isActive } = this.state;
    return (
      <div
        className={`toggle-button ${isActive ? "active" : ""}`}
        onClick={this.toggleButton}
      >
        <div className="handler"></div>
      </div>
    );
  }
}

export default ToggleButton;
