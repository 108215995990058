/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Preprocessing Backend API Documentation
 * @version 1.0
 * @baseUrl /
 * @contact API Support <support@example.com>
 *
 * API Documentation for the Backend Service
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  aiprocessing = {
    /**
     * No description
     *
     * @tags AI Processing
     * @name AudioanalyseCreate
     * @summary Analyze audio tracks of a video using AI. Can process single or multiple audio channels separately.
     * @request POST:/aiprocessing/audioanalyse/
     * @secure
     */
    audioanalyseCreate: (
      body: {
        /**
         * Whether this task is for admin use only
         * @default true
         */
        adminOnly?: boolean;
        /**
         * Enable debug mode for detailed processing information
         * @default false
         */
        debug?: boolean;
        /**
         * Language for the transcription and analysis
         * @default "English"
         * @example "English"
         */
        language?: string;
        /**
         * AI model to be used for audio analysis
         * @default "gpt-4o"
         * @example "gpt-4o"
         */
        model?: string;
        /**
         * Operation name for the current processing session
         * @default ""
         * @example "operation_X"
         */
        op_name?: string;
        /** Previous rough analysis results. If not provided, only rough analysis will be performed and returned */
        roughAnalysisResults?: any[];
        /**
         * Whether to analyze audio channels separately. If true, processes up to 2 channels independently
         * @default false
         */
        separateChannel?: boolean;
        /**
         * Whether to add subtasks for each audio segment analysis
         * @default true
         */
        subtask?: boolean;
        /**
         * The ID of the video to be analyzed
         * @example "xxxxxxxxxxxxxxxxx"
         */
        video_id?: string;
        /**
         * Location of workflow library
         * @default "DEV"
         */
        workflowLibraryOrt?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * The timestamp when the analysis started
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "/aiprocessing/audioanalyse/ started with id thread_789" */
          message?: string;
          /** @example "Running" */
          status?: string;
          /**
           * ID of the thread executing the analysis task
           * @example "thread_789"
           */
          thread_id?: string;
        },
        void
      >({
        path: `/aiprocessing/audioanalyse/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI Processing
     * @name CuttingsCreate
     * @summary Analyze audio tracks of a video using AI. Can process single or multiple audio channels separately.
     * @request POST:/aiprocessing/cuttings/
     * @secure
     */
    cuttingsCreate: (
      body: {
        /**
         * Whether to use admin only or not
         * @example false
         */
        adminOnly?: boolean;
        /**
         * The name of the operation
         * @example ""
         */
        op_name?: string;
        /**
         * The pre-analysis to be used
         * @example ""
         */
        pre_analysis?: string;
        /**
         * The target duration of the cutting
         * @example "As short as possible"
         */
        target_duration?: string;
        /**
         * The ID of the video to be analyzed
         * @example "xxxxxxxxxxxxxxxxx"
         */
        video_id?: string;
        /**
         * The location of the workflow library
         * @example "DEV"
         */
        workflowLibraryOrt?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "Cutting process started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/aiprocessing/cuttings/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI Processing
     * @name SnippetsCreate
     * @summary This endpoint allows the user to submit video snippets for AI processing.
     * @request POST:/aiprocessing/snippets/
     * @secure
     */
    snippetsCreate: (
      body: {
        /**
         * Whether this task is for admin use only
         * @default true
         */
        adminOnly?: boolean;
        /**
         * Enable debug mode
         * @default false
         */
        debug?: boolean;
        /**
         * Language for the transcription
         * @default "English"
         */
        language?: string;
        /**
         * AI model to be used for processing the snippets
         * @default "gpt-4o"
         */
        model?: string;
        /**
         * Operation name for the current processing session
         * @example "operation_X"
         */
        op_name?: string;
        /**
         * Remove the first snippet
         * @default true
         */
        removeFirstSnippet?: boolean;
        /** Previous rough analysis results */
        roughAnalysisResults?: any[];
        /**
         * Process as a single task
         * @default false
         */
        singleTask?: boolean;
        /**
         * Start time offset in seconds
         * @default 12
         */
        starttime?: number;
        /**
         * Whether to add subtasks for each snippet analysis
         * @default false
         */
        subtask?: boolean;
        /**
         * The ID of the video to which the snippets belong
         * @example "xxxxxxxxxxxxxxxxx"
         */
        video_id?: string;
        /**
         * Location of workflow library
         * @default "DEV"
         */
        workflowLibraryOrt?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format date-time */
          date_started?: string;
          /** @example "Process started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
          /** @example "thread_123" */
          thread_id?: string;
        },
        void
      >({
        path: `/aiprocessing/snippets/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI Processing
     * @name SpeakertextCreate
     * @summary Create a speakertext based on the segment description (task and subtask level possible).
     * @request POST:/aiprocessing/speakertext/
     * @secure
     */
    speakertextCreate: (
      body: {
        processing?: {
          audioproperties?: {
            /**
             * ID of the segment object
             * @example "segment_123"
             */
            id?: string;
            /**
             * Language of the speakertext
             * @example "English"
             */
            language?: string;
            /**
             * Name of the operation (optional)
             * @example "operation_123"
             */
            op_name?: string;
            /**
             * Pre-analysis to be used (optional)
             * @example "pre_analysis_123"
             */
            pre_analysis?: string;
            /**
             * Transcript to be used (optional)
             * @example "transcript_123"
             */
            transcript?: string;
          };
          /**
           * Server name or address
           * @example "MAIN"
           */
          server?: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "Speakertext creation started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void | {
          /** @example "An unexpected error occurred: {error_message}" */
          message?: string;
          /** @example "Error" */
          status?: string;
        }
      >({
        path: `/aiprocessing/speakertext/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  audio = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name AttachCreate
     * @summary Attach given audios at given times to given video file.
     * @request POST:/audio/attach/
     * @secure
     */
    attachCreate: (
      body: {
        /**
         * Directory containing all audio files to be attached
         * @example "/path/to/audio/files/"
         */
        audio_files?: string;
        /**
         * Path to text file containing all audio timing information
         * @example "/path/to/timing.txt"
         */
        audio_times?: string;
        /**
         * Path of the video file to which audio will be attached
         * @example "/path/to/video.mp4"
         */
        video_file?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "Processing started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/audio/attach/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Audio
     * @name CreateCreate
     * @summary Create a new audio file based on a text.
     * @request POST:/audio/create/
     * @secure
     */
    createCreate: (
      body: {
        processing?: {
          audioproperties?: {
            /** Gender of the voice */
            gender?: "male" | "female";
            /**
             * ID of the segment object (optional)
             * @example "segment_123"
             */
            id?: string;
            /**
             * Language for text-to-speech
             * @example "English"
             */
            language?: string;
            /**
             * Text to be converted to speech
             * @example "This is the text that will be spoken"
             */
            speakertext?: string;
          };
          /**
           * Server name or address
           * @example "MAIN"
           */
          server?: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "Audio creation started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void | {
          /** @example "An unexpected error occurred: {error_message}" */
          message?: string;
          /** @example "Error" */
          status?: string;
        }
      >({
        path: `/audio/create/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Third party
     * @name ShortenCreate
     * @summary Shorten a given text with a time value to a new time limit.
     * @request POST:/audio/shorten/
     * @secure
     */
    shortenCreate: (
      body: {
        /**
         * New time limit in seconds that the text should fit within
         * @example "20"
         */
        limit?: string;
        /**
         * Current text that needs to be shortened
         * @example "This is a long text that needs to be shortened to fit within the time limit"
         */
        text?: string;
        /**
         * Current duration in seconds of the audio file which is the converted text
         * @example "30"
         */
        time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * Estimated duration of the shortened text in seconds
           * @example "19.5"
           */
          estimated_duration?: string;
          /**
           * The shortened version of the input text
           * @example "This is a shortened text that fits the time limit"
           */
          shortened_text?: string;
        },
        void
      >({
        path: `/audio/shorten/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  blockcut = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name ProcessingCreate
     * @summary Generate a Blockcutting command from processing object.
     * @request POST:/blockcut/processing/
     * @secure
     */
    processingCreate: (
      body: {
        /** Processing object containing video information and cutting parameters */
        processing?: {
          /** Array of time segments to cut */
          segments?: {
            /**
             * End time in seconds
             * @example 20.5
             */
            end?: number;
            /**
             * Start time in seconds
             * @example 10.5
             */
            start?: number;
          }[];
          /**
           * ID of the video to be cut
           * @example "video_123"
           */
          video_id?: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * Generated FFMPEG command
           * @example "ffmpeg -i input.mp4 -ss 10.5 -t 10.0 output.mp4"
           */
          command?: string;
          /**
           * Status of the command generation
           * @example "success"
           */
          status?: string;
        },
        void
      >({
        path: `/blockcut/processing/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  checkStatus = {
    /**
     * No description
     *
     * @tags Threads
     * @name CheckStatusDetail
     * @summary Check the status of a thread with a unique thread id.
     * @request GET:/check_status/{thread_id}/
     * @secure
     */
    checkStatusDetail: (threadId: string, params: RequestParams = {}) =>
      this.request<
        {
          /**
           * Timestamp when the thread was started
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /**
           * Current status of the thread
           * @example "Running"
           */
          status?: string;
          /**
           * The ID of the thread that was checked
           * @example "thread_123"
           */
          thread_id?: string;
        },
        void
      >({
        path: `/check_status/${threadId}/`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  concatvideo = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name ConcatvideoCreate
     * @summary Concatenate session videos from a given directory.
     * @request POST:/concatvideo/
     * @secure
     */
    concatvideoCreate: (
      body: {
        /** Directory containing files to be concatenated */
        input: string;
        /** Output directory for the concatenated video */
        output: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format date-time */
          date_started?: string;
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/concatvideo/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  create = {
    /**
     * No description
     *
     * @tags Backend
     * @name ThumbnailCreate
     * @summary Create a thumbnail of a pdf or image.
     * @request POST:/create/thumbnail/
     * @secure
     */
    thumbnailCreate: (
      body: {
        /** The file path of the base file which will be used as a thumbnail */
        file_path?: string;
        /** The ID of the media file which should get a new thumbnail */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example "Thumbnail was created" */
          status?: string;
        },
        void
      >({
        path: `/create/thumbnail/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backend
     * @name ThumbnailMediaCreate
     * @summary Create a thumbnail from HLS media stream.
     * @request POST:/create/thumbnail/media/
     * @secure
     */
    thumbnailMediaCreate: (
      body: {
        /** The ID of the media file which should get a new thumbnail */
        id?: string;
        /** The HLS stream URL from which to create the thumbnail */
        url?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "HLS thumbnail creation started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/create/thumbnail/media/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backend
     * @name ThumbnailUploadedCreate
     * @summary Create a thumbnail from an uploaded file (supports PDF, image, video, PowerPoint).
     * @request POST:/create/thumbnail/uploaded/
     * @secure
     */
    thumbnailUploadedCreate: (
      body: {
        /** The ID of the media file which should get a new thumbnail */
        id?: string;
        /** The download URL of the media file */
        url?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example "Thumbnail was created" */
          status?: string;
        },
        void
      >({
        path: `/create/thumbnail/uploaded/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  createmultiview = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name CreatemultiviewCreate
     * @summary Create a multiview from four videos with provided offsets.
     * @request POST:/createmultiview/
     * @secure
     */
    createmultiviewCreate: (
      body: {
        /** The offset in milliseconds for the first video */
        offset1?: string;
        /** The offset in milliseconds for the second video */
        offset2?: string;
        /** The offset in milliseconds for the third video */
        offset3?: string;
        /** The offset in milliseconds for the fourth video */
        offset4?: string;
        /** Output path to determine where to store results */
        output_path?: string;
        /** The resolution of the output file */
        resolution?: string;
        /** The file path of the first video */
        vid1_path?: string;
        /** The file path of the second video */
        vid2_path?: string;
        /** The file path of the third video */
        vid3_path?: string;
        /** The file path of the fourth video */
        vid4_path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "Process started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/createmultiview/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  createvideo = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name CreatevideoCreate
     * @summary Create a new video based on a provided cutting file.
     * @request POST:/createvideo/
     * @secure
     */
    createvideoCreate: (
      body: {
        /** The cutting text file path containing video path and timestamps */
        file_path: string;
        /** Output path for results */
        output_path: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format date-time */
          date_started?: string;
          /** @example "Video creation started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/createvideo/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  getResult = {
    /**
     * No description
     *
     * @tags Threads
     * @name GetResultDetail
     * @summary Get the result of a thread with a unique thread id.
     * @request GET:/get_result/{thread_id}/
     * @secure
     */
    getResultDetail: (threadId: string, params: RequestParams = {}) =>
      this.request<
        {
          /**
           * The result data of the thread
           * @example {"data":{"duration":"00:05:30","processed_items":10},"result":"Operation successful","status":"Completed"}
           */
          message?: object;
        },
        void
      >({
        path: `/get_result/${threadId}/`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  import = {
    /**
     * No description
     *
     * @tags Excel
     * @name WorkflowCreate
     * @summary Read Excel file into cache.
     * @request POST:/import/workflow/
     * @secure
     */
    workflowCreate: (
      body: {
        /**
         * File path of the Excel file that should be uploaded
         * @example "/path/to/workflow.xlsx"
         */
        file_path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** List of person groups found in the Excel file */
          persongroups?: {
            /** @example "Group A" */
            name?: string;
          }[];
          /** List of persons found in the Excel file */
          persons?: {
            /** @example "John Doe" */
            name?: string;
          }[];
        },
        void
      >({
        path: `/import/workflow/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Excel
     * @name WorkflowLinkageCreate
     * @summary Complete excel import with linked persons and persongroups.
     * @request POST:/import/workflow/linkage/
     * @secure
     */
    workflowLinkageCreate: (
      body: {
        /**
         * File path of the cutting file if segments should be cut
         * @example "/path/to/cutting_file.txt"
         */
        cutting_file_path?: string;
        /**
         * Persongroups connected to an existing id if it exists already
         * @example {"Group A":"existing_group_id_1","Group B":"existing_group_id_2"}
         */
        pglinks?: object;
        /**
         * Persons connected to an existing id if it exists already
         * @example {"Person 1":"existing_person_id_1","Person 2":"existing_person_id_2"}
         */
        plinks?: object;
        /**
         * ID of the video which content of excel file are supposed to be added to
         * @example "video_123"
         */
        video?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string[][], void>({
        path: `/import/workflow/linkage/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Excel
     * @name WorkflowValidateCreate
     * @summary Validate if an excel file is valid to upload.
     * @request POST:/import/workflow/validate/
     * @secure
     */
    workflowValidateCreate: (
      body: {
        /**
         * File path of the Excel file that should be checked
         * @example "/path/to/workflow.xlsx"
         */
        file_path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string[][], void>({
        path: `/import/workflow/validate/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  metadata = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name SynchpointList
     * @summary Synch four videos based on metadata.
     * @request GET:/metadata/synchpoint/
     * @secure
     */
    synchpointList: (
      body: {
        /** The file path of the first video */
        vid1?: string;
        /** The file path of the second video */
        vid2?: string;
        /** The file path of the third video */
        vid3?: string;
        /** The file path of the fourth video */
        vid4?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * Array of synchronization points for the videos
           * @example [0,1200,2400,3600]
           */
          synch_points?: number[];
        },
        void
      >({
        path: `/metadata/synchpoint/`,
        method: "GET",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  pptx2Video = {
    /**
     * No description
     *
     * @tags PPTX2VIDEO
     * @name GenerateCreate
     * @summary Generate a video from an incomplete processing object of one slide PPTX.
     * @request POST:/pptx2video/generate/
     * @secure
     */
    generateCreate: (
      body: {
        /**
         * Whether this is admin-only content
         * @default true
         */
        adminOnly?: boolean;
        /** List of email addresses for notifications */
        emails?: string[];
        /**
         * Language for text processing
         * @default "German"
         */
        language?: string;
        /**
         * AI model to be used
         * @default "gpt-4o"
         */
        model?: string;
        /** ID of the person associated with the task */
        person_id?: string;
        /**
         * The ID of the PowerPoint file
         * @example "pptx_123"
         */
        pptx_id?: string;
        /**
         * Video resolution setting
         * @default "1080p"
         */
        resolution?: "1080p" | "720p" | "480p" | "360p" | "240p";
        /**
         * Speech speed setting
         * @default "normal"
         */
        speed?: "slow" | "normal" | "fast";
        /** Parser results from windows backend */
        uncomplete_elements?: {
          siblings?: {
            media_elements?: {
              /** End time in seconds */
              end_point?: number;
              /** ID of the media element */
              media_id?: string;
              /** Name of the media element */
              object_name?: string;
              /** Type of media (Video, Audio, etc.) */
              object_type?: string;
              /** Start time in seconds */
              start_point?: number;
              /** Duration of trimmed media */
              trim_duration?: number;
            }[];
            /** Thumbnail image ID */
            thumbnail?: string;
          };
        };
        /** Custom name for the generated video */
        videoName?: string;
        /**
         * Voice gender selection
         * @default "male"
         */
        voice?: "male" | "female";
        /** ID of the workflow library */
        workflowlibraryId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "pptx2video video generation started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
          /** @example "thread_123" */
          thread_id?: string;
        },
        void
      >({
        path: `/pptx2video/generate/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  processvideo = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name ProcessvideoCreate
     * @summary Process a video based on a processing object.
     * @request POST:/processvideo/
     * @secure
     */
    processvideoCreate: (
      body: {
        /** Processing object containing command and output information */
        processing?: {
          /**
           * FFMPEG command to be executed
           * @example "ffmpeg -i input.mp4 -vf scale=1920:1080 output.mp4"
           */
          command?: string;
          /**
           * Output path for the processed video
           * @example "/path/to/output.mp4"
           */
          output?: string;
          /** Additional files needed for processing */
          supplements?: {
            /** Supplement file identifier */
            id?: string;
            /** Content of the supplement file */
            text_content?: string;
          }[];
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "Process started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/processvideo/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FFMPEG
     * @name HostedCreate
     * @summary Cut a video based on a processing object.
     * @request POST:/processvideo/hosted/
     * @secure
     */
    hostedCreate: (
      body: {
        /** Processing object that contains information to cut the video */
        processing?: {
          /**
           * FFMPEG command to be executed
           * @example "ffmpeg -i input.mp4 -ss 00:00:10 -t 00:00:30 output.mp4"
           */
          command?: string;
          /** Label for the processed video */
          label?: string;
          /** Additional properties for the media */
          mediaProperties?: {
            /** Name of the video */
            videoName?: string;
            /** ID of the workflow library */
            workflowlibraryId?: string;
          };
          /**
           * Processing mode
           * @example "cut"
           */
          mode?: string;
          /**
           * Server identifier
           * @example "MAIN"
           */
          server?: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "Multiview creation started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/processvideo/hosted/`,
        method: "POST",
        body: body,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FFMPEG
     * @name SegmentsCreate
     * @summary Cut a video based on a processing object.
     * @request POST:/processvideo/segments/
     * @secure
     */
    segmentsCreate: (
      body: {
        /** Processing object that contains information to cut the video */
        processing?: {
          /**
           * FFMPEG command to be executed
           * @example "ffmpeg -i input.mp4 -ss 00:00:10 -t 00:00:30 output.mp4"
           */
          command?: string;
          /** Label for the processed video */
          label?: string;
          /** Additional properties for the media */
          mediaProperties?: {
            /** Name of the video */
            videoName?: string;
            /** ID of the workflow library */
            workflowlibraryId?: string;
          };
          /**
           * Processing mode
           * @example "cut"
           */
          mode?: string;
          /**
           * Server identifier
           * @example "MAIN"
           */
          server?: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "Multiview creation started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/processvideo/segments/`,
        method: "POST",
        body: body,
        secure: true,
        ...params,
      }),
  };
  publish = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name PublishCreate
     * @summary Convert video to HLS format and publish to storage.
     * @request POST:/publish
     * @secure
     */
    publishCreate: (
      body: {
        /**
         * Company identifier (required for non-admin users)
         * @example "company_789"
         */
        company?: string;
        /** Additional configuration parameters for video processing */
        configs?: {
          /**
           * Target bitrate for the HLS stream
           * @example "3000k"
           */
          bitrate?: string;
          /**
           * Target resolution for the HLS stream
           * @example "1080p"
           */
          resolution?: string;
        };
        /**
         * ID of the video to be published
         * @example "video_123"
         */
        video_id?: string;
        /**
         * Name of the video
         * @example "My Video"
         */
        video_name?: string;
        /**
         * ID of the workflow library
         * @example "workflow_456"
         */
        workflowlib_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @format date-time
           * @example "2024-01-01T12:00:00Z"
           */
          date_started?: string;
          /** @example "HLS conversion started with id thread_123" */
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/publish`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  screenshot = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name ScreenshotCreate
     * @summary Create a screenshot of a video at a specified time.
     * @request POST:/screenshot/
     * @secure
     */
    screenshotCreate: (
      body: {
        /** The file path of the video */
        file_path: string;
        /** The time in seconds when the screenshot should be created */
        time: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format date-time */
          date_started?: string;
          message?: string;
          /** @example "Running" */
          status?: string;
        },
        void
      >({
        path: `/screenshot/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  settings = {
    /**
     * No description
     *
     * @tags App
     * @name SettingsList
     * @summary Get all public Settings.
     * @request GET:/settings/
     * @secure
     */
    settingsList: (params: RequestParams = {}) =>
      this.request<
        {
          /**
           * Object containing all public settings
           * @example {"setting1":"value1","setting2":"value2"}
           */
          settings?: Record<string, string>;
        },
        void
      >({
        path: `/settings/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name SettingsCreate
     * @summary Change a Setting.
     * @request POST:/settings/
     * @secure
     */
    settingsCreate: (
      body: {
        /**
         * Name of the setting that should be changed
         * @example "setting_name"
         */
        name?: string;
        /**
         * Value of the setting that should be changed
         * @example "new_value"
         */
        value?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * Status of the update operation
           * @example "Setting updated successfully"
           */
          status?: string;
          updated_setting?: {
            /** @example "setting_name" */
            name?: string;
            /** @example "new_value" */
            value?: string;
          };
        },
        void
      >({
        path: `/settings/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  video = {
    /**
     * No description
     *
     * @tags Backend
     * @name VideoCreate
     * @summary Upload a video or an image to digital ocean via the backend.
     * @request POST:/video/
     * @secure
     */
    videoCreate: (
      body: {
        processing?: {
          /** Description which should be connected to the file */
          description?: string;
          /** File path of the file that should be uploaded */
          file_path?: string;
          /** Label for the uploaded file */
          label?: string;
          /** Additional properties for the media */
          mediaProperties?: {
            /** Name of the video */
            videoName?: string;
            /** ID of the workflow library */
            workflowlibraryId?: string;
          };
          /**
           * Target server identifier
           * @example "MAIN"
           */
          server?: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * Duration of the uploaded video in seconds
           * @example 120
           */
          duration?: number;
          /**
           * ID of the uploaded video
           * @example "video_123"
           */
          last_video_id?: string;
          /**
           * ID of the associated workflow
           * @example "workflow_456"
           */
          workflow_id?: string;
        },
        void
      >({
        path: `/video/`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
