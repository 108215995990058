import React, { useEffect, useState } from "react";
import { Box, Card, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { STEP_PANEL_ACTIONS, StepPanel } from 'medimesh-ui-kit';
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import store from "../../../store/store";


export const SmartVideo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isRecording = useSelector((state: any) => state.steppanel.isRecording);

    const getOrientationAngle = () => window.screen.orientation ? window.screen.orientation.angle : window.orientation;

    const [orientation, setOrientation] = useState(getOrientationAngle());

    useEffect(() => {
        const handleOrientationChange = () => {
            const newOrientation = getOrientationAngle();
            if (newOrientation !== orientation && isRecording) {
                dispatch({
                    type: STEP_PANEL_ACTIONS.SET_RECORDING,
                    payload: false
                });

                Swal.fire({
                    title: "Recording stopped",
                    text: "Recording not possible on rotate",
                    icon: 'warning',
                    confirmButtonColor: '#4baaac',
                    confirmButtonText: 'Ok'
                });
            }
            setOrientation(newOrientation);
        };

        window.screen.orientation?.addEventListener("change", handleOrientationChange);
        window.addEventListener("resize", handleOrientationChange);

        return () => {
            window.screen.orientation?.removeEventListener("change", handleOrientationChange);
            window.removeEventListener("resize", handleOrientationChange);
        };
    }, [orientation, isRecording, dispatch]);

    return (
        <Box alignItems={"center"} width={"100%"}>
            <Text className="page-heading">{t('smartVideo')}</Text>
            <Card
                width="inherit"
                paddingTop={{ base: "0", md: "20px" }}
                paddingBottom={{ base: "0", md: "20px" }}
                paddingLeft={{ base: "0", md: "15px" }}
                paddingRight={{ base: "0", md: "15px" }}
                boxShadow={"none"}
            >
                <StepPanel store={store} />
            </Card>
        </Box>
    );
};
