/**
 *
 * @file PatientLogin.js
 * @created_date Thurseday, Jan 19, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The PatientLogin component is responsible for patient video login feature in a user interface.
 * <br />Here's what it does: - {@tutorial PatientLogin}
 * @module PatientLogin
 **/

import React from "react";
import backgroundImage from "../../assets/img/normal_background_img_1.jpg";
import { Modal } from "flowbite-react";
import PatientLoginForm from "./PatientLoginForm";
import { withTranslation } from "react-i18next";

class PatientLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPopupVisible: true,
    };
    this.mainPageStyles = {
      backgroundImage: `url(${backgroundImage})`,
    };
  }

  handleOnCloseReset = () => {
    this.setState({ resetPopupVisible: false });
  };

  render() {
    return (
      <div className="container-reset-page" style={this.mainPageStyles}>
        <div className="landing_header float-right mr-1">
          <Modal
            show={this.state.resetPopupVisible}
            className="register_main_modal"
            onClick={() => this.setState({ resetPopupVisible: false })}
            onClose={() => {
              this.setState({ resetPopupVisible: false });
            }}
          >
            <Modal.Body
              className="_popup_body"
              style={{ overflow: "inherit", flex: "none" }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex flex-col items-center justify-center mx-3 my-3">
                <div className="w-full max-w-6xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                  <span
                    className="button_modal_close"
                    onClick={this.handleOnCloseReset}
                  ></span>
                  <h4
                    className="text-xl font-medium text-gray-900 dark:text-white text-center"
                    id="label_login_header"
                  >
                    {this.props.t('login_register_page.patient_login')}
                  </h4>
                  <h5
                    className="text-xl font-medium text-gray-900 dark:text-white text-center"
                    id="label_sign-up_description"
                  >
                    {this.props.t('login_register_page.welcome_to')} medi<span className="boldColored">MESH</span>{" "}
                    <span className="boldOnly">{this.props.t('login_register_page.world')}.</span>
                  </h5>

                  <PatientLoginForm />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PatientLogin);
