/**
 *
 * @file TimelineGlobal.js
 * @created_date Wednesday, August 01, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component is designed to display and control the global timeline with a zooming feature. It coordinates the rendering of a range slider that allows users to adjust the zoom level and see the current progress indicator of a video's timeline.
 *<br/>Key functionalities - {@tutorial TimelineGlobal}
 * @module TimelineGlobal
 *
 **/
import React from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { MiscFunctions } from 'helper-functions-package';
import { Box, Text } from "@chakra-ui/react";
import store from "../../store/store";

class TimelineGlobal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: props.player.current.cache_.currentTime,
      duration: props.player.current.cache_.duration,
      zoomLevel: this.props.zoomLevel,
      rangeValue: this.props.rangeSliderValue,
      ProgressBarPosition: store.getState().ProgressBarPosition,
      progress: store.getState().ProgressBarPosition.pos * 100,
      isDragging: false,
      MouseDownZoomingBar: false,
      MouseDownIndicator: false,
      TouchStartZoomingBar: false,
      TouchStartIndicator: false,
      pos: null,
      checkIfHitHour:
        props.player.current.cache_.duration / 60 >= 60 ? true : false,
      showTooltip: false,
    };
    this.timelineIndicatorRef = React.createRef();

    this.unsubscribe = null;
  }

  handleZoomChange = (event) => {
    const zoom = parseFloat(event[1]) - parseFloat(event[0]);
    const newZoomLevel = parseFloat(zoom);
    this.props.handlingZoomScroll(newZoomLevel, event);
  };

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({
        ProgressBarPosition: store.getState().ProgressBarPosition,
        progress: store.getState().ProgressBarPosition.pos * 100,
      });
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentTime !== prevProps.player.current.cache_.currentTime) {
      this.setState({
        currentTime: prevProps.player.current.cache_.currentTime,
        duration: prevProps.player.current.cache_.duration,
        ProgressBarPosition: store.getState().ProgressBarPosition,
        progress: store.getState().ProgressBarPosition.pos * 100,
      });
    }
    if (prevProps.rangeSliderValue !== this.props.rangeSliderValue) {
      this.setState({ rangeValue: this.props.rangeSliderValue });
    }
    if (prevState.checkIfHitHour !== this.state.checkIfHitHour) {
      this.setState({ checkIfHitHour: this.state.checkIfHitHour });
    }
  }

  handleSeek = (e) => {
    const video = document.querySelector(".medimesh-video-player");
    const seekTime = (e.target.value / 100) * video.duration;
    video.currentTime = seekTime;
    this.setState({ progress: e.target.value });
  };

  handleMouseUp = () => {
    const video = document.querySelector(".medimesh-video-player");
    video.currentTime = (this.state.progress / 100) * video.duration;
  };


  render() {
    const splitedByDot = MiscFunctions.secondsMinuteFormat(
      (this.state.progress / 100) * this.state.duration
    ).split(".")[0];
    const splittedByColon = splitedByDot.split(":");
    const indicatorCurrentTime =
      this.state.checkIfHitHour === true
        ? splitedByDot
        : `${splittedByColon[1]}:${splittedByColon[2]}`;
    const checkIfNan = isNaN(splittedByColon[1]);
    return (
      <div className="global-time-line-block">
        <Box className="swimlane-time-line" marginLeft={{ base: "5px", md: "18px" }} onMouseEnter={() => this.setState({ showTooltip: true })}
          onMouseLeave={() => this.setState({ showTooltip: false })}>
          <input
            type="range"
            value={this.state.progress}
            // defaultValue={this.state.progress}
            min={0}
            max={100}
            step="0.01"
            onPointerDown={(e) => {
              // During MouseDown the position of the cursor is identified.
              // Calculate the initial position here
              let rect = e.target.getBoundingClientRect();
              let pos = (e.clientX - rect.x) / rect.width;
              // Determine if the cursor was on the indicator or the zoomingbar.
              if (
                this.state.progress - 1 < pos * 100 &&
                this.state.progress + 1 > pos * 100
              ) {
                this.setState({
                  MouseDownIndicator: true,
                  DraggingPos: pos,
                  MouseDownTime: Date.now(),
                });
                console.log("Indicator");
              } else if (
                this.state.rangeValue[0] < pos &&
                this.state.rangeValue[1] > pos
              ) {
                this.setState({
                  MouseDownZoomingBar: true,
                  DraggingPos: pos,
                  MouseDownTime: Date.now(),
                });
              } else {
                this.setState({ MouseDownTime: Date.now() });
              }
            }}
            onPointerUp={(e) => {
              if (
                !isNaN(
                  document.querySelector(".medimesh-video-player").duration
                )
              ) {
                // Get actual time
                let actTime = Date.now();
                // Check if less than 500ms are passed. If yes the event is handled as a Click-Event
                // if not the corrresponding states for the dragging are set
                if (actTime - this.state.MouseDownTime <= 500) {
                  let rect = e.target.getBoundingClientRect();
                  let pos = (e.clientX - rect.x) / rect.width;
                  const video = document.querySelector(
                    ".medimesh-video-player"
                  );
                  let progress = pos * 100;
                  const seekTime = (progress / 100) * video.duration;
                  video.currentTime = seekTime;
                  this.setState({
                    progress: progress,
                  });
                }
                if (this.state.MouseDownIndicator) {
                  this.setState({ MouseDownIndicator: false });
                }
                if (this.state.MouseDownZoomingBar) {
                  this.setState({ MouseDownZoomingBar: false });
                }
              }
            }}
            onPointerMove={(e) => {
              let rect = e.target.getBoundingClientRect();
              let pos = (e.clientX - rect.x) / rect.width;

              if (this.state.MouseDownIndicator) {
                const video = document.querySelector(".medimesh-video-player");
                let progress =
                  this.state.progress - (this.state.DraggingPos - pos) * 100;
                if (progress >= 0 && progress <= 100) {
                  const seekTime = (progress / 100) * video.duration;
                  video.currentTime = seekTime;
                  this.setState({
                    progress: progress,
                    DraggingPos: pos,
                  });
                } else if (progress > 100) {
                  this.setState({
                    progress: 100,
                  });
                } else if (progress < 0) {
                  this.setState({
                    progress: 0,
                  });
                }
              } else if (this.state.MouseDownZoomingBar) {
                let range = [];
                range[0] =
                  this.state.rangeValue[0] - (this.state.DraggingPos - pos);
                range[1] =
                  this.state.rangeValue[1] - (this.state.DraggingPos - pos);
                if (range[0] >= 0 && range[1] <= 1) {
                  this.setState({
                    rangeValue: range,
                    DraggingPos: pos,
                  });
                  this.props.handlingZoomScroll(range[1] - range[0], range);
                } else if (range[1] > 1) {
                  range[0] = range[0] - (range[1] - 1);
                  range[1] = 1;
                  this.setState({
                    rangeValue: range,
                  });
                } else if (range[0] < 0) {
                  range[1] = range[1] - range[0];
                  range[0] = 0;
                  this.setState({
                    rangeValue: range,
                  });
                }
              }
            }}
            onTouchStart={(e) => {
              let rect = e.target.getBoundingClientRect();
              this.setState({
                pos: (e.touches[0].clientX - rect.x) / rect.width,
              });
              if (
                this.state.progress - 1 < this.state.pos * 100 &&
                this.state.progress + 1 > this.state.pos * 100
              ) {
                this.setState({
                  TouchStartIndicator: true,
                  DraggingPos: this.state.pos,
                  MouseDownTime: Date.now(),
                });
              } else {
                this.setState({ MouseDownTime: Date.now() });
              }
            }}
            onTouchEnd={() => {
              if (
                !isNaN(
                  document.querySelector(".medimesh-video-player").duration
                )
              ) {
                let actTime = Date.now();
                if (actTime - this.state.MouseDownTime <= 500) {
                  const video = document.querySelector(
                    ".medimesh-video-player"
                  );
                  let progress = this.state.pos * 100;
                  const seekTime = (progress / 100) * video.duration;
                  video.currentTime = seekTime;
                  this.setState({
                    progress: progress,
                  });
                }
                if (this.state.TouchStartIndicator) {
                  this.setState({ TouchStartIndicator: false });
                }
                if (this.state.TouchStartZoomingBar) {
                  this.setState({ TouchStartZoomingBar: false });
                }
              }
            }}
            className="swimlane-time-line-input"
            readOnly
          />
          {!checkIfNan && (
            <Text
              className="timelane-indicator-label-tooltip"
              position="absolute"
              display={{ base: "none", md: this.state.showTooltip ? "block" : "none" }}
              // Hides on mobile (base), shows on medium (md) screens and larger
              style={
                !this.state.checkIfHitHour
                  ? {
                    left: `${this.state.progress - this.state.progress / 100}%`,
                    translate: "-17px",
                  }
                  : {
                    left: `${this.state.progress - this.state.progress / 100}%`,
                    translate: "-30px",
                  }
              }
            >
              {indicatorCurrentTime}
            </Text>
          )}
          <RangeSlider
            id="zoom-in-out-slide"
            onInput={(e) => {
              this.handleZoomChange(e);
              this.setState({ rangeValue: e });
            }}
            step={0.01}
            min={0}
            max={1}
            defaultValue={[0, 1]}
            value={this.state.rangeValue}
            rangeSlideDisabled={false}
          />
        </Box>
      </div>
    );
  }
}
export default TimelineGlobal;
