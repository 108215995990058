/**
 *
 * @file PatientLoginForm.js
 * @created_date Thurseday, Jan 19, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @description This component is developed for login into the app.
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The PatientLoginForm component is responsible for rendering a form that allows users to get into the patient video dashboard.
 * <br />Here's what it does: - {@tutorial PatientLoginForm}
 * @module PatientLoginForm
 **/

import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer } from "react-toastify";
import { Navigate } from "react-router-dom";
import { setAuthAccessObject } from "../../utils/tokenStorage";
import ToastMessages from "../../helpers/ToastMessages";
import { withTranslation } from "react-i18next";
import { Api, baseApiParams } from "my-api-client-package";
class PatientLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginVerfied: false,
      Username: "",
      userPassword: "",
      captchaValue: "",
      spinner: false,
      redirect: false,
      api: new Api({
        baseUrl: process.env.REACT_APP_API_URL
      })
    };
    this.reCaptchaKey = process.env.REACT_APP_SITE_KEY;
    this.inputClasses =
      "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white input-style";
    this.recaptchaRef = React.createRef();
  }

  handleResetSubmit = async (e) => {
    this.setState({ spinner: true });
    e.preventDefault();

    const res = this.state.api.patient.login(
      {username: this.state.Username,
        userPassword: this.state.userPassword},
      baseApiParams());
      let ressource = res.data;
      ressource
      .then(async (data) => {
        if (data.status === 200 && data.token !== "Forbidden") {
          setAuthAccessObject(
            JSON.stringify({
              loginStatus: true,
              token: data.token,
              recaptcha: data.recaptcha,
            })
          );

          this.state.api.patient.getPatientvideo(baseApiParams())
          .then((res) => {
              console.log(res);
              localStorage.setItem(
                "patient_video_item",
                JSON.stringify({
                  data: res.data,
                  status: true,
                })
              );
              this.setState({ redirect: true });
            })
            .catch((err) => console.log(err));
          this.setState({ spinner: false });
        } else {
          ToastMessages.erorrMessage(this.props.t('messages.error'));

          this.setState({ spinner: false });
        }
      })
      .catch((err) => {
        ToastMessages.erorrMessage(this.props.t('messages.error'));
        this.setState({ spinner: false });
      });
  };

  /**
   * @description This function handles the change in the reCAPTCHA value. It receives the value as a parameter and updates the captchaValue state. It also sets the isLoginVerified state to true to enable the submit button.
   *
   * @function recaptchaReset
   * @return {void}
   * */
  recaptchaReset = (value) => {
    this.setState({ isLoginVerfied: true, captchaValue: value });
  };

  componentDidUpdate(prevProps, prevStats) {
    // if (prevProps.props !== this.props) {
    //   this.recaptchaRef.current?.reset();
    // }
  }

  render() {
    return (
      <>
        <ToastContainer />
        <form
          className="space-y-6 reset-form"
          onSubmit={this.handleResetSubmit}
          method="post"
        >
          <div className="input_group">
            <span className={" normal_icon_password input_icon"}></span>
            <div className="input_group_item">
              <label
                htmlFor="password"
                className={
                  " block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
                }
              >
                {this.props.t('general.username')}
              </label>
              <input
                type="text"
                value={this.state.Username}
                onChange={(e) => this.setState({ Username: e.target.value })}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white input-style"
                required
              />
            </div>
          </div>

          <div className="input_group">
            <span className={" normal_icon_repeat_password input_icon"}></span>
            <div className="input_group_item">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
              >
                {this.props.t('general.password')}
              </label>
              <input
                type="text"
                value={this.state.userPassword}
                onChange={(e) =>
                  this.setState({ userPassword: e.target.value })
                }
                className={this.inputClasses}
                required
              />
            </div>
          </div>

          <ReCAPTCHA
            ref={this.recaptchaRef}
            sitekey={this.reCaptchaKey}
            hl={this.props.i18n.language}
            onChange={this.recaptchaReset}
            id="recaptcha-login-container"
            className=""
            onExpired={() => {
              this.recaptchaRef.current.reset();
            }}
            style={{
              display: "table",
              margin: "0 auto",
              paddingTop: "16px",
            }}
          />

          <div className="flex flex-col items-center">
            <button
              type="submit"
              className="w-full text-white bg-hightlight-700 hover:bg-hightlight-800 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700 dark:focus:ring-hightlight-800 button_sign_in_up"
              disabled={!this.state.isLoginVerfied}
            >
              {this.state.spinner ? (
                <svg
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              ) : (
                ""
              )}
              Login
            </button>

            {this.state.redirect && <Navigate to="/dashboard" replace={true} />}
          </div>
        </form>
      </>
    );
  }
}

export default withTranslation()(PatientLoginForm);
