import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LanguageState {
    userLanguage: string;
    languageISO3: string;
}

const initialState: LanguageState = {
    userLanguage: "",
    languageISO3: "",
}

const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setUserSelectedLanguage: (state, action: PayloadAction<string>) => {
            state.userLanguage = action.payload;
            if (action.payload === "german") {
                state.languageISO3 = "GER";
            } else if (action.payload === "english") {
                state.languageISO3 = "ENG";
            }
        },
        setLanguageISO3: (state, action: PayloadAction<string>) => {
            console.log(action)
            state.languageISO3 = action.payload;
        },
    }
});

export const {
    setUserSelectedLanguage,
    setLanguageISO3
} = languageSlice.actions;
export default languageSlice.reducer;