import React from "react"
import { Card, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
export const AllMedia = () => {
    const { t } = useTranslation();

    return (
        <>
            <Text className="page-heading">Alle Medien</Text>
            <Card width="inherit" padding={"20px 15px 20px 15px"} boxShadow={"none"}>
                <Text className="help-small-text" padding={"0px 0px 15px 0px"}>Alle Medien Page</Text>
            </Card>
        </>
    )
}