import { Menu, MenuButton, MenuItem, MenuList, HStack, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { setUserSelectedLanguage } from "../../../store/languageSlice";
import store from '../../../store/store';
import { connect } from 'react-redux';
import MiscFunctionsLocal from "../../../helpers/MiscFunctions";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({
    userSelectedLang: state.language.userLanguage,
    languageISO3: state.language.languageISO3,
})
const languages = [
    { iso3: "ENG", full: "English", tag: "english" },
    { iso3: "GER", full: "German", tag: "german" }
]

const LanguageSelector = (props: any) => {
    const {i18n} = useTranslation();

    return (
        <Menu>
            <MenuButton
                px={4}
                py={2}
                transition='all 0.2s'
                borderRadius='md'
                borderWidth='1px'
                _hover={{ bg: 'gray.400', borderRadius: "8px" }}
            >
                {props.languageISO3} 
                <ChevronDownIcon />
            </MenuButton>
            <MenuList minW={"fit-content"} padding={"10px 4px 5px 4px"} overflowY={"auto"} maxH={"95px"}>
                {languages.map(e =>
                    <MenuItem borderRadius={"8px"} 
                    onClick={() => {
                        MiscFunctionsLocal.changeLanguage(e.tag, props.userSelectedLang);
                        store.dispatch(setUserSelectedLanguage(e.tag))
                        if(e.tag === "english"){
                            i18n.changeLanguage("en")
                        }else if(e.tag === "german"){
                            i18n.changeLanguage("de")
                        }
                        }}>
                        <HStack spacing={2} >
                            <Text textColor={e.tag === props.userSelectedLang ? "#4BAAAC" : ""}>{e.full}</Text>
                        </HStack>
                    </MenuItem>
                )}
            </MenuList>
        </Menu>
    )
}
export default connect(mapStateToProps)(LanguageSelector)