/**
 *
 * @file TimelineLocal.js
 * @created_date Wednesday, August 01, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component serves as a local timeline inside the scrollable workflow element with interactive features. It provides a visual representation of time points along with the ability to click on those points to navigate a video player's timeline.
 *<br/>Key functionalities - {@tutorial TimelineLocal}
 * @module TimelineLocal
 *
 **/

import React from "react";
import { MiscFunctions } from 'helper-functions-package';
import { Text } from "@chakra-ui/react";
import store from "../../store/store";

class TimelineLocal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ref = React.createRef();
    this.isMobile = store.getState().isMobile;
  }

  componentDidMount() {
    this.ref.current.scrollLeft = "100px";
  }
  render() {
    let indicatorCurrentTime;
    if (!isNaN(this.props.duration)) {
      const splitedByDot = MiscFunctions.secondsMinuteFormat(
        this.props.duration
      ).split(".")[0];
      const splittedByColon = splitedByDot.split(":");
      indicatorCurrentTime =
        this.state.checkIfHitHour === true
          ? splitedByDot
          : `${splittedByColon[1]}:${splittedByColon[2]}`;
    }


    return (
      <div
        className="timeline-local"
        ref={this.ref}
        onClick={(e) => {
          let timelineWidth = e.target.clientWidth;
          let myPlayer = document.querySelector(".medimesh-video-player");

          myPlayer.currentTime =
            (e.nativeEvent.offsetX / timelineWidth) * myPlayer.duration;
        }}
      >
        <span className="timeline-start-time">00:00</span>
        <div className="move_to_10">
          {!this.isMobile && <Text className="process-time-label" top={{ base: "-23px", md: "-21px" }}>10</Text>}
        </div>
        <div className="move_to_20">
          {!this.isMobile && <Text className="process-time-label" top={{ base: "-23px", md: "-21px" }}>20</Text>}
        </div>
        <div className="move_to_30">
          {!this.isMobile && <Text className="process-time-label" top={{ base: "-23px", md: "-21px" }}>30</Text>}
        </div>
        <div className="move_to_40">
          {!this.isMobile && <Text className="process-time-label" top={{ base: "-23px", md: "-21px" }}>40</Text>}
        </div>
        <div className="move_to_50">
          {!this.isMobile && <Text className="process-time-label" top={{ base: "-23px", md: "-21px" }}>50</Text>}
        </div>
        <div className="move_to_60">
          {!this.isMobile && <Text className="process-time-label" top={{ base: "-23px", md: "-21px" }}>60</Text>}
        </div>
        <div className="move_to_70">
          {!this.isMobile && <Text className="process-time-label" top={{ base: "-23px", md: "-21px" }}>70</Text>}
        </div>
        <div className="move_to_80">
          {!this.isMobile && <Text className="process-time-label" top={{ base: "-23px", md: "-21px" }}>80</Text>}
        </div>
        <div className="move_to_90">
          {!this.isMobile && <Text className="process-time-label" top={{ base: "-23px", md: "-21px" }}>90</Text>}
        </div>
        <span className="timeline-end-time">{indicatorCurrentTime}</span>
      </div>
    );
  }
}
export default TimelineLocal;
