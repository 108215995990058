import Segments from '../services/SegmentsServices';
import Segment from '../services/SegmentServices';

export const selectTasksSegments = (state) => {
  let tasksSegments = new Segments([]);
  let segments = state.selectedSegment.editTasksSegments?.length > 0 
    ? state.selectedSegment.editTasksSegments 
    : state.selectedSegment.tasksSegments;
  if (segments.length > 0) {
    segments.forEach((taskSegment) => {
      let dummySegment = new Segment();
      dummySegment.assignData = taskSegment;
      tasksSegments.addSegment = dummySegment;
    });
  }
  return tasksSegments;
};
