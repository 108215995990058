/**
 * @file VideoJS.js
 * @created_date Thursday, November 30, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This code defines a React functional component named VideoJS. It utilizes the Video.js library to create a video player in the applications. The component takes options as configuration for the Video.js player and provides an onReady callback function when the player is ready. It manages the initialization and disposal of the Video.js player using React hooks.
 *<br/>Breakdown of the code - {@tutorial VideoJS}
 * @module VideoJS
 *
 **/

import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;
  const skipTime = (player, skipBy) => {
    player.currentTime(player.currentTime() + skipBy);
  }
  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("medimesh-video-player-block");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
      }));

      const getVjsPlayer = document.querySelector(`#${player.id_}_html5_api`);
      getVjsPlayer.classList.add("medimesh-video-player");
      // Detect video metadata to adjust aspect ratio
      player.on("loadedmetadata", function () {
        const video = player.tech().el();
        if (video.videoHeight > video.videoWidth) {
          // Portrait video detected - enforce 16:9 with black padding
          player.el().style.paddingTop = "56.25%";
          video.style.objectFit = "contain";
          video.style.backgroundColor = "black";
        } else {
          // Landscape - let Video.js handle it normally
          player.el().style.paddingTop = "";
          video.style.objectFit = "";
        }
      });

    }
  }, [options, videoRef, onReady]);
  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    var Button = videojs.getComponent('Button');

    var revertTime = new Button(player);
    revertTime.controlText('RevertTime');
    revertTime.addClass('vjs-revert-time');
    player.getChild('ControlBar').addChild(revertTime, {
      children: {
        buttonChildExample: {
          buttonChildOption: true
        }
      }
    });
    revertTime.on('click', () => skipTime(player, -30));

    var forwardTime = new Button(player);
    forwardTime.controlText('ForwardTime');
    forwardTime.addClass('vjs-forward-time');
    player.getChild('ControlBar').addChild(forwardTime, {
      children: {
        buttonChildExample: {
          buttonChildOption: true
        }
      }
    });
    forwardTime.on('click', () => skipTime(player, 30));
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
