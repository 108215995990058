import React, { useState, useEffect } from "react";
import VideoPlayer from "./Player/VideoPlayer";
import Workflow from "../services/WorkflowService";
import categoryAPI from "../api/categoryAPI";
import Category from "../services/CategoryServices";
import { MiscFunctions } from 'helper-functions-package';
import { Spinner, Stack } from "@chakra-ui/react";
import { Api, baseApiParams } from "my-api-client-package";
import { removeAuthAccessObject, setAuthAccessObject } from "../utils/tokenStorage";
import { useSelector } from "react-redux";
import store from "../store/store";
import { IdleRefreshComponent } from 'auth-components-package';
import { useNavigate } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";
import MiscFunctionsLocal from "../helpers/MiscFunctions";
import { setShowInfoCard } from "../store/taskPositionSlice";

const Dashboard = () => {
  const [workflow, setWorkflow] = useState(null);
  const [patientVideo, setPatientVideo] = useState(false);
  const [PlayerSidebar, setPlayerSidebar] = useState(true);
  const [addComment, setAddComment] = useState(true);
  const [introPopupShown, setIntroPopupShown] = useState(false);
  const [redirectBy, setRedirectBy] = useState(false);
  const [redirectedToken, setRedirectedToken] = useState(null);

  const selectedWorkflowItem = JSON.parse(localStorage.getItem("selected_workflow_item"));
  const patientVideoItem = JSON.parse(localStorage.getItem("patient_video_item"));
  const patientVideoStatus = !MiscFunctions.isNull(patientVideoItem) ? patientVideoItem.status : false;
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = store.dispatch
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL || '';
  const api = new Api({
    baseUrl: process.env.REACT_APP_API_URL
  });
  useEffect(() => {
    if (!workflow) {

      const loadData = async () => {
        api.user.getCurrentUser(baseApiParams()).then(
          (user) => {
            store.dispatch({ type: 'user/setUser', payload: user.data })}
        )
        try {
          if (!patientVideoStatus) {
            const data = {
              id: selectedWorkflowItem?.id || MiscFunctionsLocal.logout(),
              label: selectedWorkflowItem?.label,
              categories: [],
              selectedVideo: selectedWorkflowItem?.selectedVideo?.id ? selectedWorkflowItem.selectedVideo : null,
              description: selectedWorkflowItem?.description,
            };

            const res = await categoryAPI.getCategories();
            let Categories = res.map((elem) => new Category(elem));
            data.categories.push(...Categories);
            const workflowInstance = new Workflow(data);
            setWorkflow(workflowInstance);
          } else {
            const data = {
              label: patientVideoItem.data.label,
              id: patientVideoItem.data.id,
              selectedVideo: patientVideoItem.data,
            };

            const workflowInstance = new Workflow(data);
            setWorkflow(workflowInstance);
            setPatientVideo(true);
            setPlayerSidebar(false);
            setAddComment(false);
          }
        } catch (error) {
          console.error("Error loading data:", error);
        }
      }

      const queryParams = new URLSearchParams(window.location.search);
      const redirectedToken = queryParams.get('_token');
      const workflowLibID = queryParams.get('_workflow_lib_id');
      const workflowID = queryParams.get('_workflow_id');
      const redirectBy = queryParams.get('_redirectBy')

      setRedirectedToken(redirectedToken)
      const loginEmail = user ? user.email : null;
      const SMALL_DELAY_MS = 100;

      const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
      };

      const handleWorkflowReload = (workflowData, storageKey) => {
        const hasReloaded = sessionStorage.getItem("hasReloaded");
        if (!hasReloaded) {
          saveToLocalStorage(storageKey, workflowData);
          sessionStorage.setItem("hasReloaded", "true");
          setTimeout(() => window.location.reload(), SMALL_DELAY_MS);
        } else {
          return workflowData;
        }
      };

      const loadWorkflow = async (workflowID) => {
        const workflowResponse = (await api.workflow.getWorkflow(workflowID, baseApiParams())).data;
        if (!MiscFunctions.isUndefined(workflowResponse)) {
          console.log("workflowResponse", workflowResponse)
          const workflowData = {
            ...workflowResponse,
            selectedVideo: workflowResponse.videos[0],
            redirectBy: redirectBy,
          };

          const dataToProcess = handleWorkflowReload(workflowData, "selected_workflow_item");
          if (dataToProcess) {
            const workflow = new Workflow({
              id: { id: workflowID, tag: "workflow" },
              label: workflowResponse.label,
              categories: [],
              selectedVideo: workflowResponse.videos[0],
              description: workflowResponse.description,
              introPopupShown: null,
            });

            setWorkflow(workflow);
            setAddComment(false);
          }
        }
      };

      const loadWorkflowLibrary = async (workflowLibID) => {
        const workflowLibraryResponse = (await api.workflowlibrary.getWorkflowlibraryWorkflows(workflowLibID, baseApiParams())).data;
        if (!MiscFunctions.isUndefined(workflowLibraryResponse)) {
          const workflowLibData = {
            ...workflowLibraryResponse[0],
            selectedVideo: workflowLibraryResponse[0].videos[0],
            redirectBy: redirectBy,
          };

          const dataToProcess = handleWorkflowReload(workflowLibData, "selected_workflow_item");
          if (dataToProcess) {
            const workflow = new Workflow({
              id: { id: workflowLibID, tag: "workflow" },
              label: workflowLibraryResponse[0].label,
              categories: [],
              selectedVideo: workflowLibraryResponse[0].videos[0],
              description: workflowLibraryResponse[0].description,
              introPopupShown: null,
            });
            setWorkflow(workflow);
            setAddComment(false);
          }
        }
      };

      const loadIfRedirect = async () => {
       
          let userResponse=null
          try {
            userResponse = (await api.user.getCurrentUser(baseApiParams()))
            store.dispatch({ type: 'user/setUser', payload: userResponse.data })
          } catch (error) {
            console.error("Error in loadIfRedirect:", error);
          }
        
          console.log("user", redirectedToken)
          if (redirectedToken) {
            // try {
              console.log("user", user)
              const userID = loginEmail ? user.id.id : null;
              dispatch({ type: 'token/setToken', payload: redirectedToken });
              setAuthAccessObject(
                JSON.stringify({
                  loginStatus: true,
                  token: redirectedToken,
                  recaptcha: "",
                  ID: userID,
                  redirectBy: redirectBy,
                })
              );

              setRedirectBy(true);

              setTimeout(async () => {
                if (workflowLibID) {
                  console.log("workflowLibID", workflowLibID)
                  await loadWorkflowLibrary(workflowLibID);
                } else if (workflowID) {
                  console.log("workflowID", workflowID)
                  await loadWorkflow(workflowID);
                }
              }, SMALL_DELAY_MS);
            // } catch (error) {
            //   console.error("Error in loadIfRedirect:", error);
            // }
          }
        
      };

      if (redirectedToken) {
        loadIfRedirect();
      } else {
        loadData();
      }

      // Prevent back navigation
      window.history.pushState(null, document.title, window.location.href);
      const handlePopState = () => {
        window.history.pushState(null, document.title, window.location.href);
      };
      window.addEventListener("popstate", handlePopState);

      // Handle storage event
      const handleStorage = () => {
        setIntroPopupShown(localStorage.getItem("hasSeenIntroductionPopup"));
      };
      window.addEventListener("storage", handleStorage);

      return () => {
        window.removeEventListener("popstate", handlePopState);
        window.removeEventListener("storage", handleStorage);
      };
    }
  }, [patientVideoStatus, selectedWorkflowItem, patientVideoItem, workflow, user]);

  const handleScroll = () => {
    if (store.getState().taskPosition.showInfoCard) {
      store.dispatch(setShowInfoCard(false));
    }
  };

  return (
    <div className="dashboard-scrollable" onScroll={handleScroll}>
      <div className="h-screen" style={{ background: "#393d41" }}>
      {!MiscFunctions.isNull(user) && !MiscFunctions.isUndefined(user.role) && <IdleRefreshComponent token={'token/setToken'} dispatch={dispatch} navigate={navigate} t={t} apiURL={API_URL} idleTimeout={parseInt(process.env.REACT_APP_IDLE_TIMER)} refrestTimeout={parseInt(process.env.REACT_APP_GET_REFRESH_TOKEN_EVERY)} />}
        <div className="all_sections_block"> 
          {workflow ? (
            <VideoPlayer
              workflow={workflow}
              patientVideo={patientVideo}
              PlayerSidebar={PlayerSidebar}
              addComment={addComment}
              redirectBy={redirectBy}
            />
          ) : (
            <Stack alignItems="center" justifyContent="center">
              <Spinner color="#4ba9aa" size="xl" />
            </Stack>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;