import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PDFViewer from '../Misc/PDFViewer';
import VideoPlayer from '../Misc/VideoPlayer';
import { MiscFunctions } from 'helper-functions-package';
import { FiFile, FiFilm, FiHeadphones, FiDownload } from "react-icons/fi";
import store from '../../store/store';

class CommentFileFullSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullSliderPopup: true,
            currentSlideIndex: this.props.fileIndex,
            pause: false,
            medias: store.getState().interactions.clickedReply !== null ?
                store.getState().interactions.clickedReply.medias :
                store.getState().interactions.comment !== undefined && store.getState().interactions.comment.medias,
            clickedComment: store.getState().interactions.comment,
            commentSettingAutoPlay: false,
            clickPauseAndPlay: false,
        };
    }

    setVideoPause = (bool) => {
        this.setState({ pause: bool })
    }

    handleClose = () => {
        this.setState({ fullSliderPopup: false });
        this.props.setFullSlider(false)
    };

    handleNext = () => {
        const { medias } = this.state;
        let newIndex = this.state.currentSlideIndex;
        newIndex = (newIndex + 1) % medias.length;
        this.setState({ currentSlideIndex: newIndex });
        this.props.setFileIndex(newIndex);
        this.setVideoPause(true);
        this.setState({ clickPauseAndPlay: false })
    };

    handlePrev = () => {
        const { medias } = this.state;
        let newIndex = this.state.currentSlideIndex;
        newIndex = (newIndex - 1 + medias.length) % medias.length;
        this.setState({ currentSlideIndex: newIndex });
        this.props.setFileIndex(newIndex);
        this.setVideoPause(true);
        this.setState({ clickPauseAndPlay: false })
    };

    handleThumbnailClick = (index) => {
        const { medias } = this.state;
        this.setState({ currentSlideIndex: index });
        this.props.setFileIndex(index);
        this.setVideoPause(true);
        this.setState({ clickPauseAndPlay: false })
    };

    updateMedias() {
        const { clickedComment } = store.getState().interactions.comment;

        if (!MiscFunctions.isNull(clickedComment) && !MiscFunctions.isUndefined(clickedComment)) {
            const data = clickedComment.medias.filter(
                (media) =>
                    media.description &&
                    !media.description.trim().toLowerCase().includes("deleted media".toLowerCase())
            );
            this.setState({ medias: data });
        }
    }

    componentDidUpdate(prevProps) {
        if (store.getState().interactions.comment) {
            this.updateMedias();
        }
    }

    componentDidMount() {
        this.updateMedias();
        if (!MiscFunctions.isNull(store.getState().interactions.comment.commentSettings)) {
            this.setState({ commentSettingAutoPlay: store.getState().interactions.comment.commentSettings.autoplay })
        }
    }

    handleClickPauseAndPlay = (status) => {
        this.setState({ clickPauseAndPlay: status })
    }

    renderSlide = (slide) => {
        switch (slide.type) {
            case 'picture':
                return <img src={slide.url} alt={slide.description} className="comment-full-slider-block" />;
            case 'video':
                // return <video src={slide.url} controls className="w-full h-auto" />;
                return <VideoPlayer
                    url={slide.url}
                    showProgressBar={true}
                    setVideoPause={this.setVideoPause}
                    pause={this.state.pause}
                    commentSettingAutoPlay={this.state.commentSettingAutoPlay}
                    slide={slide}
                    index={this.state.currentSlideIndex}
                    fileIndex={this.props.fileIndex}
                    clickPauseAndPlay={this.state.clickPauseAndPlay}
                    handleClickPauseAndPlay={this.handleClickPauseAndPlay}
                />
            case 'document':
                // return <PDFViewer fileUrl={slide.url} />;
                return <PDFViewer fileUrl={slide.url} />;
            case 'audio':
                return <VideoPlayer
                    url={slide.url}
                    showProgressBar={true}
                    setVideoPause={this.setVideoPause}
                    pause={this.state.pause}
                    commentSettingAutoPlay={this.state.commentSettingAutoPlay}
                    slide={slide}
                    index={this.state.currentSlideIndex}
                    fileIndex={this.props.fileIndex}
                    clickPauseAndPlay={this.state.clickPauseAndPlay}
                    handleClickPauseAndPlay={this.handleClickPauseAndPlay}
                />
            default:
                return null;
        }
    };

    render() {
        const { fullSliderPopup, currentSlideIndex } = this.state;
        const currentSlide = this.state.medias[currentSlideIndex];
        return (
            <div>
                <Modal
                    isOpen={fullSliderPopup}
                    onClose={this.handleClose}
                    bg="transparent"
                >
                    <ModalOverlay />
                    <ModalContent className="comment-full-slider-popup" bg="transparent" marginTop="50px">
                        <ModalBody
                            className=""
                            style={{ overflow: 'inherit' }}
                            onClick={(e) => e.stopPropagation()}
                            key={currentSlide.id}
                        >
                            <div className="flex flex-col items-center justify-center">
                                <div className="comment-full-slider-block">
                                    <span
                                        className="full-slider-close-icon"
                                        onClick={() => {
                                            this.handleClose();
                                            this.props.changeValue(!this.props.changeCommentHeight);
                                        }}
                                    ></span>
                                    <h4
                                        className="text-xl font-medium"
                                        id="comment-full-slider-heading"
                                    >
                                        {!MiscFunctions.isNull(currentSlide.filename) ? currentSlide.filename : currentSlide.description.split("/").length > 1
                                            ? currentSlide.description.split("/")[1]
                                            : currentSlide.description}
                                    </h4>
                                    <div className="slider-content mb-4 relative">
                                        {this.renderSlide(currentSlide)}
                                        <button
                                            onClick={this.handlePrev}
                                            className="absolute left-0 top-1/2 rounded-full full-slider-prev-icon"
                                        >
                                        </button>
                                        <button
                                            onClick={this.handleNext}
                                            className="absolute right-0 top-1/2 rounded-full full-slider-next-icon"
                                        >
                                        </button>
                                    </div>
                                    <div className="flex overflow-x-auto full-slider-thumb-nail-block">
                                        <div className='slider-thumbs-block'>
                                            {this.state.medias.map((media, index) => {
                                                switch (media.type) {
                                                    case 'document':
                                                        return <FiFile
                                                            color='white'
                                                            className={`full-slider-thumb-nail ${index === currentSlideIndex ? 'border-2 border-blue-500' : ''}`}
                                                            onClick={() => this.handleThumbnailClick(index)}
                                                        />;
                                                    case 'video':
                                                        return <FiFilm
                                                            color='white'
                                                            className={`full-slider-thumb-nail ${index === currentSlideIndex ? 'border-2 border-blue-500' : ''}`}
                                                            onClick={() => this.handleThumbnailClick(index)}
                                                        />
                                                    case 'audio':
                                                        return <FiHeadphones
                                                            color='white'
                                                            className={`full-slider-thumb-nail ${index === currentSlideIndex ? 'border-2 border-blue-500' : ''}`}
                                                            onClick={() => this.handleThumbnailClick(index)}
                                                        />
                                                    default:
                                                        return <img
                                                            key={media.id}
                                                            src={media.thumbnailUrl}
                                                            alt={media.description}
                                                            className={`full-slider-thumb-nail ${index === currentSlideIndex ? 'border-2 border-blue-500' : ''}`}
                                                            onClick={() => this.handleThumbnailClick(index)}
                                                        />
                                                }

                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(CommentFileFullSlider);
