import React, { Component } from "react";
import { connect } from "react-redux";
import CommentUploadedFile from "./CommentUploadedFile";
import { withTranslation } from "react-i18next";
import Swal from "sweetalert2";
import GeneralToggleButton from "../Misc/GeneralToggleButton";
import MediaUploadUI from "../ContentInput/MediaUploadUI";
import { MiscFunctions } from 'helper-functions-package';
import { FiToggleRight, FiShare, FiArrowUpCircle, FiFilePlus, FiInfo } from "react-icons/fi";
import { Tooltip } from "flowbite-react";
import { Api, baseApiParams } from "my-api-client-package";
import Comment from "../../services/CommentServices";
import store from "../../store/store";
import { deleteComment, clearClickedComment, deleteReply, uploadMedias, setChangesMade, clearMedias } from "../../store/InteractionsSlice";

class CommentFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentView: this.props.commentDisplay ? true : false,
      commentEdit: this.props.commentEdit ? true : false,
      commentReply: this.props.commentReply ? true : false,
      checkCommentIsReply: this.props.checkCommentIsReply ? true : false,
      role: this.props.user.role,
      toggleStatus: false,
      openSettings: false,
      openUpload: false,
      isAccordionOpen: false,
      autoplay:
        this.props.commentUpdate === true
          ? this.props.commentForUpdate.getAutoPlay
          : false,
      stopWhenReached:
        this.props.commentUpdate === true
          ? this.props.commentForUpdate.getStopWhenReached
          : false,
      allowReplies:
        this.props.commentUpdate === true
          ? this.props.commentForUpdate.getAllowReplies
          : false,
      commentVisibilityStatusPublic:
        this.props.commentUpdate === true
          ? this.props.commentForUpdate.getVisibility === "public"
            ? true
            : false
          : true,
      commentVisibilityStatusPrivate:
        this.props.commentUpdate === true
          ? this.props.commentForUpdate.getVisibility === "private"
            ? true
            : false
          : false,
      switchSide: this.props.switchSide,
      checkSettingPanel: this.props.checkSettingPanel,
      api: new Api({
        baseUrl: process.env.REACT_APP_API_URL
      }),
      commentMedias: []
    };
  }
  confirmDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4baaac",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.state.api.comment.deleteComment(id, baseApiParams())
          .then((response) => {
            if (response.data === undefined) {
              Swal.fire({
                title: "Error!",
                text: "Comment not deleted. You're neither admin nor owner!",
                icon: "error",
                confirmButtonColor: "#4baaac",
              });
            } else {
              this.props.setDeleteComment(!this.props.deleteComment);
              if (this.props.clickedComment.getParent === this.props.clickedComment.getID) {
                store.dispatch(deleteComment(this.props.clickedComment.getID));
              } else {
                store.dispatch(deleteReply({ commentID: this.props.clickedComment.getParent, reply: this.props.clickedComment }));
              }

              Swal.fire({
                title: "Deleted!",
                text: "Comment has been deleted.",
                icon: "success",
                confirmButtonColor: "#4baaac",
              });
              if (this.props.clickedComment.getParent === this.props.clickedComment.getID) {
                store.dispatch(clearClickedComment())
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (MiscFunctions.isNull(document.getElementById("comment-setting-block-id")) && this.state.openSettings === true && this.props.checkSettingPanel === false) {
      this.handleOpenSettings();
      this.props.handleSettingPanel(true);
    }

    if (this.state.checkSettingPanel !== this.props.checkSettingPanel) {
      this.setState({ checkSettingPanel: this.props.checkSettingPanel })
    }
    if (prevProps.commentUpdate !== this.props.commentUpdate) {
      if (this.props.commentUpdate !== true)
        this.setState({
          openSettings: false,
          autoplay: false,
          stopWhenReached: false,
          allowReplies: false,
        });
    }
    if (prevProps.switchSide !== this.props.switchSide) {
      this.setState({ switchSide: this.props.switchSide });
    }
  }

  handleAutoplayToggle = (status) => {
    this.setState({ autoplay: status });
    this.props.handleAutoplaySettings(status);
    if (status === true) {
      this.handleStopWhenReachedToggle(status)
    }
  };

  handleStopWhenReachedToggle = (status) => {
    this.setState({ stopWhenReached: status });
    this.props.handleStopWhenReached(status);
  };

  handleAllowRepliesToggle = (status) => {
    this.setState({ allowReplies: status });
    this.props.handleAllowReplies(status);
  };

  handleSetCommentStatusPublic = async (status) => {
    this.setState({
      commentVisibilityStatusPublic: status,
      commentVisibilityStatusPrivate: !this.state.commentVisibilityStatusPrivate
    });
    this.props.handleSetCommentStatusPublic(status);
  };

  handleSetCommentStatusPrivate = async (status) => {
    this.setState({
      commentVisibilityStatusPublic: !this.state.commentVisibilityStatusPublic,
      commentVisibilityStatusPrivate: status
    });
    this.props.handleSetCommentStatusPrivate(status);
  };

  handleOpenSettings = () => {
    this.setState((prevState) => ({
      openSettings: !prevState.openSettings,
      openUpload: false,
    }));
  };

  handleOpenUpload = () => {
    this.setState((prevState) => ({
      openUpload: !prevState.openUpload,
      openSettings: false,
    }));
  };

  toggleAccordion = () => {
    this.setState({ isAccordionOpen: !this.state.isAccordionOpen });
  };

  handleMediaUpload = (elem, fileInfo, isPictureVideo = false) => {
    const state = store.getState().interactions;
    const params = {
      commentID: this.props.editCommentReply 
        ? (state.clickedReply?.id || null)
        : (state.comment?.id || null),
      medias: elem,
      reply: this.props.editCommentReply || false,
      parentID: this.props.editCommentReply 
        ? state.clickedReply?.parent?.id 
        : null,
      commentReply: this.props.commentReply || false
    };

    if (this.props.editCommentReply) {
      store.dispatch(clearMedias());
    }
    
    store.dispatch(uploadMedias(params));
    store.dispatch(setChangesMade(true));
    this.setState({ 
      commentMedias: store.getState().interactions.medias,
      openUpload: false 
    });
  };

  handlePictureVideo = (elem, fileInfo) => {
    this.handleMediaUpload(elem, fileInfo, true);
  };

  handleDocument = (elem, fileInfo) => {
    this.handleMediaUpload(elem, fileInfo, false);
  };

  renderCommentUploadedFile = (props = {}) => {
    const commonProps = {
      changeValue: this.props.changeValue,
      changeCommentHeight: this.props.changeCommentHeight,
      commentMedias: this.state.commentMedias,
      setMiniSlider: this.props.setMiniSlider,
      user: this.props.user,
      setUpdateMediaFile: this.props.setUpdateMediaFile,
      updateMediaFile: this.props.updateMediaFile,
      commentUpdate: this.props.commentUpdate,
      commentForUpdate: this.props.commentForUpdate,
      commentForReply: this.props.commentForReply,
      setFileIndex: this.props.setFileIndex
    };

    return <CommentUploadedFile {...commonProps} {...props} />;
  };

  renderMediaUploadUI = () => (
    <div className="comment-upload-block">
      <MediaUploadUI
        label={
          <>
            <FiFilePlus />
            <span className="comment-setting-option-text">
              Document <span className="comment-text-gray">(50MB)</span>
            </span>
          </>
        }
        buttonClass="comment-upload-option"
        selectHandler={this.handleDocument}
        fileType={"doc"}
        description={false}
      />

      <MediaUploadUI
        label={
          <>
            <FiArrowUpCircle />
            <span className="comment-setting-option">
              Audio, Picture, Video <span className="comment-text-gray">(5GB)</span>
            </span>
          </>
        }
        buttonClass="comment-upload-option"
        selectHandler={this.handlePictureVideo}
        border={15}
        imageWidth={1600}
        imageHeight={900}
        borderRadius={0}
        reScale={true}
        rotate={true}
        fileType={"image,video,audio"}
        description={false}
      />
    </div>
  );

  renderSettingsPanel = () => (
    <div className="comment-setting-block" id={this.state.openSettings && this.props.checkSettingPanel ? "comment-setting-block-id" : ""}>
      <span className="comment-setting-option">
        <span className="comment-setting-option-text">Pause the video when the comment is reached</span>
        <GeneralToggleButton isActive={this.state.stopWhenReached} onToggle={this.handleStopWhenReachedToggle} size="small" />
      </span>

      <span className="comment-setting-option">
        <span className="comment-setting-option-text">Autoplay (Video, Audio, Text to Speech)</span>
        <GeneralToggleButton isActive={this.state.autoplay} onToggle={this.handleAutoplayToggle} size="small" />
      </span>

      <span className="comment-setting-option" data-test="reply-comment">
        <span className="comment-setting-option-text">Allow reply</span>
        <GeneralToggleButton isActive={this.state.allowReplies} onToggle={this.handleAllowRepliesToggle} size="small" />
      </span>

      <span className="comment-setting-option">
        <span className="comment-setting-option-text">
          Make comment public
          <Tooltip content="only visible to the company">
            <FiInfo color="#8C8C8C" style={{ width: "20px", height: "20px", paddingLeft: "5px" }} />
          </Tooltip>
        </span>
        <GeneralToggleButton isActive={this.state.commentVisibilityStatusPublic} onToggle={this.handleSetCommentStatusPublic} size="small" />
      </span>

      <span className="comment-setting-option">
        <span className="comment-setting-option-text">
          Make comment private
          <Tooltip content="only visible to you">
            <FiInfo color="#8C8C8C" style={{ width: "20px", height: "20px", paddingLeft: "5px" }} />
          </Tooltip>
        </span>
        <GeneralToggleButton isActive={this.state.commentVisibilityStatusPrivate} onToggle={this.handleSetCommentStatusPrivate} size="small" />
      </span>
    </div>
  );

  render() {
    const { switchSide, commentReply } = this.props;
    const clickedComment = this.props.clickedComment && new Comment(this.props.clickedComment);
    
    return (
      <>
        <div className="comment-footer-block" style={{
          borderRadius: `${!MiscFunctions.isNull(switchSide) && !MiscFunctions.isUndefined(switchSide) && switchSide === "left" ? "0 0 5px 0" : "0 0 0 5px"}`
        }}>
          {this.state.commentEdit && this.renderCommentUploadedFile({ commentEdit: true })}
          {commentReply && this.renderCommentUploadedFile({ commentEdit: false, commentReply })}
          {this.props.editCommentReply && this.props.commentForReply.id === clickedComment.getID && 
            this.renderCommentUploadedFile({ editCommentReply: true, commentEdit: this.props.commentEdit })}
          
          {this.state.commentView && !(this.props.editCommentReply && this.props.commentForReply.id === clickedComment.getID) && 
            clickedComment.medias?.length > 0 && 
            this.renderCommentUploadedFile({ 
              commentEdit: false,
              checkCommentIsReply: this.props.checkCommentIsReply,
              clickedComment: this.props.clickedComment
            })}

          {((this.state.commentReply || (this.props.editCommentReply && this.props.commentForReply.id === clickedComment.getID))) && (
            <div className="comment-footer-detial-feature">
              {this.state.openUpload && this.renderMediaUploadUI()}
              <FiShare
                style={{ width: "30px", height: "24px", cursor: "pointer" }}
                color={this.state.openUpload ? "#4BAAAC" : ""}
                onClick={() => this.handleOpenUpload()}
              />
            </div>
          )}

          {this.state.commentEdit && (
            <div className="comment-footer-detial-feature">
              {!MiscFunctions.isUndefined(this.state.role) && this.state.role.toLowerCase() === "admin" && (
                <>
                  {this.state.openSettings && this.renderSettingsPanel()}
                  <div className="comment-footer-icons">
                    <FiToggleRight
                      data-test="comment-setting"
                      className="toggleright-icon"
                      onClick={() => this.handleOpenSettings()}
                      color={this.state.openSettings ? "#4BAAAC" : ""}
                    />
                    <FiShare
                      className="share-icon"
                      data-test="comment-upload-icon"
                      color={this.state.openUpload ? "#4BAAAC" : ""}
                      onClick={() => this.handleOpenUpload()}
                    />
                  </div>
                </>
              )}
              {this.state.openUpload && this.renderMediaUploadUI()}
            </div>
          )}
        </div>

        {(!this.state.commentEdit && !this.props.commentReplyEdit && 
          !(this.props.editCommentReply && !MiscFunctions.isUndefined(this.props.commentForReply) && 
            this.props.commentForReply.id === clickedComment.id)) && (
          <div className="comment-footer-detial">
            <span className="comment-footer-date-block">
              <span>{!MiscFunctions.isUndefined(clickedComment) ? "Changed: " : "Creation: "}</span>
              <span>
                {(!MiscFunctions.isUndefined(clickedComment) && clickedComment.getIsDateChanged)
                  ? clickedComment.getDateChangedFormat
                  : clickedComment.getDateCreatedFormat}
              </span>
            </span>
            {(!MiscFunctions.isUndefined(this.state.role) && 
              (this.state.role.toLowerCase() === "admin" || clickedComment.getUserID === this.props.user.UserID)) && (
              <span
                className="comment-footer-delete-bin"
                onClick={() => this.confirmDelete(clickedComment.getID)}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  commentReply: state.interactions.commentReply,
});

export default connect(mapStateToProps)(withTranslation()(CommentFooter));
