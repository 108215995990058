import React from "react";
import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Box,
} from "@chakra-ui/react";
import WorkflowBar from "./WorkflowBar"; // Ensure correct import
import { useSelector } from 'react-redux';
import { selectTasksSegments } from "../../selector/segmentsSelector";
const WorkflowBarWrapper = ({
    workflowParameters,
    WorkflowPhases,
    playerRef,
    showIntroPopup,
    handleOpenIntroPopup,
    handlingZoomScroll,
    mainVideoID,
    zoomLevel,
    rangeSliderValue,
    setInfoCard,
    workflow,
    isOpen,
    onOpen,
    onClose,
}) => {
    const isMobile = useSelector(state => state.isMobile);
    const WorkflowTasks = useSelector(state => selectTasksSegments(state));
    return (
        <>
            {/* Always visible on larger screens */}
            {!isMobile && !isOpen && playerRef.current && (
                <Box className="workflow_bar">
                    <WorkflowBar
                        workflowParameters={workflowParameters}
                        WorkflowPhases={WorkflowPhases}
                        WorkflowTasks={WorkflowTasks}
                        videoLabel={workflow.label}
                        player={playerRef}
                        showIntroPopup={showIntroPopup}
                        handleOpenIntroPopup={handleOpenIntroPopup}
                        handlingZoomScroll={handlingZoomScroll}
                        videoID={mainVideoID}
                        selectedVideo={workflow.selectedVideo}
                        zoomLevel={zoomLevel}
                        rangeSliderValue={rangeSliderValue}
                        setInfoCard={setInfoCard}
                    />
                </Box>
            )}

            {/* Mobile Drawer with Toggle Button */}
            {/* {isMobile && playerRef.current && ( */}
            {(
                <>
                    <Drawer isOpen={isOpen} placement="bottom" className="workflow_bar" onClose={onClose} padding={0}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerBody padding={0} margin={0} className="workflow_bar_body">
                                <WorkflowBar
                                    workflowParameters={workflowParameters}
                                    WorkflowPhases={WorkflowPhases}
                                    WorkflowTasks={WorkflowTasks}
                                    videoLabel={workflow.label}
                                    player={playerRef}
                                    showIntroPopup={showIntroPopup}
                                    handleOpenIntroPopup={handleOpenIntroPopup}
                                    handlingZoomScroll={handlingZoomScroll}
                                    videoID={mainVideoID}
                                    selectedVideo={workflow.selectedVideo}
                                    zoomLevel={zoomLevel}
                                    rangeSliderValue={rangeSliderValue}
                                    setInfoCard={setInfoCard}
                                />
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </>
            )}
        </>
    );
};

export default WorkflowBarWrapper;
