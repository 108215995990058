import { baseApiParams, Api, handleApiError } from 'my-api-client-package';
import { setAuthAccessObject } from '../utils/tokenStorage.js';
import MiscFunctionsLocal from './MiscFunctions.js';

export const loadUserInfo = async (token, dispatch, navigate) => {
    const API_URL = process.env.REACT_APP_API_URL || '';
    const api = new Api({ baseUrl: API_URL });

    if (token) {
        try {
            const loggedInUser = await api.user.getCurrentUser(baseApiParams());
            const userData = loggedInUser.data;
            console.log("userData", userData)
            dispatch({ type: 'user/setUser', payload: userData });
            console.log(userData)
            const data = MiscFunctionsLocal.getLanguage(userData.preferences);
            dispatch({ type: 'language/setUserSelectedLanguage', payload: data })
            api.user.getUserByMail(userData.email, baseApiParams()).then((resp) => {
                let newID = resp.data.message
                console.log("User-ID: " + newID);
                dispatch({ type: 'token/setToken', payload: token });
                setAuthAccessObject(
                    JSON.stringify({
                        loginStatus: true,
                        token: token,
                        recaptcha: '',
                        ID: newID,
                    })
                );
                
                navigate("/mediathek");
            })

        } catch (err) {
            handleApiError(err, navigate);
        }
    }
};