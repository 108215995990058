import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProfileState {
    eduLevel: string[] | any;
    specialization: string[] | any;
    specialistArea: string[] | any;
    profilePicture: string | any;
}

const initialState: ProfileState = {
    eduLevel: null,
    specialization: null,
    specialistArea: null,
    profilePicture: null,
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setEduLevel: (state, action: PayloadAction<string[]>) => {
            state.eduLevel = action.payload;
        },
        setSpecialization: (state, action: PayloadAction<string[]>) => {
            state.specialization = action.payload;
        },
        setSpecialistArea: (state, action: PayloadAction<string[]>) => {
            state.specialistArea = action.payload;
        },
        setProfilePicture: (state, action: PayloadAction<string>) => {
            state.profilePicture = action.payload;
        },
    },
});

// Export actions so components can update the active page
export const { setEduLevel, setSpecialization, setSpecialistArea, setProfilePicture } = profileSlice.actions;
export default profileSlice.reducer;
