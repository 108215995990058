import { Button, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Card, Popover, PopoverTrigger, PopoverContent, PopoverBody, Box, VStack, Text, Tooltip } from "@chakra-ui/react"
import { Api } from "my-api-client-package";
import { useEffect, useState } from "react";
import { FiEye, FiTrash2, FiCopy } from "react-icons/fi";
import { useSelector } from "react-redux";
import { baseApiParams } from "my-api-client-package";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const LinkManagement = (props: any) => {
    const user = useSelector((state: any) => state.user);

    const [workflowsLinks, setWorkflowsLinks] = useState<any[]>([]);
    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });
    const { t } = useTranslation();
    useEffect(() => {
        api.passwordaccess.getListOfPasswordAccessForCompanies([user.company.id], baseApiParams())
            .then((response: any) => {
                setWorkflowsLinks(response.data);
                console.log(response.data);
            });

    }, []);

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to remove this workflow link?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C7A7B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!'
        }).then((result: any) => {
            if (result.isConfirmed) {
                api.passwordaccess.removeMultiplePasswordAccesses([id], baseApiParams())
                    .then((response: any) => {
                        setWorkflowsLinks(workflowsLinks.filter((workflow) => workflow.id !== id));
                        Swal.fire(
                            'Removed!',
                            'The workflow link has been removed.',
                            'success'
                        )
                    });
            }
        });
    }

    const handleCopyLink = (workflowLink: string) => {
        navigator.clipboard.writeText(workflowLink);
        Swal.fire({
            title: 'Link Copied!',
            text: 'The workflow link has been copied to your clipboard.',
            icon: 'success',
            confirmButtonColor: '#2C7A7B',
            confirmButtonText: 'Ok'
        });
    }
    return (
        <Card padding="15px" flex="1">
            <TableContainer paddingTop={"10px"}>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>{t('general.video')}</Th>
                            <Th>{t('general.expirationDate')}</Th>
                            <Th>{t('general.actions')}</Th>
                            <Th>{t('general.viewLog')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {workflowsLinks.map((workflow) => (
                            <Tr>
                                <Td>{workflow.workflow.label}</Td>
                                <Td>{new Date(workflow.expiresAt).toLocaleString()}</Td>
                                <Td display="flex" gap="5px">
                                    <Tooltip label="Remove" aria-label="Remove">
                                        <Button bg="red.500" w="35px" h="35px" borderRadius="full" _hover={{ bg: "red.600" }} color="white" size="sm" onClick={() => { handleDelete(workflow.id) }}>
                                            <FiTrash2 />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip label="Copy Link" aria-label="Copy Link">
                                        <Button bg="#2C7A7B" w="35px" h="35px" borderRadius="full" _hover={{ bg: "#2C7A7B" }} color="white" size="sm" onClick={() => { handleCopyLink(workflow.workflowLink) }}>
                                            <FiCopy />
                                        </Button>
                                    </Tooltip>
                                </Td>
                                <Td>
                                    <Popover placement="left" trigger="hover">
                                        <PopoverTrigger>
                                            <Button bg="#2C7A7B" w="35px" h="35px" borderRadius="full" _hover={{ bg: "#2C7A7B" }} color="white" size="sm">
                                                <FiEye />
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent width="300px" maxH="400px">
                                            <PopoverBody p={3} overflowY="auto" className="small-blocks-scroll">
                                                <VStack align="start" spacing={2}>
                                                    <Text fontWeight="bold" fontSize="sm">Access Logs</Text>
                                                    {workflow.accessLogs && workflow.accessLogs.length > 0 ? (
                                                        workflow.accessLogs.map((log: any, index: number) => (
                                                            <Box key={index} w="100%" p={2} bg="gray.50" borderRadius="md">
                                                                <Text fontSize="sm" fontWeight="medium">{log.accessedBy}</Text>
                                                                <Text fontSize="xs" color="gray.600">
                                                                    {new Date(log.timestamp).toLocaleString()}
                                                                </Text>
                                                                {log.description && (
                                                                    <Text fontSize="xs" color="gray.500" mt={1}>
                                                                        {log.description}
                                                                    </Text>
                                                                )}
                                                            </Box>
                                                        ))
                                                    ) : (
                                                        <Text fontSize="sm" color="gray.500">No access logs available</Text>
                                                    )}
                                                </VStack>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default LinkManagement;