import { Container, Divider, FormLabel, FormControl, Flex, Button, Input, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Card } from "@chakra-ui/react"
import { Api } from "my-api-client-package";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { FiTrash2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import { baseApiParams } from "my-api-client-package";
import { useTranslation } from "react-i18next";

const InviteUser = (props: any) => {
    const user = useSelector((state: any) => state.user);
    const [inviteEmail, setInviteEmail] = useState<string>("");

    const [invitedUsers, setInvitedUsers] = useState<any[]>([]);
    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });
    const { t } = useTranslation();
    const handleDelete = (email: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to revoke this user's invitation?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C7A7B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, revoke it!'
        }).then((result: any) => {
            if (result.isConfirmed) {
                api.company.declineUserToCompany(user.company.id, { email: email }, baseApiParams())
                    .then((response: any) => {
                        setInvitedUsers(invitedUsers.filter((user) => user.email !== email));
                        Swal.fire(
                            'Revoked!',
                            'The invitation has been revoked.',
                            'success'
                        )
                    }).catch((error: any) => {
                        console.log(error);
                        props.setNotificationHeading(t("messages.error"));
                        props.setNotificationMessage("Der Benutzer wurde nicht erfolgreich entfernt.");
                        props.setNotificationType("error");
                    });
            }
        });
    };

    const handleInvite = (inviteEmail: string) => {
        if (inviteEmail) {
            api.company.inviteUserToCompany(user.company.id, { email: inviteEmail }, baseApiParams())
                .then((response: any) => {
                    props.setNotificationHeading(t("smartslide.success_state"));
                    props.setNotificationMessage("Der Benutzer wurde erfolgreich eingeladen.");
                    props.setNotificationType("success");
                    setInviteEmail("");
                    setInvitedUsers([...invitedUsers, { email: inviteEmail }]);
                })
                .catch((error: any) => {
                    console.log(error);
                    props.setNotificationHeading(t("messages.error"));
                    props.setNotificationMessage("Der Benutzer wurde nicht erfolgreich eingeladen.");
                    props.setNotificationType("error");
                });
        }
    };

    useEffect(() => {
        api.company.getCompanyInvitations(user.company.id, baseApiParams())
            .then((response: any) => {
                setInvitedUsers(response.data);
            });
    }, []);
    return (
        <Card padding="15px" flex="1">
            <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                <Flex gap="20px" align="flex-end">
                    <FormControl flex="1">
                        <FormLabel className="input-label">{t('general.emailAdr')}</FormLabel>
                        <Input
                            name="email"
                            type="email"
                            className="payment-input-input"
                            value={inviteEmail}
                            onChange={(e) => setInviteEmail(e.target.value)}
                        />
                    </FormControl>
                    <Button bg="#2C7A7B" color="white"
                        onClick={() => handleInvite(inviteEmail)}
                        _hover={{ bg: "#2C7A7B" }}>{t('general.invite')}</Button>
                </Flex>
            </Container>
            <Divider marginTop={"25px"} marginBottom={"25px"} color="gray.400" />

            <TableContainer paddingTop={"10px"}>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>{t('general.emailAdr')}</Th>
                            <Th>{t('general.revoke')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {invitedUsers.map((user) => (
                            <Tr>
                                <Td>{user.email}</Td>
                                <Td>
                                    <Button bg="red.500" hover={{ bg: "red.600" }} color="white" size="sm" onClick={() => handleDelete(user.email)}>
                                        <FiTrash2 /> {t('general.revokeInvite')}
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default InviteUser;