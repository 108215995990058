import Interactions from '../services/InteractionsServices';
import Comment from '../services/CommentServices';

export const selectVideoInteractions = (state) => {
  let Captions = new Interactions([]);
  if (state.interactions.interactions.length > 0) {
    state.interactions.interactions.map((response) => {
      return (Captions.addComment = new Comment(response));
    });
  }
  return Captions;
};

export const selectVideoAnnotations = (state) => {
    return state.interactions.annotations;
  };
  