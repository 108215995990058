/**
 *
 * @file MiscFunctionsLocal.js
 * @created_date Wednesday, December 28,2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The module containing the utility functions serves as a collection of miscellaneous functions that can be used across different parts of the application. These functions are designed to perform specific tasks or provide helpful functionality that can be reused in various contexts.
 * <br/>Example use age of code - {@tutorial MiscFunctionsLocal}
 * @module MiscFunctionsLocal
 *
 **/

import userImage from "../assets/img/normal_control_icon_user_account.svg";
import Workflow from "../services/WorkflowService";
import { MiscFunctions } from 'helper-functions-package';
import { removeAuthAccessObject } from "../utils/tokenStorage";
import { Api, baseApiParams } from "my-api-client-package";
import store from "../store/store";
import { setUser } from "../store/userSlice";

const api = new Api({
  baseUrl: process.env.REACT_APP_API_URL
});

const gotoTimestamp = (val, player) => {
  while (document.getElementsByClassName("vjs-poster").length > 0) {
    document
      .getElementsByClassName("vjs-poster")[0]
      .parentNode.removeChild(document.getElementsByClassName("vjs-poster")[0]);
  }
  player.current.currentTime(val + 0.001);
};

const checkCommentText = (string) => {
  string = string
    .replaceAll(/\n/g, " &/n ")
    .replaceAll(/"/g, "&quot")
    .replaceAll(/'/g, "&apos")
    .replaceAll(String.fromCharCode(92), "&bsol")
    .replaceAll("}", "&rcub")
    .replaceAll("{", "&lcub");
  return string;
};

const formatText = (string) => {
  if (string.length > 0) {
    const pattern = /\@\[(.*?)\]/g;
    string = string.replace(/&quot/g, '"').replace(/&apos/g, "'");
    return string;
  } else {
    return string;
  }
};

const formatCommentText = (string) => {
  if (string !== null) {
    const pattern = /\@\[(.*?)\]/g;
    string = string
      .replaceAll(/&quot/g, '"')
      .replaceAll(/&apos/g, "'")
      .replaceAll(/&bsol/g, String.fromCharCode(92))
      .replaceAll(/&rcub/g, "}")
      .replaceAll(/&lcub/g, "{");
    let match;
    const Out = [];
    let pos = 0;
    while (!MiscFunctions.isNull((match = pattern.exec(string)))) {
      let objects = match[1].split(",");
      if (objects.length > 0) {
        if (match.index > 0) {
          Out.push({
            string:
              string.slice(pos, match.index - 1).replace(/\&\/n/g, "\n") + " ",
            type: "t",
            id: "",
          });
        }
        Out.push({
          string: objects[0] + " ",
          type: "p",
          id: objects[1],
        });
        pos = match.index + match[0].length + 1;
      }
    }

    Out.push({
      string: string.slice(pos).replace(/\&\/n/g, "\n"),
      type: "t",
      id: "",
    });

    //console.log(Out)

    return Out.map((elem) => {
      if (elem.type === "p") {
        return (
          <span
            className="user-tag"
            id={elem.id}
            onMouseOver={(ev) => {
              ev.target.className = "user-tag-hover";
            }}
            onMouseOut={(ev) => {
              ev.target.className = "user-tag";
            }}
          >
            {elem.string}
          </span>
        );
      } else if (elem.type === "t") {
        return (
          <span className="text-tag" id={elem.id}>
            {elem.string}
          </span>
        );
      }
    });
  }
};

const encodeCommentText = (string) => {
  string = string
    .replaceAll(" &/n ", "\n")
    .replaceAll(/&quot/g, '"')
    .replaceAll(/&apos/g, "'")
    .replaceAll(/&bsol/g, String.fromCharCode(92))
    .replaceAll(/&rcub/g, "}")
    .replaceAll(/&lcub/g, "{");
  return string;
}
const createUserObject = (res) => {
  if (!MiscFunctions.isUndefined(res)) {
    let User = {
      UserID: res.id.id,
      role: !MiscFunctions.isNull(res.role) ? res.role : null,
      display: res.firstname && res.lastname ? res.firstname + " " + res.lastname : res.email.split('@')[0],
      avatar: !MiscFunctions.isNull(res.avatar) ? res.avatar : userImage,
      UserCompany: MiscFunctions.isNull(res.company) ? null : res.company.id,
      notifications: !MiscFunctions.isNull(res.notifications) ? res.notifications : [],
      favourites: !MiscFunctions.isNull(res.favourites) ? res.favourites : [],
      preferences: !MiscFunctions.isNull(res.preferences) ? res.preferences : [],
      languages: !MiscFunctions.isNull(res.languages) ? res.languages : [],
    };
    return User;
  } else {
    return null;
  }
};

function sortDataByLabel(data, sortOptions) {
  let encapsulatedData = data.map((el) => {
    return new Workflow(el);
  });
  switch (sortOptions) {
    case "az":
      encapsulatedData.sort((a, b) =>
        a.getLabel.localeCompare(b.getLabel, undefined, { numeric: true })
      );
      break;
    case "za":
      encapsulatedData.sort((a, b) =>
        b.getLabel.localeCompare(a.getLabel, undefined, { numeric: true })
      );
      break;
    case "date":
      encapsulatedData.sort(
        (a, b) =>
          !MiscFunctions.isNull(a.getCreationDate) &&
          a.getCreationDate.localeCompare(b.getCreationDate)
      );
      break;
    default:
      break;
  }
  return encapsulatedData;
}

const getLanguage = (e) => {
  return e.find(elem => elem === "german" || elem === "english");
}
const changeLanguage = async (e, language) => {
  let languageTag = language;
  let languageTagNew = e;
  let dataNew;
  let data;
  switch (languageTag) {
    case "english":
      data = await api.user.updatePreferences({ preferences: ["english"] }, baseApiParams());
      dataNew = await api.user.updatePreferences({ preferences: [languageTagNew] }, baseApiParams());
      break;
    case "german":
      data = await api.user.updatePreferences({ preferences: ["german"] }, baseApiParams());
      dataNew = await api.user.updatePreferences({ preferences: [languageTagNew] }, baseApiParams());
      break;
  }
  store.dispatch(setUser(dataNew.data))
};

const handleDownload = async (media) => {
  try {
    const mediaData = await api.download.getMediaDownload(media.id, baseApiParams());
    console.log(mediaData)
    const dataType = media.filename.split(".").pop().toLowerCase();
    const mimeTypes = {
      pdf: "application/pdf",
      jpg: "image/jpeg",
      png: "image/png",
      mp4: "video/mp4",
      mp3: "audio/mp3"
    };
    if (!mimeTypes[dataType]) {
      throw new Error(`Unsupported file type: ${dataType}`);
    }
    const response = await fetch(mediaData.data.message);
    const data = await response.arrayBuffer();
    const blob = new Blob([data], { type: mimeTypes[dataType] });
    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.download = media.filename;
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Download failed:", error.message);
  }
};

const checkCategoryValid = (category) => {
  if (category.label !== "Comment" && category.label !== "Question" && category.label !== "Notice") {
    return false;
  }
  return true;
}

const logout = () => {
  removeAuthAccessObject();
  window.location.href = "/landing_page";
};

const estimateSentenceDuration = (sentence, timePerWord = 0.6) => {
  sentence = sentence.replace('[MEDIA WAIT]', ' ')
  const wordCount = sentence.trim().split(/\s+/).length;

  const totalSeconds = wordCount * timePerWord;

  return totalSeconds
}

const MiscFunctionsLocal = {
  gotoTimestamp,
  checkCommentText,
  formatCommentText,
  createUserObject,
  sortDataByLabel,
  formatText,
  changeLanguage,
  handleDownload,
  encodeCommentText,
  checkCategoryValid,
  logout,
  getLanguage,
  estimateSentenceDuration
};
export default MiscFunctionsLocal;
