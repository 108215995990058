import player_poster from "../../assets/img/player/player_poster.jpg";
import { setAnnotations, clearAnnotations, setAnnotation, clearClickedComment } from "../../store/InteractionsSlice";
import store from "../../store/store";
import { MiscFunctions } from "helper-functions-package";
class propertyCommentAnnotation {
    constructor(id, x, y, category, state, mainComment) {
        this.id = id;
        this.left = x;
        this.top = y;
        this.category = category;
        this.status = state;
        this.mainComment = mainComment;
    }
    changeState = (state) => {
        this.status = state;
    };
}

const handleSlowPause = (playerRef) => {
    if (playerRef.current) {
        const decrementRate = () => {
            const currentRate = playerRef.current.playbackRate();
            const currentVolume = playerRef.current.volume();

            if (currentRate > 0.1) {
                playerRef.current.playbackRate(currentRate - 0.05); // Decrease playback rate
            }

            if (currentVolume > 0.0) {
                playerRef.current.volume(Math.max(0, currentVolume - 0.05)); // Decrease volume
            }

            if (currentRate > 0.1 || currentVolume > 0.0) {
                setTimeout(decrementRate, 100); // Set timeout for smooth transition
            } else {
                playerRef.current.pause(); // Pause the video
                playerRef.current.playbackRate(1); // Reset playback rate
                playerRef.current.volume(1); // Reset volume for next play
            }
        };
        decrementRate();
    }
};


const loadCues = async (actCue, user) => {
    if (actCue && actCue.cues_ && actCue.cues_.length > 0) {
        const AnnotationArray = [];
        actCue.cues_.forEach((el) => {
            let dummy;
            dummy = JSON.parse(el.text);
            if (dummy && !dummy.error) {
                try {
                    let comment = store.getState().interactions.interactions.filter(elem => elem.id === dummy.id)[0];
                    let pos = comment.position;
                    let category = comment.category[0].type;
                    AnnotationArray.push(
                        {
                            "id" : comment.id,
                            "left" : pos[0] * 100 + "%",
                            "top" : pos[1] * 100 + "%",
                            "category" : category,
                            "startTime" : MiscFunctions.DateString2Seconds(comment.timestamp),
                            "autoplay" : comment?.commentSettings?.autoplay !== undefined ? comment.commentSettings.autoplay : false
                        }
                    );
                } catch (error) {
                    console.log(error);
                }
            }
        });
        store.dispatch(setAnnotations(AnnotationArray));
        if (store.getState().interactions.comment!==null){
            const foundAnnotation = AnnotationArray.filter(elem => elem.id === store.getState().interactions.comment.id)[0]
            if (foundAnnotation) {
                store.dispatch(setAnnotation(foundAnnotation));
            } else {
                store.dispatch(clearClickedComment());
            }
        }
        //   setpropertyCommentAnnotationArray(AnnotationArray);
        return await AnnotationArray
    } else {
        const AnnotationArray = [];
        store.dispatch(clearAnnotations());
        return AnnotationArray;
        //   setpropertyCommentAnnotationArray(AnnotationArray);
    }
};


const videoJsOptions = (props, getVideoURL) => {
    return {
        preload: true,
        controls: true,
        responsive: true,
        bigPlayButton: false,
        fluid: true,
        poster: player_poster,
        inactivityTimeout: 0,
        playbackRates: [0.5, 1, 1.5, 2, 3],
        sources: [
            {
                src:
                    props.patientVideo === true
                        ? props.workflow.getSelectedVideo[0].media
                        : getVideoURL,
                type: "video/mp4",
            },
        ],
        controlBar: {
            fullscreenToggle: true,
            pictureInPictureToggle: true,
            remainingTimeDisplay: false,
            volumePanel: true,
            currentTimeDisplay: false,
            timeDivider: false,
            durationDisplay: false,
        },
        fullscreen: {
            options: { navigationUI: 'show' }
        }
    }

};


const PlayerControl = {
    handleSlowPause,
    loadCues,
    videoJsOptions,
    propertyCommentAnnotation
}

export default PlayerControl