import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VideoFormState {
  language: string;
  gender: string;

}

const initialState: VideoFormState = {
  language: 'German',
  gender: 'female'
};

const videoFormSlice = createSlice({
  name: 'videoForm',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<boolean>) => {
      state.language = action.payload;
    },
    setGender: (state, action: PayloadAction<string | null>) => {
      state.gender = action.payload;
    },
  },
});

export const {
  setLanguage,
  setGender,
} = videoFormSlice.actions;

export default videoFormSlice.reducer; 