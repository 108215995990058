import { createSlice } from "@reduxjs/toolkit";

const ProgressBarPosition = createSlice({
    name: 'ProgressBarPosition',
    initialState: {
        pos: 0,
        time: "0.00",
    },
    reducers: {
        setProgressBarPosition: (state, action) => action.payload,
        clearProgressBarPosition: () => ({
            pos: 0,
            time: "0.00",
        }),
    },  
});

export const { setProgressBarPosition, clearProgressBarPosition } = ProgressBarPosition.actions;
export default ProgressBarPosition.reducer;