import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { MiscFunctions } from 'helper-functions-package';
import Swal from "sweetalert2";
import { Api, baseApiParams } from "my-api-client-package";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box,
} from "@chakra-ui/react";
import { FiFile, FiFilm, FiHeadphones, FiDownload } from "react-icons/fi";
import { connect } from "react-redux";
import Comment from '../../services/CommentServices';
import store from "../../store/store";
import { deleteMedias, setClickedComment, setClickedReply, clearClickedReply } from "../../store/InteractionsSlice";

class CommentUploadedFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      fileIndex: this.props.fileIndex,
      fileUrl: null,
      medias: [],
      commentMedias: null,
      filePreview: false,
      downloadSuccess: null,
      api: new Api({
        baseUrl: process.env.REACT_APP_API_URL
      })
    };
  }

  toggleAccordion = () => {
    this.setState({ isOpen: !this.state.isOpen });
    if (!this.props.commentEdit) {
      this.props.changeValue(!this.props.changeCommentHeight)
    }
  };

  deleteUploadedFile = async (id, medias, index) => {
    if (this.props.commentUpdate) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4baaac",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.state.api.media.deleteMedia(id, baseApiParams()).then((response) => {
            const deleteFile = [...medias].splice(index, 1);
            if (this.props.editCommentReply) {
              store.dispatch(deleteMedias(({ commentID: this.props.interactions.clickedReply.id, commentMedias: medias, deleteElem: deleteFile[0].id })))
            } else {
              store.dispatch(deleteMedias(({ commentID: this.props.interactions.comment.id, commentMedias: medias, deleteElem: deleteFile[0].id })))
            }
            this.setState({ medias: store.getState().interactions.medias });
            if (this.props.commentEdit !== true) {
              this.props.setUpdateMediaFile(!this.props.updateMediaFile);
            }
          });
          this.props.setUpdateMediaFile(!this.props.updateMediaFile);
        }
      });
    } else {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4baaac",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      if (result.isConfirmed) {
        const data = await this.state.api.media.deleteMedia(id, baseApiParams());
        const deleteFile = medias.splice(index, 1);
        this.setState({ medias: medias });
        if (this.props.commentEdit !== true) {
          this.props.setUpdateMediaFile(!this.props.updateMediaFile);
        }
      }
    }
  };

  checkDeletedMedia = (values) => {
    if (!MiscFunctions.isNull(values) && !MiscFunctions.isUndefined(values)) {
      let data = values.filter(
        (value) => value.description !== "Deleted Media"
      );
      this.setState({ medias: data });
    }

  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.commentMedias !== this.props.commentMedias) {
      this.setState({ commentMedias: this.props.commentMedias });
      if (!MiscFunctions.isUndefined(this.props.commentMedias)) {
        this.checkDeletedMedia(this.props.commentMedias);
      }
    }

    // if (this.props.comment && this.props.comment !== this.state.clickedComment) {
    //   if (!this.props.editCommentReply) {
    //     this.checkDeletedMedia(this.props.comment.medias);
    //   } else if (this.props.editCommentReply === true && !MiscFunctions.isUndefined(this.props.commnetForReply) && this.props.commnetForReply.id === this.props.comment.id) {
    //     this.checkDeletedMedia(this.props.comment.medias);
    //   }

    // }
  }

  componentDidMount() {
    if (this.props.commentUpdate === true) {
      this.setState({ medias: this.props.comment.medias });
    } else if (this.props.editCommentReply === true) {
      this.setState({ medias: this.props.clickedReply.medias });
    } else {
      this.setState({ medias: [] });
    }

    if (!MiscFunctions.isUndefined(this.props.clickedComment)) {
      this.setState({ medias: this.props.clickedComment.medias });
    }
  }

  handlePicturePreview = (status) => {
    this.setState({ filePreview: status });
  };

  render() {
    let counter = 0;
    return (
      !MiscFunctions.isNull(this.state.medias) &&
      !MiscFunctions.isUndefined(this.state.medias) && <div className="comment-files-accordion">
        <div
          className="comment-files-accordion-header"
          onClick={this.toggleAccordion}
          data-test="comment-files-open-test"
        >
          <span className="comment-files-title">
            All Files
            <span className="file-count">
              (
              {!MiscFunctions.isNull(this.state.medias) &&
                !MiscFunctions.isUndefined(this.state.medias)
                ? this.state.medias.length
                : "0"}{" "}
              files)
            </span>
          </span>
          <span
            className={`drop-down-icon  ${this.state.isOpen ? "open" : ""}`}
          ></span>
        </div>
        <div
          className={`comment-files-accordion-content ${this.state.isOpen ? "open" : ""
            }`}
          style={{
            maxHeight: this.state.isOpen ? "500px" : "0px",
            opacity: this.state.isOpen ? 1 : 0,
          }}
        >
          {this.state.medias !== null && this.state.medias !== undefined && (
            <ul
              className={`${this.state.isOpen ? "open" : ""}`}
              style={{ zIndex: 100 }}
            >
              {this.state.medias.map((file, i) => {
                let k = counter;
                let position = "bottom";
                if (this.props.checkCommentIsReply || this.props.editCommentReply) {
                  k = counter++;
                  position = "top";
                } else {
                  k = counter--;
                  position = "bottom";
                }
                return (
                  <li
                    key={file.id}
                    onClick={() => {
                      if (!this.props.commentEdit) {
                        this.props.setFileIndex(i);
                      }
                    }}
                    style={{ zIndex: k }}
                  >
                    <span className="comment-file-details">
                      {file.type === "picture"
                        ? (<>
                          <span
                            className="comment-file-thumb"
                            style={{
                              // backgroundImage: `url(${file.thumbnailUrl})`,
                              backgroundImage: `url(${file.url})`,
                            }}
                            onMouseOver={() => {
                              this.handlePicturePreview(true);
                              this.setState({ fileUrl: file.url });
                            }}
                            onMouseOut={() => this.handlePicturePreview(false)}
                            onClick={() => {
                              if (this.props.commentEdit !== true) {
                                store.dispatch(setClickedReply(this.props.clickedComment))
                                this.props.setMiniSlider(true)
                              }
                            }}></span>
                        </>) : file.type === "video"
                          ? (
                            <FiFilm
                              className="uploaded_file_video_icon"
                              onClick={() => {
                                if (this.props.commentEdit !== true) {
                                  store.dispatch(setClickedReply(this.props.clickedComment))
                                  this.props.setMiniSlider(true)
                                }
                              }}
                            />
                          ) : file.type === "audio" ? (
                            <FiHeadphones
                              className="uploaded_file_file_icon"
                              onClick={() => {
                                if (this.props.commentEdit !== true) {
                                  store.dispatch(setClickedReply(this.props.clickedComment))
                                  this.props.setMiniSlider(true)
                                }
                              }}
                            />
                          ) : (
                            <>
                              <FiFile
                                className="uploaded_file_file_icon"
                                onClick={() => {
                                  if (this.props.commentEdit !== true) {
                                    store.dispatch(setClickedReply(this.props.clickedComment))
                                    this.props.setMiniSlider(true)
                                  }
                                }}
                              />

                              {/* <Box>
                            <Popover
                              isLazy
                              trigger="hover"
                              strategy={"fixed"}
                              placement={position}
                              flip={false}
                            >
                              <PopoverTrigger>
                                <span
                                  className="comment-file-thumb"
                                  style={{
                                    backgroundImage: `url(${file.url})`,
                                  }}
                                  onClick={() =>
                                    this.props.commentEdit !== true
                                      ? this.props.setMiniSlider(true)
                                      : ""
                                  }
                                ></span>
                              </PopoverTrigger>
                              <PopoverContent
                                w="180px"
                                h="100px"
                                p={0}
                                m={0}
                                bg={"transparent"}
                              >
                                <PopoverBody p={0} borderRightRadius="5">
                                  <img
                                    src={`${file.url}`}
                                    style={{ borderRadius: "5px" }}
                                    alt=""
                                  />
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Box> */}
                            </>
                          )}
                      <span className="comment-file-name" onClick={() => {
                        if (this.props.commentEdit !== true) {
                          store.dispatch(clearClickedReply())
                          store.dispatch(setClickedReply(this.props.clickedComment))
                          this.props.setMiniSlider(true)
                        }
                      }}>
                        {!MiscFunctions.isNull(file.filename) ?
                          file.filename :
                          file.description.split("/").length > 1
                            ? file.description.split("/")[1]
                            : file.description}
                      </span>
                      <span className="comment-file-size"></span>
                    </span>
                    {this.props.commentEdit === true ||
                      this.props.commentReply === true ? (
                      <div className="func_for_uploaded_file">
                        <span className="comment_upload_check"></span>
                        <span
                          className="delete_uploaded_file"
                          onClick={() => {
                            this.deleteUploadedFile(
                              file.id,
                              this.state.medias,
                              i
                            );
                          }}
                        ></span>
                      </div>
                    ) : (
                      <FiDownload onClick={() => {
                        MiscFunctionsLocal.handleDownload(file);
                        this.setState({ downloadSuccess: file.id })
                        setInterval(() => {
                          this.setState({ downloadSuccess: null })
                        }, 1000)
                      }} style={{ width: "20px", height: "20px", color: this.state.downloadSuccess === file.id ? `#4BAAAC` : `#585858` }} />
                    )}
                  </li>
                );
              })}

              {this.state.filePreview && (
                <span
                  style={{
                    background: `url(${this.state.fileUrl}) no-repeat center, #f2f2f2`,
                  }}
                  className="content-file-preview"
                ></span>
              )}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  annotation: state.interactions?.annotation,
  comment: state.interactions?.comment ? new Comment(state.interactions.comment) : null, // Zugriff auf den Comment-State
  clickedReply: state.interactions?.clickedReply,
  interactions: state.interactions,
  commentReply: state.interactions.commentReply,
});

export default connect(mapStateToProps)(withTranslation()(CommentUploadedFile));
