import React from "react";
import { useState, useRef, useEffect } from "react";
import { Button, CircularProgress, Icon, Box, Flex } from "@chakra-ui/react";
import { FaPlay, FaPause, FaRegEdit } from "react-icons/fa";
import { Api, baseApiParams } from "my-api-client-package";
import { apiMedia } from '../../helpers/api'
import { useSelector } from 'react-redux';
import store from '../../store/store'; // Pfad entsprechend anpassen
import { setEditTaskSegment } from '../../store/segmentSlice';
import { Spinner } from "@chakra-ui/react";
import { FiRotateCcw } from "react-icons/fi";
const SpeakerAudio: React.FC = () => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [speakeraudioUrl, setSpeakeraudioUrl] = useState<any>(null);
    const [isCompleted, setIsCompleted] = useState(true);

    const audioRef = useRef<HTMLAudioElement | null>(null);
    const mediaApi = apiMedia;
    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });

    const selectedSegment = useSelector((state: any) => state.selectedSegment.currentSegment);
    const editSegment = useSelector((state: any) => state.selectedSegment.editSegment);

    useEffect(() => {
        const currentSegment = editSegment || selectedSegment;
        setSpeakeraudioUrl(null)
        if (currentSegment?.speakeraudios?.length > 0) {
            api.media.getMedia(currentSegment.speakeraudios[0].id, baseApiParams()).then((res) => {
                setSpeakeraudioUrl(res.data.url);
                console.log("res.data.url: ", res.data.url)
            });
        }
    }, [selectedSegment, editSegment]);

    useEffect(() => {
        const audio = new Audio(speakeraudioUrl);
        audioRef.current = audio;

        const updateProgress = () => {
            if (audioRef.current) {
                setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100 || 0);
            }
        };

        audio.addEventListener("timeupdate", updateProgress);
        audio.addEventListener("ended", () => setIsPlaying(false));

        return () => {
            audio.removeEventListener("timeupdate", updateProgress);
            audio.removeEventListener("ended", () => setIsPlaying(false));
        };
    }, [speakeraudioUrl]);

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const isCompletedRef = useRef(true);

    const updateAudio = async () => {
        const headers = baseApiParams()
        headers['headers']['server'] = process.env.REACT_APP_API_URL?.toLowerCase().includes('dev') || process.env.REACT_APP_API_URL?.toLowerCase().includes('localhost') ? 'DEV' : 'MAIN'
        setIsCompleted(false);
        const thread_id = (await (await mediaApi.audio.createCreate({
            processing: {
                server: process.env.REACT_APP_API_URL?.toLowerCase().includes('dev') || process.env.REACT_APP_API_URL?.toLowerCase().includes('localhost') ? 'DEV' : 'MAIN',
                audioproperties: {
                    gender: "male",
                    id: (editSegment || selectedSegment)?.id,
                    language: "English",
                    speakertext: (editSegment || selectedSegment)?.description
                }
            }
        }, headers)).json())['thread_id']
        console.log(thread_id)
        isCompletedRef.current = false;
        while (!isCompletedRef.current) {
            const state = (await (await apiMedia.checkStatus.checkStatusDetail(thread_id, headers)).json())['status'];

            if (state === 'Running') {
                await new Promise(resolve => setTimeout(resolve, 10000));
                console.log('running');
            } else if (state === 'Completed') {
                const result = (await (await apiMedia.getResult.getResultDetail(thread_id, headers)).json())['message'];
                const mediaID = result.media_object;
                const duration = result.duration;
                const currentSegment = editSegment || selectedSegment;
                store.dispatch(setEditTaskSegment({
                    ...currentSegment,
                    duration: duration,
                    speakeraudios: [{
                        ...currentSegment.speakeraudios?.[0],
                        id: mediaID
                    }]
                }));
                setIsCompleted(true);
                isCompletedRef.current = true;
            } else {

                console.log(`error: ${state}`);
                setIsCompleted(true);
                isCompletedRef.current = true;
            }
        }
    }

    return (
        <Flex direction="column" alignItems="center" justifyContent="space-between" flexDirection="row" gap={4} >
            <Box position="relative" display="inline-block">
                <CircularProgress value={progress} color="#1f6163" size="35px" thickness="8px" />
                <Button
                    onClick={togglePlayPause}
                    variant="ghost"
                    position="absolute"
                    top="20%"
                    left="20%"
                    transform="translate(-25%, -25%)"
                    backgroundColor="transparent"
                    borderRadius="50%"
                    color="#1f6163"
                    _hover={{ backgroundColor: "transparent" }}
                    _active={{ backgroundColor: "transparent" }}
                    _focus={{ backgroundColor: "transparent" }}
                    isDisabled={!speakeraudioUrl}
                >
                    <Icon as={isPlaying ? FaPause : FaPlay} boxSize={3} />
                </Button>
            </Box>
            <Button className="update-audio-button"
                onClick={updateAudio}
                variant="outline"
                colorScheme="teal"
                gap={2}
                size="sm"
            >
                {!isCompleted ? (
                    <Spinner size="sm" />
                ) : (
                    <Icon as={FiRotateCcw} boxSize={4} />
                )}
                Update Audio
            </Button>
        </Flex>
    );
};

export default SpeakerAudio;
