import React, { Component } from "react";
import CommentDisplayContainer from "./CommentDisplayContainer";
import CommentCreateContainer from "./CommentCreateContainer";
import { withTranslation } from "react-i18next";
import CommentAnchor from "./CommentAnchor";
import Swal from "sweetalert2";
import { MiscFunctions } from 'helper-functions-package';
import store from "../../store/store";
import { connect } from "react-redux";
import { clearClickedComment, clearMedias, setAnnotation, setChangesMade, setClickedComment, setClickedReply, setCommentCreate, setCommentReply, clearCommentReply } from "../../store/InteractionsSlice";

class CommentAnnotationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentBoxAnnotation: { left: 0, top: 0 },
      switchSide: null,
      commentAnnotationContainerWidth: null,
      commentBoxPositions: [],
      commentUpdate: false,
      commentForUpdate: null,
      commentLoad: false,
      commentForReply: null,
      editCommentReply: false,
      commentEdit: false,
      checkSettingPanel: true,
    };
    this.commentAnnotationContainerRef = React.createRef();
    this.isMobile = store.getState().isMobile;
  }

  toggleCommentContainer = () => {
    if ((store.getState().interactions.commentCreate === true || store.getState().interactions.commentReply === true || this.state.editCommentReply === true) && store.getState().interactions.changesMade === true) {
      Swal.fire({
        title: "Discard Comment?",
        text: "Your changes will not be saved.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4baaac",
        cancelButtonColor: "#d33",
        confirmButtonText: "Discard",
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(setCommentCreate(false));
          store.dispatch(setChangesMade(false));
          store.dispatch(setCommentReply(false));
          store.dispatch(clearClickedComment())
          store.dispatch(clearMedias());
        }
      })
    }
    else {
      if (MiscFunctions.isNull(document.getElementById("comment-setting-block-id"))) {
        this.setState({
          commentEdit: false,
          editCommentReply: false
        });
        store.dispatch(setChangesMade(false));
        store.dispatch(setCommentCreate(false));
        store.dispatch(setCommentReply(false));
      } else {
        this.setState({ checkSettingPanel: false })
      }
    }
  };

  handleSettingPanel = (status) => {
    this.setState({ checkSettingPanel: status })
  }

  placeCommentContainer(e) {
    if (this.props.addComment === false) return
    //By using e.target.closest('.comment-annotation-container'), 
    //we're ensuring that we always get the parent element with 
    //the class comment-annotation-container, regardless of the element that was actually clicked.

    //By using parent instead of e.target for calculating the left and top positions, 
    //we ensure that the positions are relative to the parent container    
    const parent = e.target.closest('.comment-annotation-container');

    if (e.target !== parent &&
      !e.target.classList.contains('comment-anchor-right') &&
      !e.target.classList.contains('comment-anchor-left') &&
      !e.target.classList.contains('comment-action-right') &&
      !e.target.classList.contains('comment-action-left')
    ) {
      return; // ignore the event
    }
    let left;
    let top;
    if (e.clientY > parent.offsetHeight || e.clientY === parent.offsetHeight) {
      top = (parent.offsetHeight - 10) / parent.offsetHeight * 100;
    } else {
      top = e.clientY / parent.offsetHeight * 100;
    }
    if (e.clientX > parent.offsetWidth || e.clientX === parent.offsetWidth) {
      left = (parent.offsetWidth - 10) / parent.offsetWidth * 100;
    } else {
      left = e.clientX / parent.offsetWidth * 100;
    }

    this.setState({ commentBoxPositions: [left / 100, top / 100] });

    store.dispatch(setAnnotation({ left: `${left}%`, top: `${top}%` }))
    this.setState({ commentForUpdate: null, commentUpdate: false })
    store.dispatch(setCommentCreate(true));
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.annotations !== this.props.annotations) {

      // this.setState({ propertyCommentAnnotationArray: this.props.propertyCommentAnnotationArray })
      const commetnAnn = this.props.annotations;

      if (commetnAnn && commetnAnn.length > 0) {
        commetnAnn.map((el, i) => {
          if (el.autoplay) {
            store.dispatch(setClickedComment(el))
          }
        })

      }
      this.setCommentLoad(false)
    } else if (prevProps.interactions !== this.props.interactions) {
      this.setCommentLoad(false)
    }
    if (this.props.infoCard === true) {
      this.toggleCommentContainer();
      this.props.setInfoCard(false)
    }
    if (this.props.clickPlayIconInSiderbar === true) {
      // this.setState({ commentCreate: this.props.commentCreate });
      this.props.setClickPlayIconInSiderbar(false);
    }
  }

  setCommentLoad = (status) => {
    this.state.commentForUpdate && this.setState({ commentLoad: status })
  }
  componentDidMount() {
    this.updateSwitchSide();
    window.addEventListener('resize', this.updateSwitchSide);
  }

  // Lifecycle method called when the component is unmounted
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSwitchSide);
  }

  // Method to update the switchSide state based on container width
  updateSwitchSide = () => {
    if (this.commentAnnotationContainerRef.current) {
      const width = this.commentAnnotationContainerRef.current.clientWidth;
      this.setState({
        switchSide: parseFloat(width) < 10175 ? 'right' : 'left',
        commentAnnotationContainerWidth: parseFloat(width)
      });
    }
  };

  closeCommentCreate = (status) => {
    if (status === 'save') store.dispatch(setCommentCreate(false))
    else this.toggleCommentContainer()
  }

  closeCommentReply = (status) => {
    if (status === 'save') store.dispatch(clearCommentReply())
    else this.toggleCommentContainer()
  }
  closeCommentReplyEdit = (status) => {
    if (status === 'save') this.setState({ editCommentReply: false, commentEdit: false })
    else this.toggleCommentContainer()
  }

  handlecommentUpdate = (commentObj, switchSide, left, top) => {
    console.log(commentObj);
    this.setState({
      commentUpdate: true,
      commentForUpdate: commentObj,
      commentBoxAnnotation: { left: `${left}`, top: `${top}` },
      switchSide: switchSide,
      commentBoxPositions: [parseFloat(left) / 100, parseFloat(top) / 100],
      editCommentReply: false
    })
    store.dispatch(setCommentCreate(true));
    store.dispatch(setCommentReply(false));
    // this.props.getClickedComment(null)
  }

  handleCommentReply = (commentObj, switchSide, left, top) => {
    this.setState({
      editCommentReply: false,
      commentForReply: commentObj,
      commentBoxAnnotation: { left: `${left}`, top: `${top}` },
      switchSide: switchSide,
      commentBoxPositions: [parseFloat(left) / 100, parseFloat(top) / 100]
    });
    store.dispatch(setCommentReply(true));
    let element;
    if (!MiscFunctions.isUndefined(document.getElementsByClassName("comment-reply-main-block")) && !MiscFunctions.isNull(document.getElementsByClassName("comment-reply-main-block"))) {
      element = document.getElementsByClassName("comment-reply-main-block");
      element[0].scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  handleEditCommentReply = (commentObj, switchSide, left, top, reply) => {
    store.dispatch(setClickedReply(commentObj))
    this.setState({
      editCommentReply: true,
      commentEdit: true,
      commentForReply: commentObj,
      commentBoxAnnotation: { left: `${left}`, top: `${top}` },
      switchSide: switchSide,
      commentBoxPositions: [parseFloat(left) / 100, parseFloat(top) / 100]
    })
  }

  render() {
    const { companyUsers, user, videoID, player, filterCategories, deleteComment, setDeleteComment } = this.props;
    const { switchSide, commentAnnotationContainerWidth, commentBoxPositions } = this.state;
    const clickedComment = this.props.clickedComment;
    const annotation = this.props.annotation;
    const propertyCommentAnnotationArray = this.props.annotations;
    const commentReply = store.getState().interactions.commentReply;
    return (
      <div
        data-test="comment-annotation-container-click-for-comment-create"
        className="comment-annotation-container"
        ref={this.commentAnnotationContainerRef}
        onClick={(e) => {
          if (!this.isMobile) {
            const parent = e.target.closest('.comment-annotation-container');
            if (e.target !== parent &&
              !e.target.classList.contains('comment-anchor-right') &&
              !e.target.classList.contains('comment-anchor-left') &&
              !e.target.classList.contains('comment-action-right') &&
              !e.target.classList.contains('comment-action-left')) {
              if (this.props.clickPlayIconInSiderbar === true) {
                this.props.setClickPlayIconInSiderbar(false);
              }
              return true
            }
            else if (clickedComment && (MiscFunctions.isUndefined(clickedComment) || MiscFunctions.isNull(clickedComment))) {
              if (this.props.clickPlayIconInSiderbar === true) {
                this.props.setClickPlayIconInSiderbar(false);
              }
              store.dispatch(clearClickedComment())
            }
            else {
              if (user && Object.keys(user).length > 0) {
                // this.toggleCommentContainer();
                if (store.getState().interactions.commentCreate === false && store.getState().interactions.commentReply === false && this.state.editCommentReply === false) {
                  store.dispatch(clearClickedComment())
                  store.dispatch(setCommentReply(false));
                  this.placeCommentContainer(e)
                } else {
                  this.closeCommentCreate()
                }
              }
            }
          }
        }}
      >
        {
          propertyCommentAnnotationArray.map((elem, i) => {
            // if (clickedComment !== null && clickedComment !== undefined) {
            //   return elem.id !== clickedComment.id && <CommentAnchor key={i} elem={elem} getClickedComment={getClickedComment} />
            // }
            return <CommentAnchor
              key={i}
              elem={elem}
              closeCommentCreate={this.closeCommentCreate} />
          })
        }
        {
          (annotation !== null && clickedComment !== null && clickedComment !== undefined && !store.getState().interactions.commentCreate) &&
          <CommentDisplayContainer
            key={clickedComment.id}
            companyUsers={companyUsers}
            deleteComment={deleteComment}
            setDeleteComment={setDeleteComment}
            deteleCommentId={this.props.deleteCommentId}
            switchSide={switchSide}
            commentAnnotationContainerWidth={commentAnnotationContainerWidth}
            user={user}
            setUpdateMediaFile={this.props.setUpdateMediaFile}
            updateMediaFile={this.props.updateMediaFile}
            handlecommentUpdate={this.handlecommentUpdate}
            handleCommentReply={this.handleCommentReply}
            handleEditCommentReply={this.handleEditCommentReply}
            commentLoad={this.state.commentLoad}
            commentForReply={this.state.commentForReply}
            closeCommentReply={this.closeCommentReply}
            closeCommentReplyEdit={this.closeCommentReplyEdit}
            setCommentLoad={this.setCommentLoad}
            setToastMessage={this.props.setToastMessage}
            commentUpdated={this.props.commentUpdated}
            setCommentUpdated={this.props.setCommentUpdated}
            editCommentReply={this.state.editCommentReply}
            commentEdit={this.state.commentEdit}
          />

        }
        {store.getState().interactions.commentCreate && !MiscFunctions.isNull(store.getState().interactions.annotation) &&
          <CommentCreateContainer
            setToastMessage={this.props.setToastMessage}
            commentAnnotationContainerWidth={commentAnnotationContainerWidth}
            user={user}
            switchSide={switchSide}
            companyUsers={companyUsers}
            videoID={videoID}
            commentBoxPositions={commentBoxPositions}
            player={player}
            filterCategories={filterCategories}
            closeCommentCreate={this.closeCommentCreate}
            closeCommentReply={this.closeCommentReply}
            commentUpdate={this.state.commentUpdate}
            commentForUpdate={this.state.commentForUpdate}
            setCommentLoad={this.setCommentLoad}
            setUpdateMediaFile={this.props.setUpdateMediaFile}
            updateMediaFile={this.props.updateMediaFile}
            commentForReply={this.state.commentForReply}
            checkSettingPanel={this.state.checkSettingPanel}
            handleSettingPanel={this.handleSettingPanel}
            editCommentReply={this.state.editCommentReply}
          />}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  comment: state.interactions?.comment ? new Comment(state.interactions.comment) : null,
  annotation: state.interactions?.annotation,
  annotations: state.interactions.annotations,
  commentReply: state.interactions.commentReply,
  clickedComment: state.interactions.comment,
});

export default connect(mapStateToProps)(withTranslation()(CommentAnnotationContainer));
