import React from "react";
import { Avatar, Box, IconButton, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Text, Image, Flex } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FiBell, FiHelpCircle } from "react-icons/fi";
import { AvatarMenu } from "./../AvatarMenu.tsx";
import { UserDto } from "my-api-client-package";
import logo from "../../../assets/img/logo_medimesh_with_icon.svg";
import LanguageSelector from "./LanguageSelector.tsx";

interface HeaderProps {
    userObj: UserDto | null;
}

export const Header: React.FC<HeaderProps> = ({ userObj }) => {
    return (
        <>
            <Flex
                width={{ base: "250px", md: "300px" }}
                height={{ base: "28px", md: "32px" }}
                gap={{ base: "5px", md: "10px" }} >
                <Image src={logo} alt="mediMESH" />
            </Flex>
            <InputGroup width={{ base: "190px", md: "440px" }}>
                <InputLeftElement pointerEvents='none'>
                    <SearchIcon color='#000000'
                        width={"20px"} height={"20px"}
                    />
                </InputLeftElement>
                <Input type='search' placeholder='Search' variant='filled' fontSize={"14px"} />
            </InputGroup>
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                <Flex display={{ base: "none", md: "flex" }}>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={<FiBell size={"24px"} color={"#000000"} />}
                            colorScheme="transparent"
                        />
                        <MenuList>
                            <MenuItem display={"flex"} alignItems={"center"} gap={"5px"}>
                                <Avatar size={"sm"} src={userObj?.avatar} />
                                <Text>Rafi tagged you in a post</Text>
                            </MenuItem>
                            <MenuItem display={"flex"} alignItems={"center"} gap={"5px"}>
                                <Avatar size={"sm"} src={userObj?.avatar} />
                                <Text>Enrico tagged you in a post</Text>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    <IconButton
                        aria-label='Options'
                        icon={<FiHelpCircle size={"24px"} color={"#000000"} />}
                        colorScheme="transparent"
                    />
                    <LanguageSelector />
                </Flex>

                {<AvatarMenu userObj={userObj} />}
            </Box>
        </>

    )
}