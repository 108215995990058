/**
 * @file PlayerSidebar.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description 
The PlayerSidebar component is a React functional component that represents a sidebar for a player interface. It displays various features and functionalities related to video comments and filtering. The component utilizes hooks such as useState and useEffect to manage state and perform side effects. It receives props from its parent component to customize its behavior and display. The component handles user interactions, such as filtering comments, sorting, and displaying notifications. It also renders a list of comments with their associated information, allowing users to play the video from specific comment timestamps and interact with the comments through various actions.
 *<br/>Breakdown of the code - {@tutorial PlayerSidebar}
 * @module PlayerSidebar
 *
 **/

 import { useEffect, useState } from "react";
 import MiscFunctionsLocal from "../../helpers/MiscFunctions";
 import { MiscFunctions } from 'helper-functions-package';
 import { Modal } from "flowbite-react";
 import { Icon_Click, Icon } from "../Misc/Icon";
 import Category from "../../services/CategoryServices";
 import Phase from "../../services/SegmentServices";
 import CommentCard from "../Misc/CommentCard";
 import SearchField from "../Misc/SearchField";
 import { useTranslation } from "react-i18next";
 import { useSelector } from "react-redux";
 import { Api, baseApiParams } from "my-api-client-package";
 import { Stack, Spinner } from "@chakra-ui/react";
 import  store  from "../../store/store";
 import { setVideoInteractions, deleteComment, setClickedComment } from "../../store/InteractionsSlice";
 import { selectVideoInteractions } from "../../selector/videoInteractionsSelector";

 const PlayerSidebar = (props) => {
   const player = props.player;
   const processSteps = props.processSteps;
 
   const [showHideSortBlock, setShowHideSortBlock] = useState(false);
   const [showHideFilterBlock, setShowHideFilterBlock] = useState(false);
   const [showHideNotifyBlock, setShowHideNotifyBlock] = useState(false);
 
   const [keywords, setKeywords] = useState("");
 
   const [companyUsers, setCompanyUsers] = useState(props.companyUsers);
   const [UserObject, setUserObject] = useState(props.UserObject);
 
   const [notificationBell, setNotificationBell] = useState(false);
   const [showVideoInfo, setShowVideoInfo] = useState(false);
   const [videoInfo, setVideoInfo] = useState("");
   const [checkCommentDeleted, setCheckCommentDeleted] = useState(false);
   const [checkCommentDeletedInfo, setCheckCommentDeletedInfo] = useState(false);
   const isMobile = store.getState().isMobile;
 
   const user = useSelector((state) => state.user);
   const videoInteractions = useSelector((state) => selectVideoInteractions(state));
 
   const api = new Api({
     baseUrl: process.env.REACT_APP_API_URL
   });

   const containerAnnotationRef = useSelector(state => state.refs.containerAnnotationRef);
   const isAdmin = UserObject.role === "Admin"; 

   useEffect(() => {
     setCompanyUsers(props.companyUsers);
   }, [props.companyUsers]);
 
   useEffect(() => {
     setUserObject(props.UserObject);
     checkNotification();
   }, [props.UserObject]);
 
   const checkNotification = async () => {
     if (!MiscFunctions.isNull(user?.preferences)) {
       if (user?.preferences && user?.preferences?.includes("notifications_comment_app")) {
         setNotificationBell(true);
       } else {
         setNotificationBell(false);
       }
     }
   };
   const [sidebarLoading, setSidebarLoading] = useState(true);
 
   // This is a temporary and quick implementation for removing the old categories. It should be removed once the old categories are deleted from the database.
   const targetCategoryTypes = ["ask_ai", "comment", "question", "notice"];
   const newFilterCategories = props.filterCategories.filter((item) =>
     targetCategoryTypes.includes(item.type)
   );
 
   let fitlerCategories = newFilterCategories.map((elem) => {
     let cat = new Category(elem);
     return cat;
   });
 
   // Add All-Category to the beginning of the array
   fitlerCategories.unshift(
     new Category({
       label: "All",
       icon: "all_category",
       id: "2368ece9-33de-42d0-8e3e-98eb5827ae02",
       status: true,
     })
   );
 
   const [fitlerCategoryList, setFitlerCategoryList] =
     useState(fitlerCategories);
 
   const getFilterItem = (name, state) => {
     let cat = fitlerCategoryList.map((item) => {
       let item_new = new Category(item);
       if (name === "All") {
         item_new.setStatus = false;
       } else if (item.label === "All") {
         item_new.setStatus = false;
       }
       if (name === item.label) {
         item_new.setStatus = state;
       }
 
       return item_new;
     });
     setFitlerCategoryList(cat);
   };
 
   const getFilterPerson = (ev, elem) => {
     let state = false;
     if (ev.target.className === "filter_person_item_container") {
       state = true;
       ev.target.className = "filter_person_item_container-hover";
     } else {
       ev.target.className = "filter_person_item_container";
     }
 
     let companyUsersCopy = companyUsers.map((obj) => {
       if (obj.id === elem) {
         obj.filterState = state;
       }
       return obj;
     });
     setCompanyUsers(companyUsersCopy);
   };
 
   const clickNotify = async (elem, player) => {
     let val = {};
     if (!MiscFunctions.isUndefined(videoInteractions.Elements)) {
       if (elem.video.id === props.workflow.getVideoID) {
         if (elem.parent.id === "null") {
           val = videoInteractions.Elements.find((obj) => obj.id === elem.comment.id);
         } else {
           val = videoInteractions.Elements.find((obj) => obj.id === elem.parent.id);
         }
         if (!MiscFunctions.isUndefined(val) && !MiscFunctions.isNull(val)) {
           store.dispatch(setClickedComment(val));
           clickComment(val, player);
           
           const dataNotify = await api.user.updateNotification(elem.id, baseApiParams());
           if (dataNotify.data !== false && !MiscFunctions.isUndefined(dataNotify.data)) {
             let UserObjectNew = await MiscFunctionsLocal.createUserObject(dataNotify.data);
             setUserObject(UserObjectNew);
           }
         } else {
           const dataNotify = await api.user.updateNotification(elem.id, baseApiParams());
           if (dataNotify.data !== false && !MiscFunctions.isUndefined(dataNotify.data)) {
             let UserObjectNew = await MiscFunctionsLocal.createUserObject(dataNotify.data);
 
             setUserObject(UserObjectNew);
           }
           setCheckCommentDeletedInfo("Comment already deleted.");
           setCheckCommentDeleted(true);
         }
       } else {
 
         const videoData = await api.video.getVideo(elem.video.id, baseApiParams());
         setVideoInfo(videoData.data.label);
         setShowVideoInfo(true);
       }
     }
   };
   // On-Click-Callback für the Play-Button in the comment card
   const clickComment = (val, player) => {
     MiscFunctionsLocal.gotoTimestamp(val.getTimeStampSeconds, player);
     var intervalID = setInterval(function () {
       let check = document.getElementById("ann_" + val.getID);
       if (check !== null) {
         clearInterval(intervalID);
         check.classList.add("FadeInAndOut");
         check.addEventListener("animationend", function () {
           check.classList.remove("FadeInAndOut");
         });
       }
     }, 300);
   };
 
   const updateTextTracks = () => {
 
     api.video.getVidIntById(props.mainVideoID, baseApiParams()).then((res) => {
       res = res.data.message;
       const playerRef = props.player
       let trackDel = playerRef.current.textTracks_.tracks_.find(
         (o) => o.id === "interactions"
       );
       playerRef.current.removeRemoteTextTrack(trackDel);
 
       const encoder = new TextEncoder();
       const data = encoder.encode(res);
 
       const track = playerRef.current.addRemoteTextTrack(
         {
           kind: "metadata",
           src: `data:text/vtt;base64,${window.btoa(
             new Uint8Array(data).reduce(
               (data, byte) => data + String.fromCharCode(byte),
               ""
             )
           )}`,
           srclang: "en",
           label: "English",
           id: "interactions",
         },
         true
       );
       track.addEventListener("load", function () {
         props.loadTextTracks("interactions", true);
       });
     });
   };
 
 
   const onClickOutsideListener = () => {
     if (showHideFilterBlock) {
       document.removeEventListener("click", onClickOutsideListener);
       setShowHideFilterBlock(!showHideFilterBlock);
     }
     if (showHideSortBlock) {
       document.removeEventListener("click", onClickOutsideListener);
       setShowHideSortBlock(!showHideSortBlock);
     }
     if (showHideNotifyBlock) {
       document.removeEventListener("click", onClickOutsideListener);
       setShowHideNotifyBlock(!showHideNotifyBlock);
     }
   };
 
   const getNotifyText = (elem) => {
     return MiscFunctionsLocal.formatCommentText(elem.content);
   };
 
   const { t } = useTranslation();
 
   useEffect(() => {
     if (videoInteractions && videoInteractions.Elements && videoInteractions.Elements.length === 0) {
       api.video.getVidIntByIdSlimJson(props.workflow.getVideoID, baseApiParams())
         .then((response) => {
           if (MiscFunctions.isUndefined(response)) {
             return;
           } else {
             response = JSON.parse(response.data.message);
             store.dispatch(setVideoInteractions(response));
             setSidebarLoading(false);
             updateTextTracks();
           }
         });
     } else {
       setSidebarLoading(false);
     }
   }, [])
 
   useEffect(() => {
     if (!MiscFunctions.isNull(props.deleteCommentId)) {
       store.dispatch(deleteComment(props.deleteCommentId));
     }
   }, [props.deleteCommentId])
 
   return (
     <Stack padding="0" className="player_sidebar_container_inner">
 
       {
         <SearchField
           stateHandler={(input) => {
             setKeywords(input);
           }}
           iconDirection={"right"}
         />
       }
 
       {
         <div className="filters_block flex flex-wrap comment_side_box_transition">
           <div className="selected_tags">
             {fitlerCategoryList.map((category, i) => {
               if (category.status) {
                 return (
                   <Icon
                     category={category}
                     key={i}
                     changeState={getFilterItem}
                   />
                 );
               } else {
                 return false;
               }
             })}
           </div>
           <div className="filter-icons">
             <div className="flex flex-row filter-section">
               <span
                 className={
                   (showHideFilterBlock && " icon_filter_category_select") +
                   " icon_filter_category"
                 }
                 onClick={() => {
                   setTimeout(() => {
                     setShowHideFilterBlock(!showHideFilterBlock);
                   }, 300);
                 }}
               ></span>
 
               {showHideFilterBlock && (
                 <div
                   className="block filter_block"
                   onMouseLeave={() => {
                     document.addEventListener("click", onClickOutsideListener);
                   }}
                   style={{
                     width: isMobile ? "230px" : "auto",
                   }}
                 >
                   <div className="flex flex-col text-sm">
                     <div className="flex py-1 px-2 rounded">
                       <div className="context_menu_header">
                         {t("player_sidebar.filter_category")}
                       </div>
                     </div>
                     <hr className="border-gray-300" />
 
                     {fitlerCategoryList.map((category, i) => {
                       return (
                         <Icon_Click
                           category={category}
                           changeState={getFilterItem}
                           keyName={i}
                           key={i}
                         />
                       );
                     })}
                   </div>
                 </div>
               )}
             </div>
 
             {/* Sort section */}
 
             {
               <div className="flex flex-row filter-section">
                 <span
                   className={
                     (showHideSortBlock && "icon_filter_person_select") +
                     " icon_filter_person"
                   }
                   onClick={() => {
                     setTimeout(() => {
                       setShowHideSortBlock(!showHideSortBlock);
                     }, 300);
                   }}
                 ></span>
                 {showHideSortBlock && (
                   <div
                     className="block sort_block"
                     onMouseLeave={() => {
                       document.addEventListener(
                         "click",
                         onClickOutsideListener
                       );
                     }}
                     style={{
                       width: isMobile ? "230px" : "200px",
                     }}
                   >
                     <div className="flex flex-col text-sm">
                       <div className="flex py-1 px-2 rounded">
                         <div className="context_menu_header">
                           {t("player_sidebar.filter_person")}
                         </div>
                       </div>
                       <hr className="border-gray-300" />
                       <div className="filter-box-list" style={{ display: "flex", flexDirection: "column", gap: "3px", width: isMobile ? "auto" : "195px" }}>
                         {companyUsers.map((elem, i) => {
                           return (
                             <div key={i} className="filter-box-list-item">
                               <div
                                 className={
                                   elem.filterState
                                     ? "filter_person_item_container-hover"
                                     : "filter_person_item_container"
                                 }
                                 style={{
                                   height: "30px",
                                   alignItems: "center",
                                   display: "flex",
                                 }}
                                 onClick={(ev) => {
                                   getFilterPerson(ev, elem.id);
                                 }}
                               >
                                 {elem.display}
                               </div>
                             </div>
                           );
                         })}
                       </div>
                     </div>
                   </div>
                 )}
               </div>
             }
 
             {/* Notify section */}
 
             {notificationBell ? (
               <div className="flex flex-row filter-section">
                 <span
                   className={
                     !MiscFunctions.isNull(UserObject) &&
                     !MiscFunctions.isNull(UserObject.notifications) &&
                       UserObject.notifications &&
                      UserObject.notifications.some(n => !n.read && n.video.id === props.workflow.getVideoID)
                       ? (showHideNotifyBlock &&
                         "icon_filter_notify_active_select") +
                       " icon_filter_notify_active"
                       : (showHideNotifyBlock && "icon_filter_notify_select") +
                       " icon_filter_notify"
                   }
                   onClick={() => {
                     setTimeout(() => {
                       setShowHideNotifyBlock(!showHideNotifyBlock);
                     }, 300);
                   }}
                 ></span>
                 {showHideNotifyBlock && (
                   <div
                     className="block notify_block"
                     onMouseLeave={() => {
                       document.addEventListener(
                         "click",
                         onClickOutsideListener
                       );
                     }}
 
                     style={{
                       width: isMobile ? "280px" : "300px",
                     }}
                   >
                     <div className="flex flex-col text-sm">
                       <div className="flex py-1 px-2 rounded">
                         <div className="context_menu_header">
                           {t("general.notifications")}
                         </div>
                       </div>
                       <hr className="border-gray-300" />
                       <div className="filter-box-list">
                         {!MiscFunctions.isNull(UserObject.notifications) && !UserObject.notifications.some(n => !n.read && n.video.id === props.workflow.getVideoID) ? (
                           <p className="text-center pt-3">
                             {t("messages.no_new_notifications")}.
                           </p>
                         ) : (
                          !MiscFunctions.isNull(UserObject.notifications) && UserObject.notifications.map((elem, i) => {
                             if (!elem.read && (elem.video.id === props.workflow.getVideoID)) {
                               return (
                                 <div key={i}>
                                   <div className="notify_box_message">
                                     {t("messages.you_were_mentioned") +
                                       elem.author +
                                       "in a" +
                                       elem.parent ===
                                       null
                                       ? " " + t("messages.comment")
                                       : " " + t("messages.reply_a_comment")}
                                   </div>
                                   <div className="flex flex-row" key={i}>
                                     <div className="notify_box_comment_text">
                                       {getNotifyText(elem)}
                                     </div>
                                     <div
                                       className="selected_tag_container flex"
                                       onClick={() => {
                                         clickNotify(elem, player);
                                       }}
                                     >
                                       {/* Play-Icon */}
                                       <span className="icon_play all-category-icon"></span>
                                     </div>
                                   </div>
                                 </div>
                               );
                             }
                           })
                         )}
                       </div>
                     </div>
                   </div>
                 )}
               </div>
             ) : null}
           </div>
         </div>
       }
       {/* Comment List section */}
 
       {
         <Stack
           className="all_comments_list"
          height={!isMobile ? (containerAnnotationRef - 170) : "auto"}
           display={"block"}
         >
           {sidebarLoading && (
             <Stack alignItems="center" justifyContent="center">
               <Spinner
                 thickness="4px"
                 speed="0.65s"
                 emptyColor="gray.200"
                 color="#4ba9aa"
                 size="xl"
               />
             </Stack>
           )}
           {MiscFunctions.isUndefined(videoInteractions.Elements) ||
             MiscFunctions.isNull(videoInteractions.Elements) || 
             videoInteractions.Elements.length < 1 ? (
             <p style={{ textAlign: "center" }}>
               {t("messages.no_result_found")}
             </p>
           ) : (
            videoInteractions && videoInteractions.Elements && videoInteractions.Elements.length > 0 && videoInteractions
               .getElementsOfKeywords(keywords)
               .Elements.map((comment, i) => {
                   // Search through the CategoryList to find the corresponding object and it's status
                   let match = false;
                   // ToDo change the filtering
                   if (
                    !MiscFunctions.isUndefined(comment) &&
                     !MiscFunctions.isNull(comment) &&
                     !MiscFunctions.isUndefined(
                       companyUsers.find((o) => {
                         return (
                           (comment.getUserName && comment.getUserName.indexOf(o.display) !== -1) &&
                           o.filterState
                         );
                       })
                     ) ||
                     MiscFunctions.isUndefined(
                       companyUsers.find((o) => {
                         return o.filterState;
                       })
                     )
                   ) {
                     if (fitlerCategoryList[0].status) {
                       // In the case, that "All-Categories" is picked
                       match = true;
                     } else {
                       comment.category.map((cat, j) => {
                         let obj = fitlerCategoryList.find((o) => o.id === cat.id);
                         if (obj && obj.status) {
                           match = true;
                         }
                       });
                     }
                   }
 
                   if (match) {
                     let processName = new Phase();
                     if (
                       !MiscFunctions.isUndefined(processSteps) &&
                       !MiscFunctions.isNull(processSteps)
                     ) {
                       processName.assignData = processSteps.Elements.find(
                         (elem) => {
                           if (
                             elem.getTimeStampSeconds <=
                             comment.getTimeStampSeconds &&
                             elem.getTimeStampSeconds + elem.getDuration >
                             comment.getTimeStampSeconds
                           ) {
                             return 1;
                           }
                         }
                       );
                     }
 
                     return (
                       <CommentCard
                         comment={comment}
                         player={player}
                         UserObject={UserObject}
                         process={processName}
                         key={i}
                         setClickPlayIconInSiderbar={props.setClickPlayIconInSiderbar}
                       />
                     );
                   }
                 }
              )
           )}
         </Stack>
       }
       <Modal
         show={showVideoInfo}
         size="md"
         onClose={() => setShowVideoInfo(false)}
         popup
       >
         <Modal.Header />
         <Modal.Body>
           <div className="text-center">
             <h3 className="videoNotificationInfo">
               {t("messages.video_notification_info") + videoInfo}
             </h3>
           </div>
         </Modal.Body>
       </Modal>
 
       <Modal
         show={checkCommentDeleted}
         size="md"
         onClose={() => setCheckCommentDeleted(false)}
         popup
       >
         <Modal.Header />
         <Modal.Body>
           <div className="text-center">
             <h3 className="videoNotificationInfo">
               {checkCommentDeletedInfo}
             </h3>
           </div>
         </Modal.Body>
       </Modal>
     </Stack>
   );
 };
 
 export default PlayerSidebar;