/**
 * @file VideoPlayer.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The VideoPlayer component is a React component that renders a video player with various features such as video playback, comments, and annotations. It interacts with an external video player library (VideoJS) to handle video playback and provides additional functionality on top of it.
 *<br/>Breakdown of the code - {@tutorial VideoPlayer}
 * @module VideoPlayer
 *
 **/

// External Packages Imports
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import "videojs-logo";
import "videojs-logo/dist/videojs-logo.css";

// Media Imports
import mediMESH_small_logo from "../../assets/img/workflow_lib/mediMESH-Icon.svg";
import userImage from "../../assets/img/normal_control_icon_user_account.svg";

// Services Imports
import Segments from "../../services/SegmentsServices";
import Segment from "../../services/SegmentServices";
import Video from "../../services/VideoService";
import Ressources from "../../services/RessourcesServices";

// Internal Packages Imports
import { MiscFunctions } from 'helper-functions-package';

// App Misc Imports
import ToastMessages from "../../helpers/ToastMessages";
import { getAuthAccessObject } from "../../utils/tokenStorage";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import PlayerControl from "./PlayerControl";

//Components imports
import VideoJS from "./VideoJS";
import QuickOverviewPopupsContainer from "../IntroductionPopups/QuickOverviewPopupsContainer";
import CommentAnnotationContainer from "../CommentContainer/CommentAnnotationContainer";

import TimelineGlobal from "./TimelineGlobal";
import { useSelector, useDispatch } from "react-redux";
import { Api, baseApiParams } from "my-api-client-package";
import store from "../../store/store";
import Ressource from "../../services/RessourceServices";
import Context from "../../services/ContextServices";
import PlayerSidebarWrapper from "./PlayerSidebarWrapper";
import WorkflowBarWrapper from "./WorkflowBarWrapper";
import { Box, IconButton, Stack, useDisclosure } from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import platform from "platform";
import { clearVideoInteractions, clearClickedComment, clearAnnotation, clearAnnotations } from "../../store/InteractionsSlice";
import { selectVideoAnnotations } from "../../selector/videoInteractionsSelector";
import { setTasksSegments, clearTasksSegments } from "../../store/segmentSlice";
import { clearContainerAnnotationRef } from "../../store/refSlice.ts";
import { clearVideo } from "../../store/videoSlice";
import { clearProgressBarPosition } from "../../store/ProgressBarPosition";
import { setShowInfoCard } from "../../store/taskPositionSlice";
const VideoPlayer = (props) => {

  // All useStates goes here
  const [getVideoURL, setVideoURL] = useState("");
  // State to save the Videscaptions
  const [passVideoCaption, setPassVideoCaption] = useState({});
  // State to capture if the video is currently loading
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  // State to manage the Spinner-symbol
  const [isVideoLoadingSpinner, setIsVideoLoadingSpinner] = useState(true);
  const [UpdateSegments, setUpdateSegments] = useState(true);
  // State to capture the annotations in the video-player
  const [propertyCommentAnnotationArray, setpropertyCommentAnnotationArray] =
    useState([]);
  // State to capture the selected categories inside the comment-creation-card
  const [filterCategories, setfilterCategories] = useState({});
  const [UserObject, setUserObject] = useState({});
  const [VideoObject, setVideoObject] = useState(null);
  const [CompanyUserObject, setCompanyUserObject] = useState([]);
  const [deleteComment, setDeleteComment] = useState(false);
  const [updateMediaFile, setUpdateMediaFile] = useState(false);
  const [commentUpdated, setCommentUpdated] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [ressourcesUpdate, setRessourcesUpdate] = useState(false);
  const [showIntroPopup, setShowIntroPoup] = useState(false);
  const [ressources, setRessources] = useState([]);
  const [persons, setPersons] = useState([]);
  const [personsGroup, setPersonsGroup] = useState([]);
  const [WorkflowPhases, setWorkflowPhases] = useState();
  const [zoomLevel, setZoomLevel] = useState(1);
  const [rangeSliderValue, setRangeSliderValue] = useState([0, 1]);
  const [clickPlayIconInSiderbar, setClickPlayIconInSiderbar] = useState(false);
  const [infoCard, setInfoCard] = useState(false);

  // All variables and Objects are goes here
  const navigate = useNavigate();
  const playerRef = React.useRef(null);
  const InteractionsRef = React.useRef(null);
  const containerAnnotationRef = React.useRef(null);
  const loginStatus = getAuthAccessObject();
  const mainVideoID = props.workflow.getVideoID;

  const user = useSelector((state) => state.user);
  const annotations = useSelector(selectVideoAnnotations);

  const clickedComment = useSelector((state) => state.interactions.comment);
  const annotationComment = useSelector((state) => state.interactions.annotation);

  const isMobile = useSelector(state => state.isMobile);
  const dispatch = useDispatch();

  const api = new Api({
    baseUrl: process.env.REACT_APP_API_URL
  });

  const workflowParameters = {
    person: persons,
    persongroup: personsGroup,
  };

  const SETTINGS = {
    PlayerSidebar: props.PlayerSidebar,
  };

  if (MiscFunctions.isNull(loginStatus)) {
    MiscFunctionsLocal.logout()
  };


  useEffect(() => {
    const osName = platform.os.family.toLowerCase();
    const isMobile = (
      osName === "ios" || osName === "android" ||
      osName === "windows phone" || osName === "blackberry" ||
      osName === "nokia" || osName === "samsung" ||
      osName === "lg" || osName === "huawei" ||
      osName === "xiaomi");
    dispatch({ type: 'isMobile/setIsMobile', payload: isMobile });

    dispatch(clearVideo());
    dispatch(clearTasksSegments());
    dispatch(clearVideoInteractions());
    dispatch(clearContainerAnnotationRef());
    dispatch(clearClickedComment());
    dispatch(clearAnnotation());
    dispatch(clearAnnotations());
    dispatch(clearProgressBarPosition());

  }, []);

  useEffect(() => {
    if (props.patientVideo === false) {
      api.video.getVideo(mainVideoID, baseApiParams()).then((res) => {
        const result = res.data;
        setVideoURL(result.media);
        dispatch({ type: 'video/setVideo', payload: result })
        setVideoObject(new Video(result));
      })
        .finally(() => {
          if (isVideoLoadingSpinner) {
            setIsVideoLoading(true);
            setIsVideoLoadingSpinner(false);
          }
        });

    } else {
      setIsVideoLoading(true);
    }
  }, [getVideoURL, ressourcesUpdate]);

  useEffect(() => {
    if (
      !MiscFunctions.isNull(VideoObject) ||
      !MiscFunctions.isNull(mainVideoID)
    ) {
      api.video.getWorkflowRessources(mainVideoID, baseApiParams()).then((res) => {
        const ressources = res.data;
        setRessources(ressources);
        const Resources = new Ressources(ressources);
        let persons = Resources.getPersons;
        let personsGroup = Resources.getPersonGroup;
        setPersons(persons);
        setPersonsGroup(personsGroup);

      })
    }
  }, [VideoObject]);

  useEffect(() => {
    setfilterCategories(props.workflow.getCategories);

    const videoID = mainVideoID;

    try {
      api.video.getVidSegsJsonById(videoID, "PhaseDto", baseApiParams()).then((res) => {
        const response = res.data;
        if (MiscFunctions.isUndefined(response)) return;
        if (!MiscFunctions.isNull(response.length)) {
          let phasesSegments = new Segments([]);
          response.forEach((response, i) => {
            let segmentPhase = new Segment();
            segmentPhase.assignData = response;
            segmentPhase.setType = "PhaseDto";
            phasesSegments.addSegment = segmentPhase;
          });
          setWorkflowPhases(phasesSegments);
        } else {
          setWorkflowPhases(new Segments([]));
        }
      })
    } catch (error) {
      console.error("Error getting phase segments:", error);
      setWorkflowPhases(new Segments([]));
    }

    try {
      api.video.getVidSegsJsonById(videoID, "TaskDto", baseApiParams()).then((res) => {
        const responses = res.data;
        if (MiscFunctions.isUndefined(responses)) return;
        if (!MiscFunctions.isNull(responses.length)) {
          let tasksSegments = new Segments([]);
          responses.forEach((response, i) => {
            let segmentTasks = new Segment();
            segmentTasks.assignData = response;
            segmentTasks.setType = "TaskDto";
            tasksSegments.addSegment = segmentTasks;
          });
          dispatch(setTasksSegments(responses));
        } else {
          dispatch(clearTasksSegments());
        }
      })
    } catch (error) {
      console.error("Error getting task segments:", error);
      dispatch(clearTasksSegments());
    }

  }, [UpdateSegments]);

  useEffect(() => {
    setZoomLevel(zoomLevel);
    setRangeSliderValue(rangeSliderValue);
  }, [zoomLevel, rangeSliderValue]);

  useEffect(() => {
    function handleResize() {
      if (containerAnnotationRef.current) {
        dispatch({ type: 'refs/setContainerAnnotationRef', payload: containerAnnotationRef.current.clientHeight + 100 })
        dispatch(setShowInfoCard(false));
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isVideoLoading, containerAnnotationRef.current]);

  useEffect(() => {
    if (toastMessage !== "") {
      ToastMessages.warnMessage('Fill the required fields (Title, Content and Category).');
      setToastMessage('')
    }
  }, [toastMessage]);

  useEffect(() => {
    console.log(annotations);
    if (!annotations || !playerRef.current || !playerRef.current.textTracks || !InteractionsRef.current) {
      return;
    }
    const tracks = playerRef.current.textTracks();
    const existingTrack = tracks.tracks_.find(t => t.id === "interactions");

    if (!existingTrack) {
      console.warn("Interactions track not found");
      return;
    }

    console.log("Track found:", existingTrack);
    console.log("Cues:", existingTrack.cues_);
    const cueList = existingTrack.cues_;

    annotations.forEach(annotation => {

      const existingCue = Array.from(cueList || []).find(cue => {
        const cueData = JSON.parse(cue.text);
        return cueData.id === annotation.id;
      });
      if (!existingCue) {
        const cue = new VTTCue(
          annotation.startTime,
          annotation.startTime + 3, // Default small duration if no endTime
          JSON.stringify({ "id": annotation.id, "tag": "comment" })
        );
        existingTrack.addCue(cue);
      }
    });
  }, [annotations]);

  // All defined methods goes here
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    let logoURL = "";
    if (props.patientVideo === false) logoURL = "/mediathek";
    player
      .logo({
        image: mediMESH_small_logo,
        width: 44,
        height: 42,
        fadeDelay: null,
        position: "top-left",
        url: logoURL,
      })
      .show();

    if (props.PlayerSidebar === true) {

      if (user !== null && !MiscFunctions.isUndefined(user)) {
        let User = MiscFunctionsLocal.createUserObject(user);
        setUserObject(User);

        if (!MiscFunctions.isNull(User.UserCompany)) {
          api.user.getCompanyUsers(baseApiParams()).then(async (User) => {
            User = User.data;
            if (
              !MiscFunctions.isUndefined(User) &&
              !MiscFunctions.isNull(User) &&
              User.length > 0
            ) {
              let userlist = [];
              User.map((elem, i) => {
                userlist.push({
                  id: elem.id.id,
                  display: elem.firstname + " " + elem.lastname,
                  avatar: !MiscFunctions.isNull(elem.avatar)
                    ? elem.avatar
                    : userImage,
                  type: "user",
                  filterState: false,
                });
              });
              setCompanyUserObject(userlist);
            }
          });
        } else {
          //Just Add the User
          let userlist = [
            {
              id: User.UserID,
              display: User.display,
              avatar: User.avatar,
              type: "user",
              filterState: false,
            },
          ];
          setCompanyUserObject(userlist);
        }
      }
      api.video.getVidIntById(mainVideoID, baseApiParams()).then(async (res) => {
        const result = res.data;
        const encoder = new TextEncoder();
        const data = encoder.encode(res);
        // Added an ID and the kind to the track
        const track = await playerRef.current.addRemoteTextTrack(
          {
            kind: "metadata",
            src: `data:text/vtt;base64,${window.btoa(
              new Uint8Array(data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`,
            srclang: "en",
            label: "English",
            id: "interactions",
          },
          true
        );
        InteractionsRef.current = track;
        // Note: Consider to use loadmetadata-callback of the player
        track.addEventListener("load", function () {
          loadTextTracks("interactions", false);
        });
      })
        .finally(() => {
          // api.video.getVidSegsByIdAll(mainVideoID, baseApiParams()).then(async (res) => {
          //   // Added an ID and the kind to the track
          //   const encoder = new TextEncoder();
          //   const data = encoder.encode(res);
          //   await player.addRemoteTextTrack(
          //     {
          //       kind: "metadata",
          //       src: `data:text/vtt;base64,${window.btoa(
          //         new Uint8Array(data).reduce(
          //           (data, byte) => data + String.fromCharCode(byte),
          //           ""
          //         )
          //       )}`,
          //       srclang: "en",
          //       label: "English",
          //       id: "process",
          //     },
          //     true
          //   );
          // })

          var seekBar = player.controlBar.progressControl.seekBar;
          seekBar.on("mousemove", function (event) {
            var mouseTime =
              player.duration() * seekBar.calculateDistance(event);
            const mouesDisplay =
              document.getElementsByClassName("vjs-mouse-display")[0];
            if (parseInt(mouseTime) === parseInt(player.currentTime())) {
              mouesDisplay.classList.add("vjs-mouse-display-hover");
            }
          });
        });
    }
    player.on("timeupdate", function (e) {
      let elem = {
        pos: player.currentTime() / player.duration(),
        time: (Math.round(player.currentTime() * 100) / 100)
          .toFixed(2)
          .toString(),
      };
      dispatch({ type: 'ProgressBarPosition/setProgressBarPosition', payload: elem });
    });

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
    player.ready(() => {
      dispatch({ type: 'ProgressBarPosition/setProgressBarPosition', payload: { pos: 0, time: "0.00" } });

      let getLogo = document.querySelector(".vjs-logo-content");

      if (!MiscFunctions.isNull(getLogo) && props.patientVideo === false && !props.redirectBy) {
        getLogo.addEventListener("click", function (event) {
          event.preventDefault();
          dispatch(clearVideoInteractions());
          dispatch(clearClickedComment());
          dispatch(clearAnnotation());
          navigate("/mediathek");
        });
      }
    });
    player.on("fullscreenchange", () => {
      if (this.player.isFullscreen()) {
        this.player.exitFullscreen();
      }
    });
    player.on('enterpictureinpicture', function () {
      console.log("enterpictureinpicture");
    });

    player.on('leavepictureinpicture', function () {
      console.log("leavepictureinpicture");
    });

    player.on('ratechange', function (e) {
      dispatch(clearClickedComment())
    });
  };

  function loadTextTracks(tag, init) {
    const cueList = playerRef.current
      .textTracks()
      .tracks_.find((o) => o.id === tag).cues_;
    console.log(cueList);
    // Added callback for the Cue-Events
    let track = playerRef.current
      .textTracks()
      .tracks_.find((o) => o.id === tag);
    let actCue = track.activeCues;

    track.oncuechange = () => {
      PlayerControl.loadCues(actCue, user).then((resp) => {
        setpropertyCommentAnnotationArray(resp)
      })
      if (store.getState().interactions.annotations && store.getState().interactions.annotations.length > 0) {
        store.getState().interactions.annotations.forEach((annotation) => {
          if (
            annotation.id !== undefined &&
            annotation.commentSettings !== null &&
            annotation.commentSettings.stopWhenReached === true
          ) {
            PlayerControl.handleSlowPause(playerRef)
          }
        });
      }
    };

    let allTextTracks = [];
    cueList.forEach((el, i) => {
      let texttrack = JSON.parse(el.text);
      allTextTracks.push(texttrack);
    });

    PlayerControl.loadCues(actCue, user).then((resp) => {
      setpropertyCommentAnnotationArray(resp)
    })

    setPassVideoCaption(allTextTracks);
  };

  const ToggleUpdate = () => {
    setUpdateSegments(!UpdateSegments);
  };

  function handleCloseIntroPopup(state) {
    setShowIntroPoup(state);
  };

  function handleOpenIntroPopup() {
    setShowIntroPoup(true);
  };

  const handlingZoomScroll = (newZoomLevel, rangeValue) => {
    if (MiscFunctions.isNull(newZoomLevel)) {
      setRangeSliderValue(rangeValue);
    } else {
      setZoomLevel(newZoomLevel);
      setRangeSliderValue(rangeValue);
    }
  };

  const [context, setContext] = useState(null)
  useEffect(() => {
    const fetchContext = async () => {
      const videoState = store.getState().video;
      if (videoState) {
        const video = new Video(videoState);
        if (!MiscFunctions.isNull(video.contexts)) {
          try {
            const contextId = video.contexts[video.contexts.length - 1].id;
            const contextData = await api.context.getContext(contextId, baseApiParams());
            setContext(contextData);
            handleOpenIntroPopup();
          } catch (error) {
            console.error("Error fetching context:", error);
          }
        }
      }
    };

    fetchContext();
  }, [VideoObject, store.getState().video])

  const getWorkflowObject = (newWorkFlowInfoObject, t, resourceDataMap, uniqueLabel) => {
    let video = new Video(store.getState().video);
    let newWorkFlowInfo = { ...newWorkFlowInfoObject };
    if (video && !MiscFunctions.isNull(context)) {
      let newContext = new Context(context);
      if (newContext.type === "Workflow-Introduction") {
        newWorkFlowInfo.WorkflowName = video.getLabel;
        newWorkFlowInfo.WorkflowId = video.getVideoID;

        newWorkFlowInfo.infos[0].info = [
          {
            label: t('quick_overview_popups.abstract'),
            description: newContext.getValueByString("abstract"),
          },
          {
            label: t('quick_overview_popups.introduction'),
            description: newContext.getValueByString("intro"),
          },
          {
            label: t('quick_overview_popups.indication'),
            description: newContext.getValueByString("indication"),
          },
          {
            label: t('quick_overview_popups.contraindication'),
            description: newContext.getValueByString("contraindication"),
          },
          {
            label: t('quick_overview_popups.preoperative_preparation'),
            description: newContext.getValueByString("preparation"),
          },
          {
            label: t('quick_overview_popups.postoperative_care'),
            description: newContext.getValueByString("care"),
          },
        ];
      } else if (newContext.type === "Workflow-Overview") {
        newWorkFlowInfo.infos[1].info = [
          {
            label: t('quick_overview_popups.workflow_phases'),
            description: newContext.getValueByString("phases"),
          },
          {
            label: t('quick_overview_popups.main_tasks'),
            description: newContext.getValueByString("tasks"),
          },
        ];
      } else if (newContext.type === "Questionary-Introduction") {
        newWorkFlowInfo.WorkflowName = video.getLabel;
        newWorkFlowInfo.WorkflowId = video.getVideoID;

        newWorkFlowInfo.infos[2].info = [
          {
            label: t('quick_overview_popups.abstract'),
            description: newContext.getValueByString("abstract"),
          },
        ];
      }
      newWorkFlowInfo.resources = uniqueLabel(newWorkFlowInfo.resources);


      if (ressources.length > 0 && newContext.type !== "Questionary-Introduction") {
        newWorkFlowInfo.resources = ressources.map((ressource) => {
          let resource = new Ressource(ressource);

          if (resource.getReferenceType === "PersonDto") {
            return {
              id: resource.getID,
              type: resource.getReference.persongroup.label,
              label: resource.getLabel,
              description: resource.getDescription,
              picture: resource.getPicture,
            };
          } else if (resource.getReferenceType === "RoomDto") {
            return resourceDataMap(resource, "Rooms");
          } else if (
            resource.getReferenceType === "ToolDto" &&
            resource.getReference.category === "tool"
          ) {
            return resourceDataMap(resource, "Tools");
          } else if (
            resource.getReferenceType === "ToolDto" &&
            resource.getReference.category === "equipment"
          ) {
            return resourceDataMap(resource, "Equipment");
          } else if (
            resource.getReferenceType === "ToolDto" &&
            resource.getReference.category === "pharmaceutical"
          ) {
            return resourceDataMap(resource, "Pharmaceuticals");
          }
        });
      }

      if (newContext.type !== "Questionary-Introduction") {
        const personsGroups = personsGroup.map((item) => item.label);
        newWorkFlowInfo.rooms_equipmentGroups = ["rooms", "equipment"];
        newWorkFlowInfo.tool_pharmaGroups = ["tools", "pharmaceuticals"];
        newWorkFlowInfo.personGroups = personsGroups.length > 0 ? personsGroups : ["default_person_group"];
      }

      return newWorkFlowInfo;
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  const containerAnnotRef = useSelector(state => state.refs.containerAnnotationRef);
  return (
    <>
      <ToastContainer />
      {!MiscFunctions.isUndefined(props.workflow) &&
        !MiscFunctions.isNull(store.getState().video) &&
        !MiscFunctions.isUndefined(store.getState().video) &&
        showIntroPopup && (
          <QuickOverviewPopupsContainer
            workflow={props.workflow}
            showIntroPopup={showIntroPopup}
            handleOpenIntroPopup={handleOpenIntroPopup}
            handleCloseIntroPopup={handleCloseIntroPopup}
            ressources={ressources}
            personsGroup={personsGroup}
            getWorkflowObject={getWorkflowObject}
          />
        )}

      {isVideoLoading && (

        <div className="main_player_block flex-wrap">
          <div className="player_slider_blocks" id="video_block"
            style={{ zIndex: `${!MiscFunctions.isNull(clickedComment) || !MiscFunctions.isNull(annotationComment) ? 8 : 6}` }}>
            <div className="video_player_block">
              <div
                className="container_annotation"
                ref={containerAnnotationRef}
              >
                <CommentAnnotationContainer
                  setToastMessage={setToastMessage}
                  propertyCommentAnnotationArray={
                    propertyCommentAnnotationArray
                  }
                  companyUsers={CompanyUserObject}
                  user={UserObject}
                  deleteComment={deleteComment}
                  setDeleteComment={setDeleteComment}
                  videoID={mainVideoID}
                  player={playerRef}
                  filterCategories={props.workflow.getCategories}
                  setUpdateMediaFile={setUpdateMediaFile}
                  updateMediaFile={updateMediaFile}
                  commentUpdated={commentUpdated}
                  setCommentUpdated={setCommentUpdated}
                  clickPlayIconInSiderbar={clickPlayIconInSiderbar}
                  setClickPlayIconInSiderbar={setClickPlayIconInSiderbar}
                  infoCard={infoCard}
                  setInfoCard={setInfoCard}
                />
                <VideoJS options={PlayerControl.videoJsOptions(props, getVideoURL)} onReady={handlePlayerReady} />
              </div>
              {/* {isMobile && ( */}
              {(
                <IconButton
                  icon={<ChevronUpIcon />}
                  onClick={() => {
                    onOpen();
                  }}
                  aria-label="Open Workflow Bar"
                  position="absolute"
                  bottom="10px"
                  right="15px"
                  backgroundColor={"#666666"}
                  top={containerAnnotRef - 16}
                  zIndex="10"
                  width="fit-content"
                  height="fit-content"
                  borderRadius="3px 3px 0 0"
                  color="#fff"
                  _hover={{
                    backgroundColor: "#666666d6",
                  }}
                  className="workflow_bar_swimlane_button"

                />
              )}
              {!MiscFunctions.isNull(playerRef.current) && !isMobile && (
                <Box className="timeline-global-wrapper" display={{ base: "none", md: "block" }} position={"relative"} top={{ base: "0", md: "40px" }}>
                  <TimelineGlobal
                    player={playerRef}
                    handlingZoomScroll={handlingZoomScroll}
                    zoomLevel={zoomLevel}
                    rangeSliderValue={rangeSliderValue}
                  />
                </Box>
              )}
            </div>
            <Stack padding="0" className="player_sidebar_container_wrapper" maxWidth={!isMobile ? "35%" : "auto"} minWidth={!isMobile ? "35%" : "auto"}>
              <PlayerSidebarWrapper
                SETTINGS={SETTINGS}
                setClickPlayIconInSiderbar={setClickPlayIconInSiderbar}
                UserObject={UserObject}
                CompanyUserObject={CompanyUserObject}
                playerRef={playerRef}
                filterCategories={filterCategories}
                workflow={props.workflow}
                WorkflowPhases={WorkflowPhases}
                deleteComment={deleteComment}
                ToggleUpdate={() => {
                  ToggleUpdate();
                }}
                mainVideoID={mainVideoID}
                loadTextTracks={loadTextTracks}
                setRessourcesUpdate={setRessourcesUpdate}
                ressourcesUpdate={ressourcesUpdate}
              />
            </Stack>
          </div>
          <div
            className="container_process flex-col"
            onClick={() => {
              dispatch(clearClickedComment());
              dispatch(clearAnnotation());
            }}
          >
            {!MiscFunctions.isNull(playerRef.current) && (
              <div>
                <WorkflowBarWrapper
                  workflowParameters={workflowParameters}
                  WorkflowPhases={WorkflowPhases}
                  playerRef={playerRef}
                  showIntroPopup={showIntroPopup}
                  handleOpenIntroPopup={handleOpenIntroPopup}
                  handlingZoomScroll={handlingZoomScroll}
                  mainVideoID={mainVideoID}
                  zoomLevel={zoomLevel}
                  rangeSliderValue={rangeSliderValue}
                  setInfoCard={setInfoCard}
                  workflow={props.workflow}
                  isOpen={isOpen}
                  onOpen={onOpen}
                  onClose={onClose}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoPlayer;