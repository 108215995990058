import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PreferenceState {
  language: any;
  // Add more refs here as needed
}

const initialState: PreferenceState = {
  language: "Deutsch",
  // Initialize new refs with default values here
};

const preferenceSlice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<any>) => {
      state.language = action.payload;
    },
  },
});

// Export actions so components can update the ref values
export const { setLanguage } = preferenceSlice.actions;
export default preferenceSlice.reducer;
