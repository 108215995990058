import React from "react";
import InputField from "../Misc/InputField";
import PasswordChecklist from "react-password-checklist";
import { Modal, Spinner } from "flowbite-react";
import ToastMessages from "../../helpers/ToastMessages";
import { withTranslation } from "react-i18next";
import PasswordResetForm from "./PasswordResetForm";
import { Api, baseApiParams } from "my-api-client-package";
class ChangePassword extends React.Component {
  constructor(props) {
    super();
    this.state = {
      inputCurrentPassword: "",
      inputNewPassword: "",
      inputNewConfirmPassword: "",
      passwordValid: false,
      updatePassword: false,
      popupVisible: false,
      api: new Api({
        baseUrl: process.env.REACT_APP_API_URL
      })
    };
  }

  handleChange = (fieldName, value, error) => {
    this.setState({ [fieldName]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ updatePassword: true });
    // Handle form submission
    const newPassword = {
      oldpassword: this.state.inputCurrentPassword,
      newpassword: this.state.inputNewConfirmPassword,
    };
    this.state.api.user.savePassword(newPassword, baseApiParams())
    .then((res) => {
      this.setState({ updatePassword: false });
      if (JSON.stringify(res.data).toLowerCase().includes("forbidden")) {
        ToastMessages.erorrMessage(
          this.props.t('messages.verify_password')
        );
      } else {
        ToastMessages.successMessage(this.props.t('messages.password_updated'));
      }
    });
    this.setState({
      inputCurrentPassword: "",
      inputNewPassword: "",
      inputNewConfirmPassword: "",
      passwordValid: true,
    });
  };

  componentDidMount() { }
  // loopTheChecklist(svgIcon, className) {
  //     var list = document.querySelectorAll(`.${className} > .checklist-icon`);
  //     list.forEach((el) => {
  //         if (el) {
  //             el.outerHTML = svgIcon;
  //         } else {
  //             console.log('SVG element not found');
  //         }
  //     })
  // }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputNewPassword !== this.state.inputNewPassword) {
      this.setState({ inputNewPassword: this.state.inputNewPassword });
    }
    if (prevState.inputNewConfirmPassword !== this.state.inputNewConfirmPassword) {
      this.setState({ inputNewConfirmPassword: this.state.inputNewConfirmPassword });
    }
    if (prevState.inputCurrentPassword !== this.state.inputCurrentPassword) {
      this.setState({ inputCurrentPassword: this.state.inputCurrentPassword });
    }
    if (prevState.passwordValid !== this.state.passwordValid) {
      this.setState({ passwordValid: this.state.passwordValid });
    }

    // if (prevState.inputNewPassword !== this.state.inputNewPassword) {

    //     if (this.state.inputNewConfirmPassword !== null) {
    //         var invalid = `<svg class="setting-password-checklist-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M5.82093 7.67664L9.78573 11.6625C10.0177 11.8945 10.313 12 10.6292 12C10.9455 12 11.2407 11.8735 11.4727 11.6625C11.7047 11.4305 11.8102 11.1353 11.8102 10.819C11.8102 10.5028 11.6837 10.2075 11.4727 9.97554L7.48684 6.01036L11.4727 2.02446C11.7047 1.79247 11.8102 1.49723 11.8102 1.18096C11.8102 0.864684 11.6837 0.569447 11.4727 0.337464C11.2407 0.105473 10.9455 0 10.6292 0C10.313 0 10.0177 0.126514 9.78573 0.337464L5.82055 4.32336L2.02446 0.527268C1.79247 0.295277 1.49723 0.189804 1.18096 0.189804C0.864683 0.189804 0.569447 0.316318 0.337464 0.527268C0.105473 0.759259 0 1.0545 0 1.37077C0 1.68705 0.126514 1.98228 0.337464 2.21427L4.13355 6.01036L0.337464 9.80644C0.105473 10.0384 0 10.3337 0 10.6499C0 10.9662 0.126514 11.2615 0.337464 11.4934C0.759214 11.9152 1.5817 11.9152 2.00345 11.4934L5.82063 7.67627" fill="#D90018"/>
    //         </svg>`;
    //         var valid = `<svg class="setting-password-checklist-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M1 7L5.8 13L13 1" stroke="#19EEF2" stroke-width="2" style="fill:none!important" stroke-linecap="round" stroke-linejoin="round"/>
    //         </svg>`;

    //         this.loopTheChecklist(invalid, 'invalid')
    //         this.loopTheChecklist(valid, 'valid')

    //     }

    // }
  }

  handleClose = () => {
    this.setState({ popupVisible: false })
  }

  render() {
    return (
      <div className="settings-password-section">
        <div className="settings-password-block">
          <div className="settings-old-password-block">
            <div className="general-input-password-section">
              <InputField
                type="password"
                name="inputCurrentPassword"
                placeholder={this.props.t('setting_page.current_password')}
                value={this.state.inputCurrentPassword}
                onChange={this.handleChange}
                class={
                  this.state.inputCurrentPassword !== ""
                    ? "general-password-field-fill"
                    : null
                }
                border={false} //false or number
                borderRadius={false} //false or number
                boxShadow={false} //true or false
              />
            </div>
          </div>

          <div className="settings-new-password-block">
            <div className="general-input-password-section">
              <InputField
                type="password"
                name="inputNewPassword"
                placeholder={this.props.t('setting_page.new_password')}
                value={this.state.inputNewPassword}
                onChange={this.handleChange}
                class={
                  this.state.inputNewPassword !== ""
                    ? "general-password-field-fill"
                    : null
                }
                passwordValid={this.state.passwordValid}
                passwordValidityMsg={true}
                required={true}
                border={false} //false or number
                borderRadius={false} //false or number
                boxShadow={false} //true or false
              />
            </div>
            <div className="general-input-password-section">
              <InputField
                type="password"
                name="inputNewConfirmPassword"
                placeholder={this.props.t('setting_page.confirm_new_password')}
                value={this.state.inputNewConfirmPassword}
                onChange={this.handleChange}
                class={
                  this.state.inputNewConfirmPassword !== ""
                    ? "general-password-field-fill"
                    : null
                }
                border={false} //false or number
                borderRadius={false} //false or number
                boxShadow={false} //true or false
              />
            </div>
          </div>
          <div className="settings-buttons-block">
            <button
              className="save-settings-btn"
              disabled={
                !this.state.passwordValid ||
                  ((this.state.inputCurrentPassword === "") &
                    (this.state.inputNewPassword === "") &&
                    this.state.inputNewConfirmPassword === "")
                  ? true
                  : false
              }
              onClick={(e) => this.handleSubmit(e)}
            >
              {this.state.updatePassword && (
                <Spinner
                  color="success"
                  aria-label="updating password"
                  size="sm"
                />
              )}
              {this.props.t('general.save')}
            </button>
            <button
              className="settings-forgot-password-btn"
              onClick={(e) => this.setState({ popupVisible: true })}
            >
              {this.props.t('login_register_page.forgot_password')}
            </button>
          </div>
        </div>
        <div className="settings-password-strenght-block">
          {this.state.inputNewPassword !== "" && (
            <PasswordChecklist
              rules={[
                "minLength",
                "capital",
                "lowercase",
                "number",
                "specialChar",
                "match",
              ]}
              minLength={12}
              value={this.state.inputNewPassword}
              valueAgain={this.state.inputNewConfirmPassword}
              className="settings-password-check"
              messages={{
                minLength: this.props.t('messages.character_long', { len: 12 }),
                capital: this.props.t('messages.uppercase_letter'),
                lowercase: this.props.t('messages.lowercase_letters'),
                number: this.props.t('general.numbers'),
                specialChar: this.props.t('messages.special_characters'),
                match: this.props.t('messages.dont_match'),
              }}
              onChange={(isValid) => {
                if (isValid === true || isValid === false)
                  if (this.state.passwordValid !== isValid) {
                    this.setState({ passwordValid: isValid });
                  }
              }}
            />
          )}
        </div>
        {this.state.popupVisible && <Modal
          show={this.state.popupVisible}
          className="register_main_modal"
          onClick={() => this.setState({ popupVisible: false })}
          style={{ overflow: "inherit", flex: "none" }}
          onClose={() => {
            this.setState({ popupVisible: false })
          }}
        >
          <Modal.Body
            className="reset_password_popup_body"
            style={{ overflow: "inherit", flex: "none" }}
            onClick={(e) => e.stopPropagation()}
          >
            <PasswordResetForm handleClose={this.handleClose} />
          </Modal.Body>
        </Modal>}
      </div>
    );
  }
}

export default withTranslation()(ChangePassword);
