import React from 'react';
import { Button, ButtonProps, Spinner } from '@chakra-ui/react';
import { CheckIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useState, useEffect } from 'react';

interface ProgressButtonProps extends Omit<ButtonProps, 'onClick'> {
    onClick: () => Promise<void>;
    resetTrigger?: any; // Dependency to reset button state (e.g., form state)
    initialText?: string;
    runningText?: string;
    doneText?: string;
}

export const ProgressButton = ({
    onClick,
    resetTrigger,
    initialText = 'Start',
    runningText = 'Running',
    doneText = 'Done',
    ...props
}: ProgressButtonProps) => {
    const [buttonState, setButtonState] = useState<'ready' | 'running' | 'done'>('ready');

    useEffect(() => {
        // Reset the button state when the form or any external value changes
        setButtonState('ready');
    }, [resetTrigger]);

    const handleClick = async () => {
        setButtonState('running');
        try {
            await onClick();
            setButtonState('done');
        } catch (error) {
            console.error('Operation failed:', error);
            setButtonState('ready');
        }
    };

    const getButtonProps = () => {
        switch (buttonState) {
            case 'running':
                return { leftIcon: <Spinner size="sm" />, children: runningText, isDisabled: true };
            case 'done':
                return { leftIcon: <CheckIcon />, children: doneText, isDisabled: true };
            default:
                return { leftIcon: <ArrowForwardIcon />, children: initialText, isDisabled: false };
        }
    };

    return (
        <Button onClick={handleClick} {...getButtonProps()} pl={2} {...props} />
    );
};
