import { Api as mediaApi } from '../models/mediaApi-media';


export const apiMedia = new mediaApi({
    securityWorker: async (securityData) => {
        const requestParams = {
            headers: {
                "x-api-key": securityData as string,
            },
        };
        return requestParams;
    },
    baseUrl: process.env.REACT_APP_MEDIA_SERVER,
});
