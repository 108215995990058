/**
 *
 * @file CommmentServices.js
 * @created_date Friday, July 28, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This class is for handling of the Comment-Objects, it encapsulates various functions for handling and retrieving information related to single comment objects.
 * <br/>Example use age of code - {@tutorial CommentServices}
 * @module CommentServices
 *
 **/

import MiscFunctionsLocal from '../helpers/MiscFunctions';
import { MiscFunctions } from 'helper-functions-package';
/** Class for handling the Comment-Objects. */

class Comment {
  constructor(json) {
    Object.keys(json).map((key) => {
      this[key] = json[key];
    });
  }

  //
  /**
   * @description Setter-Function to assign the VideoID to the object.
   * @param {string} input - ID of the video.
   **/
  set setVideoID(input) {
    this.video = { id: input, tag: "video" };
  }

  //
  /**
   * @description Setter-Function to assign the Comment ID to the object.
   * @param {string} input - ID of the comment.
   **/
  set setCommentID(input) {
    this.id = { id: input, tag: "comment" };
  }

  //
  /**
   * @description Setter-Function to assign the UserID to the object.
   * @param {string} input - ID of the user.
   **/
  set setUserID(input) {
    this.user = {
      id: input,
      tag: "user",
    };
  }

  //
  /**
   * @description Setter-Function to assign the position to the object.
   * @param {Array} input - Array with the coordinates of the anntation.
   **/
  set setPosition(input) {
    this.position = input;
  }

  //
  /**
   * @description Setter-Function set all required values of the comment-object to be a reply.
   * @param {comment} input - Parent-Comment-Object.
   **/
  set reply(input) {
    this.previous = {
      id: input.id,
      tag: "comment",
    };
    //     //TODO: Adapt these function
    // this.parent = input.parent===null ? {"id": input.id, "tag": "comment"} : input.parent;
    this.parent = input.parent;
  }

  //
  /**
   * @description Setter-Function to set the visibility of the comment object.
   * @param {string} input - String with the visibility level.
   **/
  set setVisibility(input) {
    this.visibility = input;
  }

  //
  /**
   * @description Setter-Function to assign an array of Categories to the object.
   * @param {string} input - Array of categories.
   **/
  set categories(input) {
    // TODO: CategoryServices!
    this.category = input;
  }

  // Getter - Methods

  //
  /**
   * @description Getter-Function to retrieve the ID of the comment.
   * @return {string} ID of the comment
   **/
  get getID() {
    return this.id;
  }

  get getParent() {
    return this.parent.id;
  }

  //
  /**
   * @description Getter-Function to retrieve the title of the comment.
   * @return {string} title of the comment
   **/
  get getHeadline() {
    return MiscFunctionsLocal.formatText(this.headline);
  }

  //
  /**
   * @description Getter-Function to retrieve the ID of the comments creator.
   * @return {string} ID
   **/
  get getUserID() {
    let output = "Unknown";

    if (this.user.tag === "user") {
      output = !MiscFunctions.isNull(this.user.id) && !MiscFunctions.isUndefined(this.user.id) ? this.user.id : "Unknown";
    }

    return output;
  }

  //
  /**
   * @description Getter-Function to retrieve the name of the comments creator.
   * @return {string} Username
   **/
  get getUserName() {
    let output = "Unknown";

    if (this.user.tag === "user") {
      output = !MiscFunctions.isNull(this.user.user) && !MiscFunctions.isUndefined(this.user.user) ? this.user.user : "Unknown";
    }

    return output;
  }

  //
  /**
   * @description Getter-Function to retrieve the avatar of the comments creator.
   * @return {string} signed URL
   **/
  get getUserAvatar() {
    let output = "";

    if (this.user.tag === "user") {
      output = this.user.avatar;
    }

    return output;
  }

  //
  /**
   * @description Getter-Function to retrieve all categories of the comment.
   * @return {categories} Categories of the comment
   **/
  get getCategories() {
    return this.category;
  }

  //
  /**
   * @description Getter-Function to retrieve visibility of the comment.
   * @return {visibility} Visibility of the comment
   **/

  get getVisibility() {
    return this.visibility;
  }
  
  //
  /**
   * @description Getter-Function to retrieve the number of replies of the comment.
   * @return {number} Number of replies of the comment
   **/
  get getNumberOfReplies() {
    return this.numberOfChildren;
  }

  //
  /**
   * @description Getter-Function to retrieve a formatted String of the Creation-Date of the comment.
   * @return {String} Creation Date
   **/
  get getDateCreatedFormat() {
    return this.creationDate !== "null"
      ? MiscFunctions.Dates2CardFormat(this.creationDate)
      : "";
  }

  //
  /**
   * @description Getter-Function to retrieve a formatted String of the lastChanged-Date of the comment.
   * @return {String} LastChange-Date
   **/
  get getDateChangedFormat() {
    return this.lastChanged !== "null"
      ? MiscFunctions.Dates2CardFormat(this.lastChanged)
      : "";
  }

  get getIsDateChanged() {
    const createdDate = this.creationDate;
    const changedDate = this.lastChanged;
    return MiscFunctions.areDatesDifferent(createdDate, changedDate);
  }

  //
  /**
   * @description Getter-Function to retrieve the content of the comment.
   * @return {String} Content
   **/
  get getContent() {

    if (!MiscFunctions.isUndefined(localStorage.selected_language)) {
      let languageTag = JSON.parse(localStorage.selected_language).tag;
      switch (languageTag) {
        case 'en':
          return this.content !== "null" ? this.content.en : "";
        case 'de':
          return this.content !== "null" && !MiscFunctions.isNull(this.content.ger) ? this.content.ger : this.content.en;
        case 'ja':
          return this.content !== "null" && !MiscFunctions.isNull(this.content.jp) ? this.content.jp : this.content.en;
        default:
          return this.content !== "null" ? this.content.en : "";
      }
    } else {
      return this.content !== "null" ? this.content.en : "";
    }
  }

  //
  /**
   * @description Getter-Function to retrieve the audio content of the comment.
   * @return {String} Content
   **/
  get getContentAudio() {
    if (!MiscFunctions.isUndefined(localStorage.selected_language)) {
      let languageTag = JSON.parse(localStorage.selected_language).tag;
      switch (languageTag) {
        case 'en':
          return this.content !== "null" ? this.content.audioEn : "";
        case 'ger':
          return this.content !== "null" ? this.content.audioGer : "";
        case 'jp':
          return this.content !== "null" ? this.content.audioJp : "";
        default:
          return this.content !== "null" ? this.content.audioEn : "";
      }
    } else {
      return this.content !== "null" ? this.content.audioEn : "";
    }
  }


  //
  /**
   * @description Getter-Function to retrieve a the recorded audio.
   * @return {String} Creation Date
   **/
  get getRecordedAudio() {
    return this.recordedAudio !== null
      ? this.recordedAudio.audio
      : "";
  }
  //
  /**
   * @description Getter-Function to retrieve a the recorded audio text.
   * @return {String} Creation Date
   **/
  get getRecordedAudioText() {
    return this.recordedAudio.audioText !== "null"
      ? this.recordedAudio.audioText
      : "";
  }


  //
  /**
   * @description Getter-Function to retrieve the timestamp of the comment as string
   * @return {String} TimeStamp
   **/
  get getTimeStampString() {
    return this.timestamp;
  }

  //
  /**
   * @description Getter-Function to retrieve the timestamp of the comment in seconds
   * @return {Number} TimeStamp
   **/
  get getTimeStampSeconds() {
    return MiscFunctions.DateString2Seconds(this.getTimeStampString);
  }

  //
  /**
   * @description Getter-Function to retrieve the medias of the comment.
   * @return {Array} 
   **/
  get getMedias() {
    return this.medias;
  }

  //
  /**
   * @description Getter-Function to retrieve the setting of the comment.
   * @return {boolean} 
   **/
  get getAutoPlay() {
    return !MiscFunctions.isNull(this.commentSettings) ? this.commentSettings.autoplay : false;
  }

  //
  /**
   * @description Getter-Function to retrieve the setting of the comment.
   * @return {boolean} 
   **/
  get getAllowReplies() {
    return !MiscFunctions.isNull(this.commentSettings) ? this.commentSettings.allowReplies : false;
  }

  //
  /**
   * @description Getter-Function to retrieve the setting of the comment.
   * @return {boolean} 
   **/
  get getStopWhenReached() {
    return !MiscFunctions.isNull(this.commentSettings) ? this.commentSettings.stopWhenReached : false;
  }

  //
  /**
   * @description Getter-Function to retrieve the json required for Post-request
   * @return {String} JSON
   **/
  get json() {
    let comment = {
      id: !MiscFunctions.isUndefined(this.id) ? this.id : null,
      content: this.content,
      headline: this.headline,
      video: this.video,
      user: this.user,
      visibility: !MiscFunctions.isUndefined(this.visibility) ? this.visibility : "public",
      creationDate: this.creationDate,
      category: !MiscFunctions.isUndefined(this.category) ? this.category : [],
      medias: !MiscFunctions.isUndefined(this.medias) ? this.medias : [],
      recordedAudio: !MiscFunctions.isNull(this.recordedAudio) ? this.recordedAudio : null,
      commentSettings: !MiscFunctions.isUndefined(this.commentSettings) ? this.commentSettings : null
    };

    if (!MiscFunctions.isUndefined(this.position)) {
      comment["position"] = this.position;
    }
    if (!MiscFunctions.isUndefined(this.parent)) {
      comment["parent"] = this.parent;
    }
    if (!MiscFunctions.isUndefined(this.previous)) {
      comment["previous"] = this.previous;
    }

    return comment;
  }

  /**
   * @description Will return a boolean value indicating if the category (cat) was found inside the category stack of the comment.
   * @param {cat} category Category-Object
   * @return {boolean}
   */
  isCommentOfCategory(category) {
    //Checks if the values exists
    //console.log(category)
    return category.some((cat) => {
      return !MiscFunctions.isUndefined(this.category.find((o) => o.id === cat.id));
      //console.log(this.category.find((o) => o.id === cat.id))
    });
  }
}

export default Comment;
