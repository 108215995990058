// Redux Slice (workflowsSlice.js)
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workflows: [], // Initialer Zustand
    activeLibrary: null
};

const workflowlibrarySlice = createSlice({
    name: "workflows",
    initialState,
    reducers: {
        setWorkflowlibrary(state, action) {
            state.workflows = action.payload;
        },
        clearWorkflows(state) {
            state.workflows = []; // Workflows leeren
        },
        setActivelibrary(state, action) {
            state.activeLibrary = action.payload;
        },
        setWorkflows(state, action) {

            // Aktualisiere das Array der Workflows
            state.workflows = state.workflows.map((workflowlibrary) => {
                if (workflowlibrary.id && workflowlibrary.id.id === action.payload.acItem) {
                    return {
                    ...workflowlibrary,
                    workflows: action.payload.workflows, // Neue Werte anwenden
                    };
                }
                return workflowlibrary; // Unveränderte Workflows beibehalten
                });
            state.activeLibrary = state.workflows.filter(elem => elem.id.id === action.payload.acItem)[0];
            console.log(state.workflows)
        }
    },
});

export const { setWorkflowlibrary, clearWorkflows, setActivelibrary, setWorkflows} = workflowlibrarySlice.actions;
export default workflowlibrarySlice.reducer;
