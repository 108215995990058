import { createSlice } from '@reduxjs/toolkit';

interface SelectedTaskState {
    selectedTaskInfo: [];
}

const initialState: SelectedTaskState = {
    selectedTaskInfo: []
};

const selectedTaskSlice = createSlice({
    name: 'selectedTask',
    initialState,
    reducers: {
        setSelectedTaskInfo: (state, action) => {
            state.selectedTaskInfo = action.payload;
        },
        clearSelectedTask: (state) => {
            state.selectedTaskInfo = [];
        }
    }
});

export const { setSelectedTaskInfo, clearSelectedTask } = selectedTaskSlice.actions;
export default selectedTaskSlice.reducer; 