import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RefState {
    containerAnnotationRef: number;
    // Add more refs here as needed
}

const initialState: RefState = {
    containerAnnotationRef: 0,
    // Initialize new refs with default values here
};

const refSlice = createSlice({
  name: 'refs',
  initialState,
  reducers: {
    setContainerAnnotationRef: (state, action: PayloadAction<number>) => {
      state.containerAnnotationRef = action.payload;
    },
    clearContainerAnnotationRef: (state) => {
      state.containerAnnotationRef = 0;
    },
  },
});

// Export actions so components can update the ref values
export const { setContainerAnnotationRef, clearContainerAnnotationRef } = refSlice.actions;
export default refSlice.reducer;
