import { createSlice } from '@reduxjs/toolkit';

const commentSlice = createSlice({
    name: 'comment',
    initialState: null, // Initial comment value
    reducers: {
        setClickedComment: (state, action) => action.payload, // Update comment
        clearClickedComment: () => null, // Clear comment
    },
});

export const { setClickedComment, clearClickedComment } = commentSlice.actions;

export default commentSlice.reducer;


