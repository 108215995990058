import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ContentManagementState {
    toWorkflows: any[];
    fromWorkflows: any[];
    selectedFromWorkflow: any[];
    selectedToWorkflow: any[];
    selectedFromWorkflowLibrary: any;
    selectedToWorkflowLibrary: any;
}

const initialState: ContentManagementState = {
    toWorkflows: [],
    fromWorkflows: [],
    selectedFromWorkflow: [],
    selectedToWorkflow: [],
    selectedFromWorkflowLibrary: null,
    selectedToWorkflowLibrary: null
};

const contentManagementSlice = createSlice({
    name: 'contentManagement',
    initialState,
    reducers: {
        setToWorkflows: (state, action: PayloadAction<any[]>) => {
            state.toWorkflows = action.payload;
        },
        setFromWorkflows: (state, action: PayloadAction<any[]>) => {
            state.fromWorkflows = action.payload;
        },
        setSelectedFromWorkflowLibrary: (state, action: PayloadAction<any>) => {
            state.selectedFromWorkflowLibrary = action.payload;
        },
        setSelectedToWorkflowLibrary: (state, action: PayloadAction<any>) => {
            state.selectedToWorkflowLibrary = action.payload;
        },
        moveToRight: (state, action: PayloadAction<any>) => {
            const itemToMove = action.payload;
            // Remove from source array
            state.fromWorkflows = state.fromWorkflows.filter(item => item.id !== itemToMove.id);
            // Only add if not already in target array
            if (!state.toWorkflows.some(item => item.id === itemToMove.id)) {
                state.toWorkflows = [...state.toWorkflows, itemToMove];
            }
        },
        moveToLeft: (state, action: PayloadAction<any>) => {
            const itemToMove = action.payload;
            // Remove from source array
            state.toWorkflows = state.toWorkflows.filter(item => item.id !== itemToMove.id);
            // Only add if not already in target array
            if (!state.fromWorkflows.some(item => item.id === itemToMove.id)) {
                state.fromWorkflows = [...state.fromWorkflows, itemToMove];
            }
        },
        setSelectedFromWorkflow: (state, action: PayloadAction<any[]>) => {
            state.selectedFromWorkflow = action.payload;
        },
        setSelectedToWorkflow: (state, action: PayloadAction<any[]>) => {
            state.selectedToWorkflow = action.payload;
        }
    },
});

export const {
    setToWorkflows,
    setFromWorkflows,
    setSelectedFromWorkflowLibrary,
    setSelectedToWorkflowLibrary,
    moveToRight,
    moveToLeft,
    setSelectedFromWorkflow,
    setSelectedToWorkflow
} = contentManagementSlice.actions;
export default contentManagementSlice.reducer;