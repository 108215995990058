import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ActiveMenuState {
    activeMediathekPage: string;
    activeMediathekWorkflowLibaray: {}
}

const initialState: ActiveMenuState = {
    activeMediathekPage: "null",
    activeMediathekWorkflowLibaray: {}
};

const activeMenuSlice = createSlice({
    name: 'mediathek',
    initialState,
    reducers: {
        setActiveMediathekPage: (state, action: PayloadAction<string>) => {
            state.activeMediathekPage = action.payload;
        },
        setActiveMediathekWorkflowLibaray: (state, action: PayloadAction<any>) => {
            state.activeMediathekWorkflowLibaray = action.payload.id;
        }
    },
});

// Export actions so components can update the active page
export const {
    setActiveMediathekPage,
    setActiveMediathekWorkflowLibaray
} = activeMenuSlice.actions;
export default activeMenuSlice.reducer;
