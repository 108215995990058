import { configureStore } from '@reduxjs/toolkit';
import tokenReducer from './tokenSlice';
import userReducer from './userSlice';
import videoReducer from './videoSlice';
import workflowlibraryReducer from './workflowlibrarySlice';
import commentReducer from './commentSlice';
import isMobileReducer from './isMobileSlice';
import interactionsReducer from './InteractionsSlice';
import segmentReducer from './segmentSlice';
import ProgressBarPositionReducer from './ProgressBarPosition';
import refReducer from './refSlice.ts';
import activeMenuReducer from './activeMenuSlice.ts';
import profileReducer from './profileSlice.ts';
import preferenceReducer from './preferenceSlice.ts';
import mainCommentActionPositionReducer from './InteractionsSlice';
import replyCommentActionBtnHeightReducer from './InteractionsSlice';
import languageReducer from "./languageSlice.ts"
import { stepPanelReducer } from 'medimesh-ui-kit';
import videoFormReducer from './videoFormSlice';
import contentManagementReducer from './contentManagementSlice';
import taskPositionReducer from './taskPositionSlice.ts';
import selectedTaskReducer from './selectedTaskSlice';
const loggerMiddleware = (store) => (next) => (action) => {
    console.log('Dispatching action:', action);
    const result = next(action);
    console.log(store.getState());
    return result;
};

const store = configureStore({
    reducer: {
        token: tokenReducer, // Top-level token state
        user: userReducer,   // Top-level user state
        video: videoReducer,
        videoForm: videoFormReducer,
        workflowlibrary: workflowlibraryReducer,
        comment: commentReducer,
        isMobile: isMobileReducer,
        interactions: interactionsReducer,
        selectedSegment: segmentReducer,
        ProgressBarPosition: ProgressBarPositionReducer,
        refs: refReducer,
        activeMenu: activeMenuReducer,
        profile: profileReducer,
        preference: preferenceReducer,
        mainCommentActionPosition: mainCommentActionPositionReducer,
        replyCommentActionBtnHeight: replyCommentActionBtnHeightReducer,
        language: languageReducer,
        steppanel: stepPanelReducer,
        contentManagement: contentManagementReducer,
        taskPosition: taskPositionReducer,
        selectedTask: selectedTaskReducer,
        // Add more reducers for other states if needed
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    'selectedSegment/setSegment',
                    'UPDATE_STEPPANEL_STATE'
                ],
                ignoredPaths: [
                    'selectedSegment',
                    'steppanel.files',
                    'steppanel.medias'
                ],
            },
        }).concat(loggerMiddleware),
});

export default store;