import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TaskPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface TaskPositionState {
  position: TaskPosition | null;
  showInfoCard: boolean;
}

const initialState: TaskPositionState = {
  position: null,
  showInfoCard: false
};

const taskPositionSlice = createSlice({
  name: 'taskPosition',
  initialState,
  reducers: {
    setTaskPosition: (state, action: PayloadAction<TaskPosition>) => {
      state.position = action.payload;
    },
    clearTaskPosition: (state) => {
      state.position = null;
    },
    setShowInfoCard: (state, action: PayloadAction<boolean>) => {
      state.showInfoCard = action.payload;
    }
  }
});

export const { setTaskPosition, clearTaskPosition, setShowInfoCard } = taskPositionSlice.actions;
export default taskPositionSlice.reducer;