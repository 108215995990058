/**
 *
 * @file TaskInfoCard.js
 * @created_date Thur, April 25, 2024
 * @author Jiaying He <jiayinghe@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The component renders the Info-Cards of workflow phases and workflow tasks.
 *<br/>Key functionalities - 
 * @module TaskInfoCard
 *
 **/
import React from "react";
import Ressource from "../../services/RessourceServices";
import { MiscFunctions } from 'helper-functions-package';
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import store from "../../store/store";
class TaskInfoCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pos: this.props.pos,
      taskRight: this.props.taskRight,

    }
  }

  componentDidUpdate(prevProps, prevState) {
    var infoCard = document.getElementById("info-card-box-id");
    if (infoCard !== null && infoCard !== undefined) {
      window.addEventListener("mouseup", function (event) {
        if (
          event.target != infoCard &&
          event.target.parentNode != infoCard
        ) {
          infoCard.style.display = "none";
        }
      });
    }
  }
  render() {
    const taskPosition = store.getState().taskPosition.position;
    const selectedTaskInfo = store.getState().selectedTask.selectedTaskInfo;
    return (
      <>
        <div
          // style={{
          //   left: `${(this.props.pos + this.props.taskRight)}%`,
          // }}
          className="swimlane-info-card-block"
        >
          <span
            className={"swimlane-task-info-card"}
            // style={{
            //   bottom: `${this.props.topValue}px`,
            // }}
            ref={this.props.taskInfoCardRef}
            style={{
              left: `${taskPosition.left < 450 ? 0 : 'auto'}`,
              right: `${taskPosition.left > 450 ? 0 : 'auto'}`,
            }}
          >
            {this.props.showToolbox && (
              <div className="swimlane-info-card-tool-box-details">
                <span className="swimlane-info-card-tool-box-details-title">
                  <span style={{ width: "85%" }}>
                    {this.props.selectedTool
                      ? this.props.selectedTool.getLabel
                      : "Tool name"}
                  </span>

                  <span
                    className="button_modal_close closeBtn"
                    onClick={() => {
                      this.setState({
                        showToolbox: false,
                        selectedTool: null,
                      });
                    }}
                  ></span>
                </span>
                <span className="swimlane-info-card-body">
                  <span className="swimlane-info-card-tag-list">
                    {this.props.selectedTool.getQuantity !== 0 && (
                      <span className="swimlane-info-card-tag">
                        {this.props.selectedTool.getQuantity}
                      </span>
                    )}
                    {!MiscFunctions.isNull(this.props.selectedTool.getDisposable) && (
                      <span className="swimlane-info-card-tag">
                        {this.props.selectedTool.getDisposable
                          ? "Disposable"
                          : "Non Disposable"}
                      </span>
                    )}
                  </span>
                  <span className="swimlane-info-card-tool-box-details-discription">
                    {this.props.selectedTool
                      ? this.props.selectedTool.getDescription
                      : "Tool description"}
                  </span>
                </span>
              </div>
            )}
            <div className="swimlane-info-card-content-box" id="info-card-box-id">
              <span className="swimlane-info-card-block-arrow"
                style={{
                  left: `${taskPosition.left < 450 ? '15px' : 'auto'}`,
                  right: `${taskPosition.left > 450 ? '15px' : 'auto'}`,
                }}
              ></span>
              <span className="swimlane-info-card-heading">
                <span className="swimlane-info-card-task-label">
                  {selectedTaskInfo.getLabel}
                </span>

                <span className="swimlane-task-duration">
                  <span className="swimlane-task-start-time">
                    <span className="swimlane-task-duration-icon"></span>
                    {
                      selectedTaskInfo.getTimestamp.split(".")[0]
                    }{" "}
                  </span>

                  <span className="swimlane-task-end-time">
                    <span className="swimlane-time-arrow-icon"></span>
                    {
                      MiscFunctions.secondsMinuteFormat(
                        Math.ceil(
                          selectedTaskInfo.getTimeStampSeconds +
                          selectedTaskInfo.getDuration
                        )
                      ).split(".")[0]
                    }
                  </span>
                </span>
              </span>
              <span className="swimlane-info-card-tag-list">
                {!MiscFunctions.isUndefined(selectedTaskInfo.getSterility) && (
                  <span className="swimlane-info-card-tag">
                    {selectedTaskInfo.getSterility
                      ? "Steril"
                      : "Non Steril"}
                  </span>
                )}
                {!MiscFunctions.isNull(selectedTaskInfo.getAnatomical) &&
                  selectedTaskInfo.getAnatomical !== "" && (
                    <span className="swimlane-info-card-tag">
                      {selectedTaskInfo.getAnatomical}
                    </span>
                  )}
              </span>
              <span className="swimlane-info-card-task-description">
                {MiscFunctionsLocal.formatCommentText(selectedTaskInfo.getDescription)}
              </span>
              <div className="swimlane-info-card-tool-box">
                {this.props.tools &&
                  selectedTaskInfo.ressources
                    .filter(
                      ({ reference }) =>
                        reference.type === "ToolDto" &&
                        reference.category === "tool"
                    )
                    .slice(0, 3)
                    .map((tool, i) => {
                      tool = new Ressource(tool);
                      return (
                        <span
                          key={i}
                          className={
                            !MiscFunctions.isNull(this.props.selectedTool) &&
                              this.props.selectedTool.getID === tool.getID
                              ? "swimlane-info-card-tool-box-item-selected swimlane-info-card-tool-box-item"
                              : "swimlane-info-card-tool-box-item"
                          }
                          onClick={() => this.handleSelectedTool(tool)}
                        >
                          <span className="swimlane-info-card-tool-box-item-label">
                            {tool.getLabel}
                          </span>
                          <span className="swimlane-info-card-tool-box-item-category">
                            {tool.getReference.category}
                          </span>
                          {/* <span className="swimlane-info-card-tool-box-item-play">
                            Play
                          </span> */}
                        </span>
                      );
                    })}
              </div>
            </div>
          </span>
        </div>
      </>
    )
  }
}
export default TaskInfoCard;